<template>
  <div class="detail-root" style="margin-top: 20px; margin-bottom: 40px">
    <div class="datails-content">
      <div class="data-cont-wrap">
        <div style="font-size: 20px; padding-bottom: 20px; font-weight: bold">
          {{ supplierBName }}
        </div>
        <!-- 商品信息 -->
        <div v-for="(item, index) of productList" :key="index">
          <div
            class="product-intro layui-clear"
            v-if="(!more && index == 0) || more"
          >
            <div class="preview-wrap">
              <a href="javascript:;"
                ><img :src="itemUrl(item)" style="height: 260px; width: 260px"
              /></a>
            </div>

            <div class="itemInfo-wrap">
              <div class="itemInfo">
                <div class="title">
                  <h4 style="font-weight: bold">{{ item.PNAME }}</h4>
                </div>
                <div class="summary">
                  <p class="reference"><span>规格</span>{{ item.SPEC }}</p>
                  <p class="activity">
                    <span>单价</span
                    ><strong class="price"
                      ><i>￥</i><span>{{ item.PURCHASEPRICE }}</span></strong
                    >
                  </p>
                </div>
                <div class="choose-attrs">
                  <div class="number layui-clear">
                    <span
                      class="title"
                      style="font-size: 16px; font-weight: bold; color: black"
                      >{{ item.BUYNUM }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            justify-items: center;
            align-items: center;
          "
        >
          <span
            @click="handleMore"
            style="
              display: inline-block;
              background-color: orangered;
              color: white;
              padding: 5px 10px;
              border-radius: 15px;
              cursor: pointer;
            "
            >{{ moreText }}</span
          >
        </div>

        <div class="line-new">
          <div style="font-size: 18px">配送方式</div>
          <div style="margin: 20px">
            <div
              v-for="(item, index) of psList"
              :key="index"
              style="display: inline-block"
            >
              <span
                @click="psClick(index)"
                :class="{
                  'ps-btn-like': !item.active,
                  'ps-btn-like-active': item.active,
                }"
                >{{ item.bName }}</span
              >
            </div>
          </div>
        </div>

        <div class="line-new">
          <div style="font-size: 18px">支付方式</div>
          <div style="margin: 20px">
            <div
              v-for="(item, index) of payList"
              :key="index"
              style="display: inline-block"
            >
              <span
                @click="payClick(index)"
                :class="{
                  'ps-btn-like': !item.active,
                  'ps-btn-like-active': item.active,
                }"
                >{{ item.bName }}</span
              >
            </div>
          </div>
        </div>

        <div class="line-new">
          <div style="font-size: 18px">优惠券</div>
          <div style="margin: 20px" v-if="youhuiList.length == 0">
            暂无可用优惠券
          </div>
          <div
            style="margin: 20px; display: flex; flex-wrap: wrap"
            v-if="youhuiList.length > 0" class="coupon"
          >
            <div class="coupons" v-for="(item, i) in youhuiList" :key="i">
              <img
                src="@/assets/new/youhui01.png"
                class="shiyong"
                alt=""
                v-if="item.isUse == 'N'"
              />
              <img
                src="@/assets/new/youhui02.png"
                class="shiyong"
                alt=""
                v-if="item.isUse == 'E'"
              />
              <div class="neirong">
                <div
                  class="price"
                  :style="{ color: item.isUse == 'E' ? '#515151' : '' }"
                >
                  <span>￥</span>{{ item.subPrice }}
                </div>
                <div
                  class="man"
                  :style="{ color: item.isUse == 'E' ? '#515151' : '' }"
                >
                  满{{ item.xfAmount }}可用
                </div>
                <div class="youxiaoqi">有效期至：{{ item.validTime }}</div>
                <div class="liji" @click="shiyong(item)">
                  {{
                    item.couponCode==userCouponCode?"已使用":"立即使用"
                  }}
                </div>
                <div
                  class="shangjia"
                  :class="[item.isUse == 'E' ? 'shangjias' : '']"
                >
                  <span>{{ item.mtzName }}</span>
                </div>
                <img
                  src="@/assets/new/yishiyong.png"
                  class="yishiyong"
                  v-if="item.couponCode==userCouponCode"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div class="line-new">
          <div style="font-size: 18px">备注</div>
          <div style="margin-top: 20px">
            <el-input
              placeholder="请输入备注"
              v-model="remark"
              style="width: 300px"
            ></el-input>
          </div>
        </div>

        <div class="line-new">
          <div style="font-size: 18px; font-weight: bold">收货地址</div>
          <div style="margin: 20px">
            <span v-if="address" @click="selectAddress" class="address-text">{{
              address
            }}</span>
            <span v-else @click="selectAddress" class="address-text"
              >请选择地址</span
            >
          </div>
        </div>

        <div class="line-new">
          <div style="font-size: 18px">联系电话</div>
          <div style="margin: 20px">
            <span v-if="phone">{{ phone }}</span>
          </div>
        </div>

        <div class="settle-info">
          <div>
            <span style="margin-right: 20px">应付总额：</span>
            <span style="font-size: 18px; font-weight: bold; color: red"
              >￥{{ (priceInfo.allPurchaseTrue - yhPrice).toFixed(2) }}</span
            >
          </div>
          <div style="max-width: 769px">
            <span style="margin-right: 5px">收货地址:</span>
            <span style="margin-right: 15px">{{ address }}</span>
            <span style="margin-right: 5px">收货人:</span>
            <span>{{ receiver_plus_phone }}</span>
          </div>
        </div>

        <div class="line-new-pay" style="display: flex; align-items: center">
          <span style="margin-left: auto; margin-right: 20px">
            已优惠：{{ yhPrice }}元
          </span>
          <span class="span-pay-style" @click="clickPay">付款</span>
        </div>
      </div>
    </div>

    <SelectAddressDialog
      @custom_evt="setAddress"
      :seed="seed"
    ></SelectAddressDialog>
  </div>
  <!-- root div -->
</template>
<script>
// import home from "@/json/home.json";

import axios from "axios";
import { base_url, appId } from "@/common/constant";

import { checkLogin } from "@/common/tools";

import SelectAddressDialog from "@/components/dialog/SelectAddressDialog";

export default {
  name: "Payment",
  components: {
    SelectAddressDialog,
  }, // components end
  data() {
    return {
      bnum: "",
      detail: {},
      priceInfo: "",
      mesResult: "",
      otherInfo: "",
      productList: "",
      more: false,
      // psActive: false,
      psList: "",
      payList: "",
      psSelectedItem: "", // 选中的配送方式
      paySelectedItem: "", // 选中的支付方式
      address: "",
      phone: "",
      receiver_plus_phone: "",
      clickPayDisabled: false, // 付款禁用,默认false
      submitData: "", // 付款提交到后台的data
      seed: "", // 传递给 `SelectAddressDialog` 的种子值
      supplierBName: "", //商家名称
      remark: "", //备注
      youhuiList: [], //优惠券的列表
      userCouponCode: "", //优惠券编码
      yhPrice: 0, //优惠金额
    };
  }, //data end
  computed: {
    moreText() {
      if (!this.more) {
        return "更多商品";
      } else {
        return "收起";
      }
    },
  }, //computed end
  methods: {
    shiyong(item){
      if(this.priceInfo.allPurchaseTrue - item.subPrice <=0){
         this.$message.error("优惠券不可使用");
         return;
      }
      this.userCouponCode = item.couponCode
      this.yhPrice = item.subPrice
    },
    before_pay() {
      // 付款前的校验和参数组装
      const that = this;
      const data = {};
      if (that.psSelectedItem == "") {
        that.$message.error("请选择配送方式");
        return false;
      }
      if (that.paySelectedItem == "") {
        that.$message.error("请选择支付方式");
        return false;
      }
      if (that.address == "") {
        that.$message.error("请选择收货地址");
        return false;
      }

      data.supplierBNum = that.bnum;
      if (that.$route.query && that.$route.query.orderNum) {
        data.buyOrderNum = that.$route.query.orderNum;
      } else {
        that.$message.error("缺少订单号");
        return false;
      }

      data.psTypeNum = that.psSelectedItem.bValue;
      data.psTypeName = that.psSelectedItem.bName;
      data.payTypeNum = that.paySelectedItem.bValue;
      data.payTypeName = that.paySelectedItem.bName;

      if (that.priceInfo) {
        data.payTotalPrice = (that.priceInfo.allPurchaseTrue - that.yhPrice).toFixed(2);
        data.payTotalPriceTrue = (that.priceInfo.allPurchaseTrue - that.yhPrice).toFixed(2);
        data.remark = that.remark; //备注
        data.userCouponCode = that.userCouponCode; //优惠券码
        data.yhPrice = that.yhPrice; //优惠金额
      } else {
        that.$message.error("金额有误");
        return false;
      }

      data.receiveGoodsAddr = that.address;
      data.telephone = that.phone;
      that.submitData = data;
      return true;
    },

    clickPay() {
      const that = this;
      if (!that.clickPayDisabled) {
        that.clickPayDisabled = true;
        if (that.before_pay()) {
          if (that.paySelectedItem.bValue == "PAY002") {
            // 货到付款
            that.cash_on_delivery();
          } else if (that.paySelectedItem.bValue == "PAY001") {
            // 微信支付, 预支付
            that.prePay();
          }
        } else {
          that.clickPayDisabled = false;
        }
      }
    },
    
    prePay() {
      // 微信支付, 预支付
      const that = this;
      const postData = {};
      postData.appId = appId;
      postData.supplierBNum = that.productList[0].SUPPLIERBNUM;
      postData.buyOrderNum = that.productList[0].ORDERNUM;
      postData.psTypeNum = that.psSelectedItem.bValue;
      postData.psTypeName = that.psSelectedItem.bName;
      postData.payTypeNum = that.paySelectedItem.bValue;
      postData.payTypeName = that.paySelectedItem.bName;
      postData.payTotalPrice = that.priceInfo.allPurchaseOld;
      postData.payTotalPriceTrue = that.priceInfo.allPurchaseTrue;
      postData.receiveGoodsAddr = that.address;
      postData.telephone = that.phone;
      postData.yhPrice = that.yhPrice;
      postData.remark = that.remark;
      postData.userCouponCode = that.userCouponCode;
      axios
        .post(`${base_url}/wxPay/wholesale/native/prePay`, postData)
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            const data = res.data;
            that.clickPayDisabled = false;
            const url = `/wxPay?v=${Math.random()}&code_url=${
              data.code_url
            }&price=${that.priceInfo.allPurchaseTrue}`;
            that.$router.push(url);
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.message && res.message.length < 30) {
              errMsg = res.message;
            }
            that.$message.error(errMsg);
            that.clickPayDisabled = false;
          }
        })
        .catch((res) => {
          console.error(res);
          that.clickPayDisabled = false;
        });
    },

    cash_on_delivery() {
      // 货到付款
      const that = this;
      // /webStore/calcOrder
      let a = { ...that.submitData };
      axios
        .post(`${base_url}/webStore/calcOrder`, a)
        .then((res) => {
          res = res.data;
          if (res.status == 200) {
            that.$message.success("支付成功！");
            that.clickPayDisabled = false;
            that.$router.push(`/?v=${Math.random()}`);
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.message && res.message.length < 30) {
              errMsg = res.message;
            }
            that.$message.error(errMsg);
            that.clickPayDisabled = false;
          }
        })
        .catch((err) => {
          console.error(err);
          that.clickPayDisabled = false;
        });
    },

    concat_address(item) {
      if (item) {
        let addressDetail = "";
        let prefix = "";
        if (item.province == item.city) {
          prefix = item.province;
        } else {
          prefix = `${item.province} ${item.city}`;
        }
        addressDetail += `${prefix} ${item.area} ${item.address}`;
        return addressDetail;
      } else {
        return "";
      }
    },

    setAddress(addressItem) {
      if (addressItem) {
        this.address = this.concat_address(addressItem);
        this.phone = addressItem.phone;
        this.receiver_plus_phone = `${addressItem.receiver} ${addressItem.phone}`;
      }
    },

    selectAddress() {
      const that = this;
      that.seed = Math.random();
      that.$store.commit("change_visible_for_payment", true);
    },

    psClick(index) {
      const that = this;
      that.psSelectedItem = that.commonSwitch(that.psList, index);
    },
    payClick(index) {
      const that = this;
      that.paySelectedItem = that.commonSwitch(that.payList, index);
    },
    commonSwitch(list, index) {
      const that = this;
      if (list && list.length > 0) {
        let idx = 0;
        for (let item of list) {
          item.active = false;
          that.$set(list, idx, item);
          idx++;
        }
        const item = list[index];
        item.active = true;
        that.$set(list, index, item);
        return item;
      } else {
        return undefined;
      }
    },

    handleMore() {
      const that = this;
      that.more = !that.more;
    },

    itemUrl(item) {
      if (item && item.PRUL) {
        let url = item.PRUL;
        if (url && !url.includes("http")) {
          url = `${base_url}/file/show?fileName=${url}`;
        }
        return url;
      } else {
        return "";
      }
    },

    // http://127.0.0.1/mall/webStoreUser/address/list/1
    remote_listAddressByUid() {
      const that = this;
      return new Promise((resolve) => {
        const login_user = that.$store.state.login_user;
        if (login_user) {
          axios
            .get(`${base_url}/webStoreUser/address/list/${login_user.id}`)
            .then((res) => {
              res = res.data;
              if (res.status == 200) {
                resolve({ flag: true, data: res.data });
              } else {
                resolve({ flag: false, err: res });
              }
            })
            .catch((res) => {
              console.error(res);
              resolve({ flag: false, err: res });
            });
        } else {
          resolve({ flag: false, err: { message: "请重新登录" } });
        }
      });
    },

    // http://127.0.0.1/mall/webStore/getBuyOrderList/11628235957971
    remote_getBuyOrderList() {
      const that = this;
      return new Promise((resolve) => {
        const query = that.$route.query;
        if (query && query.orderNum) {
          axios
            .get(`${base_url}/webStore/getBuyOrderList/${query.orderNum}`)
            .then((res) => {
              res = res.data;
              if (res.status == 200) {
                resolve({ flag: true, data: res.data });
              } else {
                resolve({ flag: false, err: res });
              }
            })
            .catch((res) => {
              console.error(res);
              resolve({ flag: false, err: res });
            });
        } else {
          console.error("没有orderNum");
          resolve({ flag: false, err: "没有orderNum" });
        }
      });
    },

    // http://127.0.0.1/mall/webStore/getBasicInfo/10001
    remote_getBasicInfo() {
      const that = this;
      return new Promise((resolve) => {
        if (that.bnum) {
          axios
            .get(`${base_url}/webStore/getBasicInfo/${that.bnum}`)
            .then((res) => {
              res = res.data;
              if (res.status == 200) {
                resolve({ flag: true, data: res.data });
              } else {
                resolve({ flag: false, err: res });
              }
            })
            .catch((res) => {
              resolve({ flag: false, err: res });
            });
        } else {
          resolve({ flag: false, err: { message: "没有bnum" } });
        }
      });
    },
    //查询优惠券
    getYouhui() {
      let that = this;
      if (that.productList.length > 0) {
        that.$axios
          .get(
            `${that.$base_url}/webStore/queryYhqOfMe/${that.productList[0].SUPPLIERBNUM}`
          )
          .then((res) => {
            if (res.data.status == 200) {
              let arr = [];
              res.data.data.forEach(r=>{
                if(that.priceInfo.allPurchaseTrue - r.subPrice > 0){
                  arr.push(r)
                }
              })
              that.youhuiList = arr
            } else {
              that.$message.error(res.data.message);
            }
          })
          .finally(() => {
            that.loading = false;
          });
      }
    },
  }, //methods end
  created() {
    const that = this;
    checkLogin()
      .then((res) => {
        if (!res.flag) {
          const url = that.$route.fullPath;
          // 请先登录
          that.$store.commit("change_url_before_login", url);
          that.$message.warning("请先登录!");
          setTimeout(() => {
            that.$router.push("/login");
          }, 1500);
        } else {
          // 已经登录

          const query = that.$route.query;
          if (query && query.bnum) {
            that.bnum = query.bnum;
          }
          that.supplierBName = query.supplierBName || "";

          // 查询出所有的购物车商品
          that
            .remote_getBuyOrderList()
            .then((res) => {
              if (res.flag) {
                let arr = res.data;
                if (arr && arr.length > 0) {
                  that.priceInfo = arr.shift();
                  that.mesResult = arr.shift();
                  that.otherInfo = arr.shift();
                  that.productList = arr;
                }
                //查询可以使用的优惠券
                that.getYouhui();
              }
            })
            .catch((err) => {
              console.error(err);
            });

          // 出现配送、支付选项
          that
            .remote_getBasicInfo()
            .then((res) => {
              if (res.flag) {
                const data = res.data;
                that.psList = data.psList;
                that.payList = data.payList;
                //
                that.psSelectedItem = that.commonSwitch(that.psList, 0);
                that.paySelectedItem = that.commonSwitch(that.payList, 1);
              }
            })
            .catch((err) => {
              console.error(err);
            });

          // 查询默认地址
          that
            .remote_listAddressByUid()
            .then((res) => {
              if (res.flag) {
                const addressList = res.data;
                if (addressList && addressList.length > 0) {
                  const item = addressList.find(
                    (item) => item.isDefault == "Y"
                  );
                  that.address = that.concat_address(item);
                  that.phone = item.phone;
                  this.receiver_plus_phone = `${item.receiver} ${item.phone}`;
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } // else end
      })
      .catch(() => {});
  }, //created end
};
</script>
<style lang="scss" scoped>
.shangjias{
  background: url("../assets/new/juxing02.png") no-repeat!important;
  color: #515151!important;
}
.shangjia{
  width: 300px;
height: 29px;
background: url("../assets/new/juxing01.png") no-repeat;
background-size: 100%;
border-radius: 15px;
display: flex;
align-items: center;
font-size: 16px;
color: #F6555D;
position: absolute;
z-index: 2;
left: 28px;
bottom: 61px;
span{
  margin-left: 14px;
}
}
.detail-root {
  margin: 0;
  padding: 0;
  width: 100%;
  //background-color: #f8f8f8;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

/*details*/
.datails-content {
  width: 1200px;
  display: block;
  padding-bottom: 10px;
}

.datails-content .crumb {
  line-height: 76px;
}

.datails-content .crumb span {
  font-family: simsun;
  color: #666;
  font-weight: bold;
  margin: 0 5px;
}

.datails-content .crumb a {
  color: #666;
}

.datails-content .crumb a:hover {
  color: #ff5500;
}

.datails-content .product-intro {
  margin-bottom: 15px;
}

.datails-content .product-intro .preview-wrap {
  width: 320px;
  float: left;
}

.datails-content .product-intro .preview-wrap img {
  border: 1px solid #eaeaea;
}

.datails-content .itemInfo-wrap {
  float: left;
  width: 770px;
}

.datails-content .itemInfo .title {
  position: relative;
  margin-bottom: 30px;
}

.datails-content .itemInfo .title h4 {
  font-size: 18px;
}

.datails-content .itemInfo .title span {
  position: absolute;
  right: 0;
  top: 2px;
  cursor: pointer;
}

.datails-content .itemInfo .title span i {
  margin-right: 4px;
  color: #ff5500;
}

.datails-content .itemInfo .summary {
  width: 100%;
  height: 135px;
  background: #f5f5f5;
  padding-top: 25px;
  margin-bottom: 26px;
}

.datails-content .itemInfo .summary p {
  line-height: 40px;
  padding-left: 34px;
  color: #888888;
}

.datails-content .itemInfo .summary p span {
  padding-right: 40px;
}

.datails-content .itemInfo .summary .activity .price {
  font-size: 30px;
  color: #ee0000;
}

.datails-content .itemInfo .summary .activity .price i {
  font-size: 14px;
}

.datails-content .itemInfo .choose-attrs {
  color: #888888;
  line-height: 32px;
  padding-left: 34px;
}

.datails-content .itemInfo .choose-attrs .title {
  margin-right: 34px;
  float: left;
  margin-bottom: 0;
}

.datails-content .itemInfo .choose-attrs .color {
  margin-bottom: 18px;
}

.datails-content .itemInfo .choose-attrs .color .color-cont {
  display: inline-block;
  float: left;
}

.datails-content .itemInfo .choose-attrs .color .color-cont .btn {
  display: inline-block;
  padding: 0 26px;
  margin-right: 26px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  color: #000;
}

.datails-content .itemInfo .choose-attrs .color .color-cont .btn.active {
  border-color: #ff5500;
}

.datails-content .itemInfo .choose-attrs .number {
  line-height: 30px;
}

.datails-content .itemInfo .choose-attrs .number .title {
  float: left;
}

.datails-content .itemInfo .choose-attrs .number .number-cont {
  display: inline-block;
  float: left;
  line-height: 30px;
}

.datails-content .itemInfo .choose-attrs .number .number-cont .btn {
  display: inline-block;
  padding: 0 6px;
  border: 1px solid #dddddd;
  background: #f8f8f8;
  width: 20px;
  text-align: center;
  font-size: 26px;
  cursor: pointer;
  float: left;
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}

.datails-content .itemInfo .choose-attrs .number .number-cont .cut {
}

.datails-content .itemInfo .choose-attrs .number .number-cont input {
  width: 35px;
  height: 28px;
  text-align: center;
  margin: 0 5px;
  float: left;
}

.datails-content .itemInfo .choose-btns {
  padding-left: 34px;
  margin-top: 22px;
}

.datails-content .itemInfo .choose-btns .purchase-btn {
  border: 1px solid #ff5500;
  color: #ff5500;
  width: 116px;
  height: 40px;
  line-height: 40px;
}

.datails-content .itemInfo .choose-btns .car-btn {
  width: 136px;
  height: 40px;
  line-height: 40px;
}

.datails-content .aside {
  display: block;
  float: left;
  width: 360px;
  margin-right: 40px;
}

.coupon {
  .liji {
    color: #fff;
    font-size: 20px;
    width: 20px;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 16px;
  }
  padding-top: 30px;
  padding-bottom: 30px;
  width: 856px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .yishiyong {
    width: 99px;
    height: 99px;
    position: absolute;
    bottom: 20px;
    right: 64px;
    z-index: 1.5;
  }
  .youxiaoqi {
    font-size: 16px;
    position: absolute;
    left: 30px;
    bottom: 20px;
    color: #5d6169;
  }
  .man {
    font-size: 20px;
    position: absolute;
    color: #f6555c;
    right: 73px;
    top: 41px;
  }
  .price {
    display: flex;
    align-items: center;
    color: #f54851;
    font-size: 48px;
    position: absolute;
    left: 30px;
    top: 20px;
    span {
      font-size: 24px;
    }
  }
  .neirong {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }
  .coupons {
    width: 391px;
    height: 172px;
    position: relative;
    margin-bottom: 34px;
    cursor: pointer;
    .shiyong {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
  .coupons:nth-child(2n) {
    margin-left: auto;
  }
}
.datails-content .aside h4 {
  font-size: 18px;
  line-height: 42px;
  padding-left: 18px;
  color: #888888;
}

.datails-content .aside .item-list {
  border: 1px solid #ededed;
  padding: 45px;
  padding-bottom: 0;
}

.datails-content .aside .item-list .item {
  margin-bottom: 34px;
}

.datails-content .aside .item-list .item p {
  line-height: 64px;
  position: relative;
}

.datails-content .aside .item-list .item .pric {
  color: #ee0000;
  position: absolute;
  right: 0;
}

.datails-content .detail {
  //float: right;
  float: left;
}

.datails-content .detail h4 {
  line-height: 42px;
  width: 100px;
  text-align: center;
  border-bottom: 2px solid #888888;
  font-size: 18px;
  cursor: pointer;
  color: #888888;
}

.datails-content .detail .item {
  display: block;
  border: 1px solid #ececec;
  width: 795px;
}

/*end-details*/

.item-detail {
  width: 770px;
  border: 2px solid #bbb8b8;
  margin: 10px 0;
  padding: 10px;
  .username {
    margin-right: 5px;
  }
  .province {
  }
  .line {
    margin: 5px 0;
  }
  .line-title {
    display: inline-block;
    width: 80px;
    text-align: right;
    color: #b1acac;
    margin-right: 10px;
  }
  .line-text {
  }
}

.hot-prod-img {
  cursor: pointer;
}

.line-new {
  padding-left: 320px;
  margin: 20px 0;
}

.ps-btn-like {
  font-size: 14px;
  cursor: pointer;
  background-color: #dcd9d9;
  color: black;
  padding: 8px 20px;
  margin-right: 10px;
  border-radius: 10px;
}

.ps-btn-like-active {
  font-size: 14px;
  cursor: pointer;
  background-color: orangered;
  color: white;
  padding: 8px 20px;
  margin-right: 10px;
  border-radius: 10px;
}

.address-text {
  cursor: pointer;
  &:hover {
    color: orangered;
  }
}

.settle-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0 0 320px;
  width: 770px;
  max-width: 770px;
  background-color: #f5f5f5;
  padding: 20px;
}

.line-new-pay {
  margin: 20px 0 40px 320px;
  width: 770px;
}

.span-pay-style {
  cursor: pointer;
  float: right;
  padding: 5px 15px 5px 25px;
  background-color: orangered;
  color: white;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 10px;
  &:hover {
    background-color: lighten(orangered, 10%);
  }
}
</style>
