<template>
  <div class="main">
    <router-view class="app-content"></router-view>
  </div><!-- root div -->
</template>

<script>
export default {
  name: 'Main',
  components: {

  }
}
</script>

<style lang="scss" scoped>
.main {
  margin: 0;
  padding: 0;
}
.app-content {
  min-height: calc(100vh - 30px - 117px - 45px - 88px);
}
</style>
