<template>
  <div>
    <!-- 这个项目只有一个drawer可以这样写，如果有多个地方需要drawer可以只创建一个，用`<slot></slot>`来插入内容 -->
    <el-drawer title="订单明细" :visible.sync="isShow" direction="ltr">
      <OrderDetailContent :order_main="order_main"></OrderDetailContent>
    </el-drawer>
  </div><!-- root div -->
</template>
<script>

import OrderDetailContent from "@/components/drawer/OrderDetailContent";

export default {
  name: "OrderDetailDrawer",
  components: {
    OrderDetailContent
  },
  data() {
    return {
      order_main: '', // 传入子组件的主订单内容
    };
  },//data
  computed: {
    isShow: {
      get(){
        return this.$store.state.drawer_isShow;
      },
      set(v){
        this.$store.commit('change_drawer_isShow', v)
      }
    }
  },//computed
  watch: {
    isShow(val) {
      const that = this;
      if (val === true) {
        that.order_main = that.$store.state.order_main;
      }
    },
  },//watch
  methods: {},// methods
}
</script>
