import Vue from 'vue'
import App from './App.vue'

import { setApp } from "@/common/global";


import "./plugins/element.js";

import store from "@/store";
import router from "@/router";
// import "@/assets/common/css/main.css";
import "@/assets/layui/css/layui.css";

//axios全局封装
import axios from 'axios';
Vue.prototype.$axios = axios;

//定义全局url
import { base_url } from "@/common/constant";
Vue.prototype.$base_url = base_url;
import { translateProdUrl } from "@/utils/utils.js"
Vue.prototype.$translateProdUrl = translateProdUrl
//使用coolies  https://blog.csdn.net/weixin_28686261/article/details/107037947
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.config.productionTip = false;

const app = new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');

setApp(app);
