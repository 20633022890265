<template>
  <div class="home">

    <div class="regist">
      <div class="regist_center">
        <div class="regist_top">
          <div class="left fl">会员登录</div>
          <div class="right fr"><router-link to="/register">会员注册</router-link></div>
          <div class="clear"></div>
          <div class="xian center"></div>
        </div>
        <div class="regist_main center">
          <div class="username">用&nbsp;&nbsp;户&nbsp;&nbsp;名:&nbsp;&nbsp;
            <input class="shurukuang" type="text" v-model="userinfo.username" placeholder="输入用户名或手机号"/>
          </div>
          <div class="username">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:&nbsp;&nbsp;
            <input class="shurukuang" type="password" v-model="userinfo.password" placeholder="输入密码"/>
          </div>
        </div>
        <div style="margin-left: 45px;padding-top: 20px;display: flex;align-items: center;">
            <el-checkbox v-model="checked">
              阅读并同意
            </el-checkbox>
			<span :style="{color:checked==true?'#409EFF':''}" style="font-weight: bold;cursor: pointer;" @click.stop="$refs.fuwuHi.dialogVisible=true">《用户服务协议》</span>
			<span :style="{color:checked==true?'#409EFF':''}">和</span>
			<span :style="{color:checked==true?'#409EFF':''}" style="font-weight: bold;cursor: pointer;" @click.stop="$refs.yinsiHi.dialogVisible=true">《隐私政策》</span>
          </div>
        <div class="regist_submit">
          <input class="submit" type="button" value="登录" @click="handleLogin">
          <el-button type="text" style="margin-left: 150px;" @click="routers">
            <span style="color: #ff6700;">忘记密码</span>
          </el-button>
        </div>

      </div>
    </div>
    <fuwuXieyi ref="fuwuHi" />
    <yinsiXieyi ref="yinsiHi" />
  </div><!-- root div-->
</template>
<script>

// import home from "@/json/home.json";

import axios from "axios";
import { base_url } from "@/common/constant";
import fuwuXieyi from "@/components/components/xieyi/fuwu.vue"
import yinsiXieyi from "@/components/components/xieyi/yinsi.vue"
export default {
  components:{
    fuwuXieyi,yinsiXieyi
  },
  name: 'Login',
  data() {
    return {
      checked:false,
      userinfo: {
        username: '',
        password: '',
      },
    };
  },//data end
  watch:{
  $route(to,from){

  }
},
  methods: {
    routers(){
      this.$router.push({path:"/Forgot"})
    },
    rou(path){
       // 使用$router.resolve方法获取到目标URL对应的RouteLocationNormalizedLoaded对象
       const route = this.$router.resolve(path);
      
      // 创建一个a元素并将其添加到body中
      const aElement = document.createElement('a');
      aElement.href = route.href;
      aElement.setAttribute('target', '_blank');
      document.body.appendChild(aElement);
      
      // 模拟点击事件，自动打开新窗口
      aElement.click();
      
      // 移除已经添加的a元素
      document.body.removeChild(aElement);
    },
    handleLogin(){
      const that = this;
      if (that.userinfo.username == '') {
        that.$message.error("请输入用户名");
        return;
      } else if (that.userinfo.password == '') {
        that.$message.error("请输入密码");
        return;
      } else {
        if(!that.checked){
          this.$message.error("请阅读并同意用户服务协议和隐私政策");
          return;
        }
        axios.post(`${base_url}/webStoreUser/login`, that.userinfo).then(res=>{
          res = res.data;
          if (res.status == 200) {
            that.$message.success("登录成功!");
            
            that.$store.commit('change_is_login', true);
            setTimeout(()=>{
              that.$router.push(that.$store.state.url_before_login);
            }, 1500);
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.message && res.message.length < 30) {
              errMsg = res.message;
            }
            that.$message.error(errMsg);
            console.error(res);
          }
        }).catch(res=>{
          console.error(res);
        });
      }
    },
  },//methods end
  created() {
  },//created end
}
</script>
<style lang="scss" scoped>
.home {
  display: block;
  margin: 0;
  padding: 20px 0 0 0;
  width: 100%;
  height: calc(100vh - 55px);
  background-color: #f8f8f8;
}

.fl{float: left;}
.fr{float: right;}
.clear{clear:both;}
.center{margin:0 auto;}
.border{border:1px solid red;}

/*用户注册*/
.regist{width: 1200px;height: 550px;margin:0 auto;background:#fff;color:#000;border-radius: 10px;}
.regist_center{width: 760px;margin: 0 auto;}
.regist .regist_top{margin: 0;}
.regist .regist_top .left{height: 40px;line-height: 40px;font-weight: bold;font-size: 20px;}
.regist .regist_top .right{height: 40px;line-height: 40px;font-size: 13px;}
.regist .regist_top .right a{color:#ff6700;font-weight: bold;}
.regist .regist_top .right a:hover{color:orange;}
.xian{height: 2px;background: #ff6700;margin: 8px auto;}

.regist_main{padding:10px 0;padding-left:45px;}
.regist_main .username{font:16px Times New Roman;height: 40px;line-height: 40px;margin:20px 0;}
.regist_main .username .shurukuang{width: 220px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .yanzhengma{width: 100px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .shurukuang:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .yanzhengma:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .right{margin-left: 20px;}

.regist_main .username span{display:inline-block;margin-left:20px;font-size: 12px;color: #ccc;font-weight: bold;}
.regist_main .username span:hover{color: #ff6700;}

.regist .regist_center .regist_submit{margin: 20px auto;display: flex;flex-direction: column;}
.regist .regist_center .submit{border:none;width: 440px;height: 45px;margin-left:45px;
  background: #ff6700;color: #fff; font-size: 22px;font-weight: bold;letter-spacing: 8px;cursor:pointer;}
.regist .regist_center .submit:hover{border:1px solid #ccc;}

</style>
