
<template>
  <div>

    <el-dialog :title="title" :visible.sync="visible_for_userinfo" :append-to-body="true"
               :close-on-click-modal="false" width="50%" >
      <div class="address-line">
        <div class="line">
          <span class="line-title">收货人:</span>
          <el-input class="line-receiver" v-model="addressInfo.receiver" placeholder="请输入姓名"></el-input>
        </div>
        <div class="line">
          <span class="line-title">所在地区:</span>
          <el-cascader v-model="selectedAddress" :options="addressArrayData" :clearable="true"
                       :props="{value: 'name', label: 'name', checkStrictly: true}"
                       style="width: 380px;"
            @change="handleAddressChange"></el-cascader>
        </div>
        <div class="line">
          <span class="line-title">地址:</span>
          <el-input v-model="addressInfo.address"
                    placeholder="请输入详细地址" style="width: 420px;"></el-input>
        </div>
        <div class="line">
          <span class="line-title">手机:</span>
          <el-input v-model="addressInfo.phone"
                    placeholder="请输入手机号"  style="width: 200px;"></el-input>
        </div>
      </div>
      <span v-if="errMsgTip != ''" style="color: red;margin-right: 15px;">{{errMsgTip}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible_for_userinfo = false">取 消</el-button>
        <el-button type="primary" class="btn-confirm" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>

  </div><!-- root div-->
</template>
<script>

import full_address from "@/json/full_address.json";

import { remote_add_address } from "@/components/dialog/js/scriptForAddress";

export default {
  name: 'UserInfoDialog',
  props: ['title'],
  data() {
    return {
      addressInfo: {
        uid: '', // 用户id
        receiver: '', // 收货人
        province: '',
        city: '',
        area: '',
        address: '',
        phone: '',
      },
      addressArrayData: [],
      selectedAddress: [],
      errMsgTip: '', // 错误提示
    };
  },//data end
  computed: {
    visible_for_userinfo: {
      get(){
        return this.$store.state.visible_for_userinfo;
      },
      set(val){
        this.$store.commit('change_visible_for_userinfo', val);
      }
    },
  },//computed end
  methods: {

    handleAddressChange(selectedVal){
      if (selectedVal.length == 1) {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[0];
        this.addressInfo.area = "";
      } else if (selectedVal.length == 2) {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[0];
        this.addressInfo.area = selectedVal[1];
      } else {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[1];
        this.addressInfo.area = selectedVal[2];
      }
    },

    handleSubmit(){
      const that = this;
      if (that.check_before_submit()) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        remote_add_address(that.addressInfo).then((res)=>{
          if (!res.flag) {
            console.error(res);
          }
          loading.close();
          that.visible_for_userinfo = false;
        }).catch((res)=>{
          console.error(res);
          loading.close();
          that.visible_for_userinfo = false;
        });
      }
    },

    check_before_submit() {
      const that = this;
      const arr = [
        {field: 'receiver', msg: '请输入收货人姓名'}, {field: 'province', msg: '请选择所在地区'},
        {field: 'city', msg: '请选择所在地区'}, {field: 'area', msg: '请选择所在地区'},
        {field: 'address', msg: '请输入详细地址'}, {field: 'phone', msg: '请输入手机号码'},
      ];
      for (let item of arr) {
        if (that.addressInfo[item.field] == '') {
          that.errMsgTip = item.msg;
          return false;
        }
      }
      that.errMsgTip = "";
      return true;
    },




  },//methods end
  created() {
    const that = this;
    that.addressArrayData = full_address.data;
  },//created end
}
</script>
<style lang="scss" scoped>
.address-line {
  border: 2px solid #bbb8b8;
  margin: 10px 0;
  padding: 10px;
  .username {
    margin-right: 5px;
  }
  .province {
  }
  .line {
    margin: 5px 0;
  }
  .line-title {
    display: inline-block;
    width: 80px;
    text-align: right;
    color: #242222;
    font-weight: bold;
    margin-right: 10px;
  }
  .line-input {
    display: inline-block;
  }
  .line-receiver {
    display: inline-block;
    max-width: 150px;
  }
}

.btn-confirm {
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}
</style>
