<template>
  <div class="list">
    <div class="biaoshis">
      <img
        src="@/assets/new/shouye.png"
        @click="$router.push({ path: '/' })"
        class="shouye"
        alt=""
      />
      <div class="dangqian">
        当前位置：
        <div
          style="cursor: pointer"
          @click="$router.push({ path: '/ShopList' })"
        >
          批发部 >
        </div>
        <div style="margin-left: 5px">{{mtzName}}</div>
      </div>
    </div>

    <!--商品分类-->
    <div class="cate">
      <div class="catetitle">
        <el-divider>商品分类</el-divider>
      </div>
      <div class="catelist">
        <div
          class="catelists"
          @click="bnaneclcik('')"
          :class="[sortCode == '' ? 'select' : '']"
        >
          全部
        </div>
        <div
          class="catelists"
          @click="bnaneclcik(item.sortCode)"
          :class="[sortCode == item.sortCode ? 'select' : '']"
          v-for="(item, i) in cateList"
          :key="i"
        >
          {{ item.sortName }}
        </div>
      </div>
    </div>

    <!--优惠券-->
    <div class="youhui">
      <div class="youhuis" v-for="(item, i) in youhuiArr" :key="i" :class="[item.isLing==false?'youhuis01':'youhuis02']">
        <div class="price" :style="{color:item.isLing==true?'#515151!important':''}"><span>￥</span>{{item.subPrice}}</div>
        <div class="lingqu" :style="{color:item.isLing==true?'#515151!important':''}" @click="lingqu(item)">{{item.isLing==true?"已领取":"领取"}}</div>
        <div class="manprice" :style="{color:item.isLing==true?'#515151!important':''}">满{{item.xfAmount}}可用</div>
        <div class="manprices" :style="{color:item.isLing==true?'#515151!important':''}">有效期:{{item.validTime}}</div>
      </div>
    </div>

    <!--商品列表-->
    <div class="list">
      <div class="lists" v-for="(item, i) in list" :key="i">
        <div class="product">
          <div class="img"  @click="gotoDetail(item)" style="width: 242px; height: 190px">
            <img :src="$translateProdUrl(item.productUrl)" alt="" />
          </div>
          <div  @click="gotoDetail(item)" class="title" style="text-align: left">
            {{ item.pname || "--" }}
          </div>
          <div  @click="gotoDetail(item)" class="guige">
            规格: {{item.spec || "--"}}
          </div>
          <div class="price" style="justify-content: flex-start;align-items: center;">
            <div  @click="gotoDetail(item)" class="prices" style="margin-top:10px;">
              ￥{{ item.pfPrice }}
              <!-- <span style="text-decoration:line-through;color: #aaa;font-size: 14px;" v-if="item.yhPrice">￥{{item.yhPrice}}</span> -->
            </div>
            <div style="margin-left: auto;margin-top: 10px;display: flex;align-items: center;">
                <div class="jia" @click="jianqu(item)">-</div>
                <div class="cument">{{item.buyNum}}</div>
              <div class="jia" @click="jiashang(item)">+</div>&nbsp;<span style="color: #8A8A94;">{{item.unitName}}</span>
                <!-- <el-input-number size="small" v-model="item.buyNum" @change="handleChange($event,item)" :min="0" :max="999" label="描述文字"></el-input-number> -->
            </div>
          </div>
          <div class="mtzName"  @click="gotoDetail(item)" style="justify-content: flex-start">
            <div class="mtzName01">{{rzType==1?'企':'店'}}</div>
            <div class="mtzName02">{{ mtzName || "--" }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="gouwuche" @click="gouwucheClick">
      <img src="@/assets/nav/gouwuche.png" class="gouwu" alt="">
      <span>{{numgou}}</span>
    </div>
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
export default {
  data() {
    return {
        bizNum:"",
        sortCode: "",
      cateList: [],
      list:[],

      num:1,
      mtzName:"",
      rzType:"",
      youhuiArr:[],
      numgou:0,
    };
  },
  mounted() {
    checkLogin().then(()=>{}).catch(()=>{});
    if(this.$route.query.bizNum){
      this.bizNum = this.$route.query.bizNum
      this.rzType = this.$route.query.rzType || ""
      this.mtzName = this.$route.query.mtzName || ""
      this.getCateList();
        this.getList();
        this.getBizYhq();//获取优惠券
        this.getGouwu();
    }else{
        // this.$message.error("缺少参数");
        this.$router.push({
          path:"/ShopList"
        });
    }
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
    city() {
      return this.$store.state.city;
    },
  },
  methods: {
    async getList(){
       let that = this;
       await that.$axios.get(`${this.$base_url}/webStoreBusiness/getProducts?bizNum=${that.bizNum}&sortCode=${that.sortCode}`).then(res=>{
        if (res.data.status == 200) {
            this.list = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
       })
    },
    getGouwu(){
      let that = this;
       that.$axios.get(`${this.$base_url}/webStoreBusiness/queryUserBuyCartCount?bizNum=${that.bizNum}`).then(res=>{
        if (res.data.status == 200) {
            that.numgou = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
       })
    },
    //领取
    lingqu(item){
        let that = this;
        if(item.isLing==true){
          return;
        }
        that.$axios.post(`${this.$base_url}/webStoreBusiness/addUserYhq`,{
            bnum:item.bizNum,
            couponCode:item.couponCode,
            couponName:item.couponName,
            subPrice:item.subPrice,
            validTime:item.validTime,
            xfAmount:item.xfAmount
        }).then(res=>{
          that.youhuiArr.forEach(r=>{
            if(r.couponCode==item.couponCode){
              that.$set(r,"isLing",true);
            }
          })
            if (res.data.status == 200) {
            that.$message.success("领取成功，请前往个人中心查看");
          } else {
            this.$message.error(res.data.message);
          }
        })
    },
    getBizYhq(){
        let that = this;
        that.$axios.get(`${this.$base_url}/webStoreBusiness/getBizYhq/${that.bizNum}`).then(res=>{
        if (res.data.status == 200) {
          that.youhuiArr = res.data.data
          that.youhuiArr.forEach(r=>{
              that.$set(r,"isLing",false);
            })
          } else {
            this.$message.error(res.data.message);
          }
       })
    },
    jianqu(item){
       let that = this;
       if(item.buyNum>0){
        that.$set(item,"buyNum",item.buyNum-1);
        let query = `${item.buyNum}/${item.bizNum}/${item.pnum}/${item.unitNum}`;
           that.$axios.put(`${this.$base_url}/webStore/subBuyCart/${query}`).then(res=>{
            if (res.data.status != 200) {
                that.$set(item,"buyNum",item.buyNum+1);
                this.$message.error(res.data.message);
          } 
       }).finally(()=>{
        that.getGouwu();
       })
       }
    },
    jiashang(item){
        let that = this;
        that.$set(item,"buyNum",item.buyNum+1);
        that.$axios.post(`${this.$base_url}/webStore/addBuyCart`,{
            count:item.buyNum,
            supplierName:that.mtzName,
            productNum:item.pnum,
            dwNum:item.unitNum,
            smallSortCode:item.sortCode,
            supplierBizNum:item.bizNum
        }).then(res=>{
            if (res.data.status == 200) {
                
          } else {
            that.$set(item,"buyNum",item.buyNum-1);
            this.$message.error(res.data.message);
          }
        }).finally(()=>{
        that.getGouwu();
       })
    },
    gouwucheClick(){
      if(this.numgou==0){
        this.$message.error("你还没有添加商品");
        return;
      }
      this.$router.push({
        path:"/shoppingCart"
      })
    },
    //获取分类列表
    async getCateList() {
      await this.$axios
        .get(`${this.$base_url}/webStoreBusiness/getProductSort/${this.bizNum}`)
        .then((res) => {
          if (res.data.status == 200) {
            this.cateList = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
        //页数
        handleCurrentChange(val) {
      this.limitStart = val;
      this.getList();
    },
    //每页条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    gotoDetail(item) {
      const that = this;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.pfPrice || item.yhPrice;
      let url = `/details?bnum=${item.bizNum}&pnum=${item.pnum}&shopName=${that.mtzName}`;
      url = `${url}&yhPrice=${yhPrice}&price=${item.pfPrice}&rzType=${item.rzType}`;
      // 跳转到商品详情前，先校验是否登录
      checkLogin()
        .then((res) => {
          if (res.flag) {
            that.$router.push(url);
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", url);
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
    },
    bnaneclcik(sortCode) {
      this.sortCode = sortCode;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.gouwuche{
  width: 80px;
height: 80px;
background: #fff;
border: 1px solid #E2E4EB;
border-radius: 10px;
display: flex;
align-items: center;
justify-items: center;
justify-content: center;
position: fixed;
right: 20px;
bottom: 300px;
cursor: pointer;
.gouwu{
  width: 38px;
  height: 36px;
}
span{
  width: 32px;
height: 24px;
background: #FF3D09;
border-radius: 12px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-size:12px;
position: absolute;
top: 0px;
right: 0px;
}
}
.jia{
    width: 32px;
height: 32px;
background: #E2E4EB;
border: 1px solid #E2E4EB;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 26px;
color: #8A8A94;
cursor: pointer;
}
.cument{
    font-size: 20px;
    margin-left: 13px;
    margin-right: 13px;
}
.mtzName{
    padding-top: 20px;
    border-top: 1px solid #ececec;
    margin-top: 20px;
}
.guige{
    color: #8A8A94;
    font-size:16px;
    width: calc(100% - 40px);
    text-align: left;
}
.youhuis01{
  background: url("../../assets/new/youhui002.png") no-repeat;
}
.youhuis02{
  background: url("../../assets/new/aaa.png") no-repeat;
}
.youhuis {
  width: 225px;
  height: 132px;
  background-size: 100%;
  margin-left: 5px;
  position: relative;
  .manprice{
    color:#fff;
    font-size: 16px;
    position: absolute;
    left: 28px;
    bottom: 32px;
  }
  .manprices{
    color:#fff;
    font-size: 16px;
    position: absolute;
    left: 28px;
    bottom: 10px;
  }
  .lingqu {
    width: 61px;
    height: 28px;
    background: #ffffff;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fe2e2e;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    right: 18px;
    top: 28px;
  }
  .price {
    color: #fff;
    font-size: 42px;
    position: absolute;
    left: 23px;
    top: 18px;
    span {
      font-size: 24px;
    }
  }
}
.youhui {
  width: 100%;
  height: 160px;
  background: url("../../assets/new/youhui001.png") no-repeat;
  background-size: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select {
  background: #ff592d !important;
  color: #fff !important;
  border: none !important;
}
.catelist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  .catelists {
    width: 127px;
    height: 48px;
    background: #fff;
    border: 1px solid #e3e4eb;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    margin-left: 22px;
    margin-bottom: 20px;
  }
}
.cate {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  .catetitle {
    width: 277px;
    margin: 0 auto;
    font-size: 24px;
  }
}
/deep/ .el-divider__text {
  font-size: 24px;
}
.list {
  .dangqian {
    display: flex;
    align-items: center;
  }
  width: 1250px;
  margin: 0 auto;
  .biaoshis {
    .shouye {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
  }
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff3d09 !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FF3D09!important;
}
.list {
  width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .lists {
    width: 240px;
    background: #fff;
    margin-right: 11px;
    margin-bottom: 11px;
    padding-bottom: 27px;
    cursor: pointer;
  }
  .lists:nth-child(5n) {
    margin-right: 0px !important;
  }
}
.goodsVue {
  width: 100%;
  margin-top: 30px;
  .tdimg {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 227px;
      height: 59px;
    }
  }
}
</style>