<template>
  <div class="home">
     <div class="homes">
        <swiperVue />
        <winnowVue />
        <div class="ad">
          <img src="@/assets/new/haozhe.png" alt="">
        </div>
        <goodsVue />
     </div>
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
  import swiperVue from "./components/home/swiper.vue"
  import winnowVue from "./components/home/winnow.vue"
  import goodsVue from "./components/home/goods.vue"
  export default {
    components:{
      swiperVue,
      winnowVue,
      goodsVue
    },
    data(){
      return{

      }
    },
    mounted(){
       // 这里只是调用一下，不需要获取结果
    checkLogin().then(()=>{}).catch(()=>{});
    },
    methods:{

    },
  }
</script>

<style lang="scss" scoped>
.home{
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  .homes{
    width: 1250px;
    margin: 0 auto;
    .ad{
      width: 100%;
      height: 120px;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
</style>