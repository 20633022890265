import Vue from "vue";
import VueRouter from "vue-router";
import { setRouter } from "@/common/global";

import HelloWorld from "@/components/HelloWorld";
import Main from "@/components/Main";
import Home from "@/components/Home";
import ProductDetails from "@/components/ProductDetails";

import Register from "@/components/Register";
import Login from "@/components/Login";

import ShoppingCart from "@/components/ShoppingCart";
import MerchantSettleIn from "@/components/MerchantSettleIn";

import UserInfo from "@/components/UserInfo";

import ReloadMiddleware from "@/components/ReloadMiddleware";

import Payment from "@/components/Payment";

import WxPay from "@/components/WxPay";

import UserCenter from "@/components/UserCenter";

import OrderSearchTab from "@/components/user/OrderSearchTab";

/** 
 * 九天创想-新开发板块
 * 
*/
import Search from "@/views/search/index"//搜索页
import Shop from "@/views/shop/index"//批发部
import Forgot from "@/views/search/Forgot"//忘记密码
import Enter from "@/views/shop/enter"//入驻协议
import Apply from "@/views/shop/enter_apply"//入驻申请记录
import coupon from "@/views/shop/coupon"//优惠券
import ShopList from "@/views/shop/list"//批发部
import PruductList from "@/views/shop/product_list"//批发部的商品列表
import CateList from "@/views/shop/cateList"//分类检索页面
import fuwuXieyi from "@/components/components/xieyi/fuwu.vue"//用户服务协议
import yinsiXieyi from "@/components/components/xieyi/yinsi.vue"//隐私政策
import jingxuan from "@/views/search/jingxuan.vue"//精选好货
/** 
 * 九天创想-新开发板块
 * 
*/
Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        component: Home
      },
      {
        path: 'home',
        component: Home
      },
      {
        path: 'details',
        component: ProductDetails
      },
      {
        path: 'shoppingCart',
        component: ShoppingCart
      },
      {
        path: 'payment',
        component: Payment
      },
    ],
  },

  {
    path: '/register',
    component: Register
  },
  {
    path: '/login',
    component: Login
  },

  {
    path: '/merchantSettleIn',
    component: MerchantSettleIn
  },

  {
    path: '/user_center',
    component: UserCenter,
    children: [
      {
        path: 'userinfo',
        component: UserInfo
      },
      {
        path: 'my_order',
        component: OrderSearchTab
      },
    ]
  },

  // {
  //   path: '/userinfo',
  //   component: UserInfo
  // },





  {
    path: '/reload',
    component: ReloadMiddleware
  },

  {
    path: '/wxPay',
    component: WxPay
  },

  {
    path: '/hello',
    component: HelloWorld
  },
  {
    path:'/Search',
    component:Search
  },
  {
    path:'/Shop',
    component:Shop
  },
  {
    path:"/Forgot",
    component:Forgot
  },
  {
    path:"/Enter",
    component:Enter
  },
  {
    path:"/Apply",
    component:Apply
  },
  {
    path:"/coupon",
    component:coupon
  },
  {
    path:"/ShopList",
    component:ShopList
  },
  {
    path:"/PruductList",
    component:PruductList
  },
  {
    path:"/CateList",
    component:CateList
  },
  {
    path:"/fuwuXieyi",
    component:fuwuXieyi
  },
  {
    path:"/yinsiXieyi",
    component:yinsiXieyi
  },
  {
    path:"/jingxuan",
    component:jingxuan
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.path == '/wxPay') {
//     getStore().commit('change_is_header_show', false);
//   } else {
//     getStore().commit('change_is_header_show', true);
//   }
//   next();
// });

setRouter(router);
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router;
