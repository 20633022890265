<template>
    <div class="apply" style="margin-bottom: 50px;">
        <el-empty description="暂无申请记录"  v-if="list.length==0"></el-empty>
      <div class="applys" v-if="list.length>0">
         <div class="buzhou">
            <div class="buzhou01">
               <div class="name" style="top: 3px;">商家名称</div>
               <div class="name" style="top: 89px;">手机号</div>
               <div class="name" style="top: 170px;">所在城市</div>
               <div class="name" style="top: 250px;">申请日期</div>
               <div class="name" style="bottom: 285px;">入驻协议</div>
               <div class="name" style="bottom: 206px;">营业执照</div>
               <div class="name" style="bottom: 32px;">审核状态</div>
            </div>
            <div class="buzhou02">
                <div class="xian"></div>
                <div class="dian dian01" style="top: 10px;"></div>
                <div class="dian dian02" style="top: 95px;"></div>
                <div class="dian dian03" style="top: 175px;"></div>
                <div class="dian dian04" style="top: 256px;"></div>
                <div class="dian dian05" style="top: 335px;"></div>
                <div class="dian dian06" style="bottom: 212px;"></div>
                <div class="dian dian07" style="bottom: 39px;background: #FF5B2F;"></div>
            </div>
            <div class="buzhou03">
                <div class="name" style="top: 3px;">{{list[0].shopName || "--"}}</div>
               <div class="name" style="top: 89px;">{{list[0].contactMobile || "--"}}</div>
               <div class="name" style="top: 170px;">{{list[0].addr || "--"}}</div>
               <div class="name" style="top: 250px;">{{list[0].applyTime || "--"}}</div>
               <div class="name" style="bottom: 285px;color: #FF5B2F;cursor: pointer;" @click="ruzhuxieyi">查看入驻协议 ></div>
               <div class="names" style="bottom: 90px;">
				   <el-image :src="$translateProdUrl(list[0].bizLicenseUrl)" v-if="list[0].bizLicenseUrl" :preview-src-list="[$translateProdUrl(list[0].bizLicenseUrl)]">
					   
				   </el-image>
                 <!-- <img :src="$translateProdUrl(list[0].bizLicenseUrl)" v-if="list[0].bizLicenseUrl" alt=""> -->
               </div>
               <div class="name" style="bottom: 32px;color: #FF5B2F;">
                   {{
                    list[0].rzState=="N"?"提交审核中":list[0].rzState=="B"?"驳回":list[0].rzState=="Y"?"已入驻":"--"
                   }}
               </div>
               <div class="namess" v-if="list[0].rzState=='B'">
                {{list[0].rejectRemark || "--"}}
               </div>
            </div>
         </div>
      </div>
    </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
    export default {
        data(){
            return{
              list:[]
            }
        },
        mounted(){
			checkLogin();
           this.getdata();
        },
        methods:{
            getdata(){
                let that = this;
                that.$axios.get(`${that.$base_url}/webCustomerReg/queryApplyRzRecord`).then(res=>{
                    if (res.data.status == 200) {
                         that.list = res.data.data
                } else {
                  this.$message.error(res.data.message);
                }
                })
            },
            ruzhuxieyi(){
                this.$router.push({
                    path:"/Enter",
					query:{
						n:1254
					}
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.apply{
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    .applys{
       .buzhou{
        display: flex;
        align-items: center;
        height: 639px;
        .buzhou02{
            position: relative;
            height: 100%;
            width: 12px;
            .dian{
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #E8EAF0;
                position: absolute;
                z-index: 2;
                margin-left: -6px;
            }
            .xian{
                width: 1px;
                height: 100%;
                background: #E8EAF0;
                position: absolute;
                z-index: 1;
            }
        }
        .buzhou03{
            width: 439px;
            position: relative;
           height: 100%;
           margin-left: 39px;
           .namess{
            position: absolute;
            padding-left: 24px;
            padding-right: 24px;
height: 60px;
background: #F3F4F7;
border-radius: 10px;
line-height: 60px;
color: #515151;
font-size: 18px;
bottom: -40px;
           }
           .names{
            width: 211px;
            height: 143px;
            background: #E8EAF0;
            border: 1px solid #E8EAF0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            img{
                width: 181px;
                height: 124px;
                cursor: pointer;
            }
           }
           .name{
            color: #515151;
            font-size: 18px;
            position: absolute;
            text-align: right;
           }
        }
        .buzhou01{
            width: 72px;
           position: relative;
           height: 100%;
           margin-right: 29px;
           .name{
            color: #515151;
            font-size: 18px;
            position: absolute;
            text-align: right;
           }
        }
       }
    }
}
</style>