<template>
  <div>
  </div><!-- root div-->
</template>
<script>

export default {
  name: 'ReloadMiddleware',
  data() {
    return {
    };
  },//data end
  computed: {
  },// computed end
  methods: {
  },//methods end
  created() {
    // const that = this;
  },//created end
  mounted() {
    this.$router.push(this.$store.state.url_before_reload);
  }, //mounted end
}
</script>
<style lang="scss" scoped>
</style>
