<template>
  <div class="common">
    <div class="body-top">
     <div class="zhenglv">
      <img src="@/assets/logos.png" class="logos" alt="">
      <span>{{baseTitle}}</span>
     </div>
     <img src="../assets/new/logo01.png" alt="" class="logo01">
     <div class="right">
         <img src="../assets/new/search.png" alt="" class="search">
         <div class="sear">
          <el-input placeholder="请输入商品名称" v-model="searchName"></el-input>
            <!-- <div v-else style="cursor: pointer;" @click.stop="searchClick()">请输入商品名称</div> -->
         </div>
         <div class="sousuo" @click.stop="searchClick()">
           搜索
         </div>
     </div>
  </div>

  <!--导航-->
  <div class="nav">
     <div class="navs">
       <div class="navback" :class="[nav==1?'select':'']" @click="routes('/',1)">
        <img src="../assets/new/fenlei.png" alt="" class="fenlei">
        全部分类
       </div>
       <div class="navback" @click="routes('/',2)"  :class="[nav==2?'select':'']">
        首页
       </div>
       <div class="navback" @click="routes('/ShopList',3)"  :class="[nav==3?'select':'']">
        批发部
       </div>
       <div class="navback" @click="routes('/jingxuan',4)" :class="[nav==4?'select':'']">
        精选商品
       </div>
     </div>
  </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
         nav:1,//1全部分类  2首页 3 批发部 4精选好货
         searchName:"",
         toPath:"",
		 baseTitle:process.env.VUE_APP_TITLE
      }
    },
    watch:{
      
    },
    mounted(){
      this.$router.beforeEach((to, from, next) => {
      if(to.path!='/Search'){
        this.searchName = ""
        this.$store.commit("change_searchName",this.searchName);
      }
      this.toPath = to.path
      let route_path = localStorage.getItem("route_path");
      if(to.path=="/"){
        this.nav = route_path
      }else if(to.path=="/ShopList" || to.path=='/PruductList'){
        this.nav = 3
      }else if(to.path=="/jingxuan"){
        this.nav = 4
      }else{
        this.nav = ""
      }
      // 其他处理逻辑...
      
      next() // 必须调用next()才能完成路由切换
    })
    if(this.$route.path=="/"){
        this.nav = 1
      }else if(this.$route.path=="/ShopList" || this.$route.path=='/PruductList'){
        this.nav = 3
      }else if(this.$route.path=="/jingxuan"){
        this.nav = 4
      }else{
        this.nav = ""
      }
    },
    methods:{
      search(){
        if(this.$route.path=="/Search"){
           
        }else{
          
          this.$router.push({path:"/Search"})
        }
      },
      searchClick(){
        if(this.$route.path=="/Search"){
           this.toPath = "/Search"
           this.$store.commit("change_searchName",this.searchName);
        }else{
          this.nav = ""
          this.$store.commit("change_searchName",this.searchName);
          this.$router.push({path:"/Search"})
        }
      },
      routes(path,nav){
        let that = this;
        that.nav = nav
        localStorage.setItem("route_path",nav)
        that.$router.push({path:path,query:{
          t:new Date().getTime()
        }})
      },
    },
  }
</script>

<style lang="scss" scoped>
.zhenglv{
  display: flex;
  align-items: center;
  img{
    width: 60px;
  }
  span{
    font-weight: bold;
    margin-left: 2px;
    font-size: 24px;
    color: #515151;
  }
}
/deep/ .el-input__inner{
  border: none!important;
  background: none!important;
  font-size: 16px;
  padding: 0px!important;
}
.select{
  background: #E03102;
}
.nav{
  width: 100%;
  height: 48px;
  background: #FF3D09;
  .navs{
    width: 1250px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .navback:hover{
      background: #E03102;
    }
    .navback{
      width: 144px;
      height: 48px;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #fff;
      font-size: 18px;
      justify-content: center;
      img{
        width: 15px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}
.sousuo{
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-left: auto;
  background: #FF5A21;
  cursor: pointer;
}
.sear{
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #8A8A94;
  margin-left: 9px;
}
.right{
  margin-left: auto;
  width: 400px;
  height: 42px;
  border: 2px solid #FF5A21;
  position: relative;
  display: flex;
  align-items: center;
  .search{
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }
}
.body-top{
  width: 1250px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .logo01{
    width: 192px;
    height: 50px;
  }
  .logo{
    width: 243px;
    height: 110px;
  }
}
.common{
width: 100%;
margin: 0 auto;
  background: #fff;
}
</style>