<template>
  <div class="content">
    <div class="title">
      <img src="@/assets/new/ruzhu.png" alt="" class="banner" />
      <div class="titles"></div>
      <div class="zhaohui">
        <div class="ruzhu_new">
          <div class="ruzhu001" style="margin-top: -46px">入驻流程</div>
          <div class="ruzhu002">
            <img src="@/assets/new/ruzhu002.png" alt="" />
            协议签订
          </div>
          <img src="@/assets/new/ruzhu006.png" class="ruzhu006" alt="" />
          <div class="ruzhu002">
            <img src="@/assets/new/ruzhu003.png" alt="" />
            提交店铺资料
          </div>
          <img src="@/assets/new/ruzhu006.png" class="ruzhu006" alt="" />
          <div class="ruzhu002">
            <img src="@/assets/new/ruzhu004.png" alt="" />
            平台审核
          </div>
          <img src="@/assets/new/ruzhu006.png" class="ruzhu006" alt="" />
          <div class="ruzhu002">
            <img src="@/assets/new/ruzhu005.png" alt="" />
            入驻成功
          </div>
        </div>
        <img src="@/assets/new/jt.png" alt="" class="xiangxia" />
      </div>
    </div>
    <div class="xieyi">
      <div style="width: 98%; margin: 0 auto; height: 90%; overflow-y: scroll">
        <div class="xieyis">
          <div style="width: 100%;font-size: 28px;font-weight: bold;text-align: center;padding-bottom: 20px;">入驻协议</div>
          <div class="xuzhi">
            用户使用“正绿航商城多商户入驻系统”前请认真阅读并理解本协议内容，本协议内容中以加粗方式显著标识的
            条款，请用户着重阅读、慎重考虑。
          </div>
          <div class="titles" style="padding-top: 60px">1.本协议的订立</div>
          <div class="contents" style="margin-top: 30px">
            符合本网站商家入驻标准的用户（以下简称“商家)"<strong>同意本协议全部条款后，方有资格使用“正绿航商城多商户入驻系统”</strong>（以下简称“入驻系统”）
            申请入驻。一经商家点击“同意并确认此协议”按钮，即意味着商家同意与本网站签订本协议并同意受本协议约束。
          </div>
          <div class="titles" style="padding-top: 60px">2.入驻系统使用说明</div>
          <div class="contents" style="margin-top: 30px">
            <strong>
              商家通过入驻系统提出入驻申请，并按照要求填写商家信息、提供商家资质资料后，由本网站审核并与有合作意向的商家联系协商合作相关事宜。
            </strong>
          </div>
          <div class="titles" style="padding-top: 60px">3.商家权利义务</div>
          <div class="contents" style="margin-top: 30px">
            <strong>3.1</strong
            >&nbsp;商家应按照本网站要求诚信提供入驻申请所需资料并如实填写相关信息，商家应确保提供的申请资料及信息真实、准确、完整、合法有效，经本网站审核通过后，商家不得擅自修改替换相应资料及主要信息。如商家提供的申请资料及信息不合法、不真实、不准确的，商家需承担因此引起的相应责任及后果，并且本网站有权立即终止商家使用入驻系统的权利。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>3.2</strong
            >&nbsp;商家使用入驻系统提交的所有内容应不含有木马等软件病毒、政治宣传、或其他任何形式的“垃圾信息”、违法信息，且商家应按本网站规则使用入驻系统，不得从事影响或可能影响本网站或入驻系统正常运营的行为，否则，本网站有权清除前述内容，并有权立即终止商家使用入驻系统的权利。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>3.3</strong
            >&nbsp;商家应注意查看入驻系统公示的入驻申请结果，如审核通过的商家，则按照本网站工作人员的通知按要求办理入驻的相关手续；如审批未通过的商家，则可自本网站通过入驻系统将审批结果告知商家（需商家登陆入驻系统查看）之日起并于15日内提出异议并提供相应资料，如审批仍未通过的，则商家同意提交的申请资料及信息本网站无需返还，由本网站自行处理。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>3.4</strong
            >&nbsp;商家不得以任何形式擅自转让或授权他人使用自己在本网站的用户帐号使用入驻系统，否则由此产生的不利后果均由商家自行承担。
          </div>
          <div class="titles" style="padding-top: 60px">4.本网站权利义务</div>
          <div class="contents" style="margin-top: 30px">
            <strong>4.1</strong
            >&nbsp;本网站开发的入驻系统仅为商家申请入驻的平台，商家正式入驻后，将在商家后台系统中进行相关操作。
          </div>

          <div class="contents" style="margin-top: 30px">
            <strong>4.2</strong
            >&nbsp;本网站有权对商家提供的资料及信息进行审核，并有权结合自身业务情况对合作商家进行选择；本网站对商家提交资料及信息的审核均不代表本网站对审核内容的真实、合法、准确、完整性作出的确认，商家应对提供资料及信息承担相应的法律责任。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>4.3</strong
            >&nbsp;无论商家是否通过本网站的审核，本网站有权对商家提供的资料及信息予以留存并随时查阅，同时，本网站有义务对商家提供的资料予以保密，但国家行政机关、司法机关等国家机构调取资料的除外。
          </div>

          <div class="contents" style="margin-top: 30px">
            <strong>4.4</strong
            >&nbsp;本网站会尽力维护本系统信息的安全，但法律规定的不可抗力，以及因为黑客入侵、计算机病毒侵入发作等原因造成商家资料泄露、丢失、被盗用、被篡改的，本网站不承担任何责任。
          </div>

          <div class="contents" style="margin-top: 30px">
            <strong>4.5</strong
            >&nbsp;本网站应在现有技术支持的基础上确保入驻系统的正常运营，尽量避免入驻系统服务的中断给商家带来的不便。
          </div>
          <div class="titles" style="padding-top: 60px">5.知识产权</div>
          <div class="contents" style="margin-top: 30px">
            <strong>5.1</strong
            >&nbsp;本网站开发的入驻系统及其包含的各类信息的知识产权归本网站所有者所有，受国家法律保护,本网站有权不时地对入驻系统的内容进行修改，并在入驻系统中公示，无须另行通知商家。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>5.2</strong
            >&nbsp;在法律允许的最大限度范围内，本网站所有者对本协议及入驻系统涉及的内容拥有解释权。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>5.3</strong
            >&nbsp;商家未经本网站所有者书面许可，不得擅自使用、非法全部或部分的复制、转载、抓取入驻系统中的信息，否则由此给本网站造成的损失，商家应予以全部赔偿。
          </div>
          <div class="titles" style="padding-top: 60px">6.入驻系统服务的终止</div>
          <div class="contents" style="margin-top: 30px">
            <strong>6.1</strong
            >&nbsp;商家自行终止入驻申请，或商家经本网站审批未通过的，则入驻系统服务自行终止。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>6.2</strong
            >&nbsp;商家使用本网站或入驻系统时，如违反相关法律法规或者违反本协议规定的，本网站有权随时终止商家使用入驻系统。
          </div>
          <div class="titles" style="padding-top: 60px">7.本协议的修改</div>
          <div class="contents" style="margin-top: 30px">
            本协议可由本网站随时修订，并将修订后的协议公告于本网站及入驻系统，修订后的条款内容自公告时起生效，并成为本协议的一部分。
          </div>
          <div class="titles" style="padding-top: 60px">8.法律适用与争议解决</div>
          <div class="contents" style="margin-top: 30px">
            <strong>8.1</strong
            >&nbsp;本协议适用中华人民共和国法律。
          </div>
          <div class="contents" style="margin-top: 30px">
            <strong>8.2</strong
            >&nbsp;因本协议产生的任何争议，由双方协商解决，协商不成的，任何一方有权向有管辖权的中华人民共和国大陆地区法院提起诉讼。
          </div>
        </div>
      </div>

      <div class="button" v-if="n">
        <div class="button01" @click="back()">返回</div>
      </div>
      <div class="button" v-if="!n">
        <div class="button01" @click="$router.go(-1)">取消</div>
        <div class="button02" @click="routers('/MerchantSettleIn')">
          同意并确认此协议
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
export default {
	data(){
		return{
			n:null
		}
	},
  mounted() {
    let that = this;
	this.n = this.$route.query.n || null
    const url = that.$route.fullPath;
    checkLogin()
      .then((res) => {
        // eslint-disable-next-line no-empty
        if (res.flag) {
        } else {
          // 请先登录
          that.$store.commit("change_url_before_login", url);
          that.$message.warning("请先登录!");
          setTimeout(() => {
            that.$router.push("/login");
          }, 1500);
        }
      })
      .catch(() => {});
  },
  methods: {
	  back(){
		  this.$router.push({
			  path:"/user_center/userinfo",
			  query:{
				  n:"4"
			  }
		  })
	  },
    routers(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style lang="scss" scoped>
.ruzhu006 {
  width: 145px;
  height: 16px;
  margin-top: -50px;
}
.ruzhu002 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 24px;
  img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    margin-bottom: 17px;
  }
}
.ruzhu001 {
  width: 146px;
  height: 48px;
  background: url("../../assets/new/ruzhu001.png") no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}
.ruzhu_new {
  width: 100%;
  position: absolute;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  .button01 {
    width: 180px;
    height: 60px;
    background: #f3f4f7;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
  }
  .button02 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 254px;
    height: 60px;
    background: #ff3d09;
    border-radius: 30px;
    font-size: 24px;
    color: #fff;
    margin-left: 20px;
  }
}
.xieyi {
  .titles {
    color: #515151;
    font-size: 24px;
  }
  .contents {
    color: #515151;
    font-size: 24px;
    line-height: 38px;
  }
  width: 1248px;
  margin: 0 auto;
  height: 1167px;
  background: #fff;
  border-radius: 20px;
  margin-top: -113px;
  position: relative;
  z-index: 999;
  padding-top: 50px;
  padding-bottom: 50px;
  .xieyis {
    width: 1161px;
    margin: 0 auto;
    .xuzhi {
      font-size: 24px;
      line-height: 42px;
      padding-bottom: 40px;
      border-bottom: 1px solid #ebedf3;
    }
  }
}
.success {
  width: 360px;
  height: 60px;
  background: #ff3d09;
  box-shadow: 0px 3px 24px 0px rgba(255, 61, 9, 0.42);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  margin-top: 35px;
  cursor: pointer;
  margin-left: 125px;
}
.code {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 25px;
  .codes {
    width: 1px;
    height: 17px;
    background: #d5d6d8;
  }
  .btnText {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ff3d09;
    margin-left: 14px;
  }
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    .inouts {
      font-size: 16px;
      width: 50%;
      margin-left: 44px;
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
    .zhaohui {
      width: 18px;
      height: 24px;
      margin-left: 25px;
    }
    width: 360px;
    height: 56px;
    background: #fff;
    border: 1px solid #d6d8de;
    box-shadow: 0px 3px 5px 0px #edeef0;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .form_title {
    width: 120px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    text-align: right;
  }
}
.neirong {
  width: 1100px;
  height: 595px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.biaoshi {
  width: 1100px;
  height: 103px;
  background: #fff;
  left: 50%;
  margin-left: -550px;
  position: absolute;
  bottom: 0px;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .dangqian {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 10px;
  }
  .biaoshis {
    width: 1161px;
    margin: 0 auto;
    height: 89px;
    border-bottom: 1px solid #edeef6;
    font-size: 24px;
    color: #515151;
  }
}
.zhaohui {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .zhaohuis {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    top: 106px;
  }
  .xiangxia {
    width: 47px;
    height: 41px;
    position: absolute;
    top: 195px;
  }
}
.content {
  width: 100%;
  padding-bottom: 30px;
  .title {
    width: 100%;
    height: 418px;
    background: #fff;
    position: relative;
    .titles {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.32;
      position: absolute;
      z-index: 2;
    }
    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
}
</style>