import axios from "axios";
import { base_url } from "@/common/constant";
import { getStore, getRouter, getApp } from "@/common/global";

export function checkLogin() {
  return new Promise((resolve) => {
    axios.get(`${base_url}/webStoreUser/checkLogin`).then(res=>{
      res = res.data;
      if (res.status == 200) {
        getStore().commit('change_is_login', true);
        getStore().commit('change_login_user', res.data);
        resolve({flag: true, data: res.data});
      } else {
        getStore().commit('change_is_login', false);
        resolve({flag: false, err: res});
      }
    }).catch(res=>{
      getStore().commit('change_is_login', false);
      resolve({flag: false, err: res});
    });
  });
}

// http://127.0.0.1/mall/countryInfo/getCountry
export function getCountry() {
  return new Promise((resolve) => {
    axios.get(`${base_url}/countryInfo/getCountry`).then(res=>{
      res = res.data;
      if (res.status == 200) {
        resolve({flag: true, data: res.data});
      } else {
        console.error(res);
        resolve({flag: false, err: res});
      }
    }).catch(res=>{
      console.error(res);
      resolve({flag: false, err: res});
    });
  });
}


export function getFirstPageProducts(limitStart, pageSize, withTotal){
  // 远程调用获取首页产品信息
  return new Promise((resolve) => {
    let requestUrl = `${base_url}/webStore/getFirstPageProducts`;

    if (withTotal) {
      requestUrl += `?limitStart=${limitStart}&pageSize=${pageSize}&withTotal=${withTotal}`;
    } else {
      requestUrl += `?limitStart=${limitStart}&pageSize=${pageSize}`;
    }

    axios.get(requestUrl).then(res => {
      res = res.data;
      if (res.status == 200) {
        getStore().commit('change_searchDisabled', false);
        resolve({flag: true, data: res.data});
      } else {
        getStore().commit('change_searchDisabled', false);
        resolve({flag: false, err: res});
      }
    }).catch(res => {
      getStore().commit('change_searchDisabled', false);
      resolve({flag: false, err: res});
    });
  });
}


export function common_logout(){
  axios.get(`${base_url}/webStoreUser/logout`).then(res=>{
    res = res.data;
    if (res.status == 200) {
      getStore().commit('change_is_login', false);
      if (getApp().$route.path != '/') {
        getRouter().push(`/?v=${Math.random()}`);
      }
    } else {
      let errMsg = "系统升级中，请稍候";
      if (res.message && res.message.length < 30) {
        errMsg = res.message;
      }
      getApp().$message.error(errMsg);
      console.error(res);
    }
  }).catch(res=>{
    console.error(res);
  });
}




