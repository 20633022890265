<template>
    <div class="menu">
      	<div class="title">
            个人中心
        </div>
        <div class="nav" v-for="(item,i) in menus" @click="navclick(item.index)" :key="i" :class="[asideActiveIndex==item.index?'select':'']">
            <span>{{item.name}}</span>
            <img src="@/assets/nav/right.png" class="right" v-if="asideActiveIndex!=item.index" alt="">
            <img src="@/assets/nav/rights.png" class="right" v-if="asideActiveIndex==item.index" alt="">
        </div>
    </div>
</template>

<script>
    export default {
		props:{
			asideActiveIndexs:{
				default:"1",
				type:String
			}
		},
		watch:{
			asideActiveIndexs(e){
				this.asideActiveIndex = e
			},
		},
        data(){
            return{
                asideActiveIndex:"1",
                menus: [
                    {name: '我的资料', path: '/user_center/userinfo', index: '1'},
                    {name: '我的订单', path: '/user_center/my_order', index: '2'},
                    {name: '我的优惠券', path: '/user_center/my_order', index: '3'},
                    {name: '入驻申请记录', path: '/user_center/my_order', index: '4'},
                ],
            }
        },
        mounted(){
            this.$emit("navName",this.menus.find(item=>item.index==this.asideActiveIndex).name,this.asideActiveIndex);
        },
        methods:{
            navclick(index){
               this.asideActiveIndex = index
               this.$emit("navName",this.menus.find(item=>item.index==this.asideActiveIndex).name,this.asideActiveIndex);
            },
        }
    }
</script>

<style lang="scss" scoped>
.select{
    background: #FEF5F2!important;
    color: #FF3D09!important;
}
.nav{
    width: 200px;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #515151;
    font-size: 22px;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    span{
        position: absolute;
        left: 20px;
    }
    .right{
      width: 8px;
      height: 14px;
      position: absolute;
      right: 26px;
    }
}
.menu{
    width: 100%;
    background: #fff;
    .title{
        width: 200px;
        height: 65px;
        background: url(../../assets/new/gerensan.png) no-repeat;
        background-size: 100%;
        text-align: center;
        color: #fff;
        font-size: 28px;
        line-height: 60px;
        margin: 0 auto;
        margin-top: 16px;
    }
}
</style>