<template>
  <div class="root">
    <!-- 顶部订单信息显示 -->
    <div class="table header">
      <div class="table-tr">
        <div class="table-td header-left">
          <div>
            <span>订单号:</span> <span>{{ order_main.ORDERNUM }}</span>
          </div>
          <div>
            <span>订单日期:</span> <span>{{ orderDate }}</span>
          </div>
        </div>
        <div class="table-td header-right">
          <div>
            <span>总金额:</span> <span>{{ order_main.ALLPRICE }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="box-card" v-for="(item, index) in order_list" :key="index">
        <div class="table">
          <div class="table-tr">
            <div class="table-td commodity-img-cover">
              <el-image
                fit="fill"
                class="commodity-img"
                :src="product_url(item)"
              ></el-image>
            </div>
            <div class="table-td specification-cover">
              <div class="specification-cover-child">
                <div>
                  <span>{{ item.BARCARD }}</span>
                </div>
                <div>
                  <span>{{ item.PANME }}</span>
                </div>
                <div>
                  <span style="font-size:12px;">规格：</span><span>{{ item.SPEC }}</span>
                </div>
                <div style="color:#ff3152;">
                  <span>￥</span><span style="font-size:22px">{{ item.BUYRICE }}</span>
                </div>
              </div>
            </div>
            <div class="table-td"></div>
          </div>
          <div class="table-tr">
            <div class="table-td commodity-img-cover">
              <div>
                <span>生产日期：</span><span>{{ item.SCRQ }}</span>
              </div>
            </div>
            <div class="table-td"></div>
            <div class="table-td buynum-cover">
              <div>
                <span style="font-weight:bold;">{{ item.BUYNUM }}</span>
              </div>
            </div>
          </div>
        </div> <!-- table end -->
        <div v-if="item.ISMES == 'Y'" style="display: block;float: right;margin-top: -70px;margin-right: 20px;">
          <div style="display: inline-block;background-color: #ff3152;padding: 0 15px;border-radius: 5px;">
            <span style="display: inline-block;font-size: 12px;line-height: 28px;color: white;">赠品</span>
          </div>
        </div>
      </div>
    </div> <!-- main end -->

  </div><!-- root div -->
</template>
<script>
// import axios from "axios";
import { base_url } from "@/common/constant";

export default {
  name: 'OrderDetailContent',
  props: ['order_main'],//props
  data(){
    return {
      // order_list: [],
    };
  },//data
  computed: {
    orderDate(){
      const that = this;
      if (that.order_main) {
        const orderDate = that.order_main.ORDERDATE;
        return orderDate.substring(0, 19).replace('T', ' ');
      } else {
        return "";
      }
    },
    order_list(){
      return this.$store.state.drawer_order_detail;
    },
  },//computed
  watch: {
  },//watch
  methods: {

    product_url(item){
      let url = item.PURL;
      if (!url.includes('http')) {
        url = `${base_url}/file/show?fileName=${url}`;
      }
      return url;
    },

  },//methods
}
</script>
<style lang="scss" scoped>
$top-outer-row-min-height: 70px;
$commodity-img-size: 120px;

.root {
  // 顶部高度:25, padding-top:20 , margin-bottom:32
  height: calc(100vh - 20px - 25px - 32px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
  }

  &:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1);
  }
}



.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
.table-tr {
  display: table-row;
}
.table-td {
  display: table-cell;
  // border: 1px solid gray;
  text-align: left;
  vertical-align: bottom;
}

.el-card {
  border-top: 0;
}

.header {
  background: #e5e9f2;
  // 防止换行影响显示高度
  overflow: hidden;
  white-space: nowrap;
}
.header-left {
  vertical-align: middle;
  height: 70px;
  padding-left: 10px;
  width: auto;
  div {
    font-size: 16px;
    margin-top: 3px;
  }
}
.header-right {
  vertical-align: middle;
  width: 35%;
  font-size: 19px;
  font-weight: bold;
}
// .main {
//   overflow-y: scroll;
// }
.box-card {
  margin-top: 5px;
  border-bottom: #eaeaea 1px solid;
}
.buynum-cover {
  // width: 3em;
  width: 15%;
}

.specification-cover {
  width: auto;
  padding-left: 10px;
  .specification-cover-child {
    div {
      margin-top: 5px;
    }
  }
}
$commodity-img-padding-left: 10px;
.commodity-img-cover {
  width: $commodity-img-size;
  padding-left: $commodity-img-padding-left;
  div {
    font-size: 12px;
  }
}
.commodity-img {
  display: block;
  width: $commodity-img-size;
  height: $commodity-img-size;
  padding: 0;
  margin: 0;
}

// .footer {
//   position: fixed;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }
.footer-button {
  height: 40px;
  width: 300px;
  font-size: 18px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.top-outer-column-style {
  min-height: $top-outer-row-min-height;
}
.inner-row-style {
  min-height: $top-outer-row-min-height / 2;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.bg-purple-dark {
  background: #99a9bf;
}

</style>
