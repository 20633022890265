<template>
  <div class="winnow">
    <div class="winnow01">
      <img src="@/assets/new/tuijian.jpg" alt="" class="tuijian" />
      <!-- <img src="@/assets/new/left.png" alt="" class="left" /> -->
    </div>
    <div class="winnow02">
      <el-carousel :interval="5000" arrow="always" style="width: 100%">
        <el-carousel-item style="width: 100%; height: 100%;display: flex;">
          <div
            class="wins"
            v-for="(item, i) in list"
            :key="i"
            @click="gotoDetail(item)"
            v-if="list.length > 0 && i<=3"
          >
            <div class="product">
              <div class="img">
                <img :src="$translateProdUrl(item.productUrl)" v-if="item.productUrl" alt="" />
              </div>
              <div class="title">
                {{ item.productName || "--" }}
              </div>
              <div class="mtzName">
                {{ item.rzType }}
                <div class="mtzName01">
                  {{ item.rzType == 1 ? "企" : "店" }}
                </div>
                <div class="mtzName02">{{ item.mtzName || "--" }}</div>
              </div>
              <div class="price" style="margin-top: 10px">
                <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
                <div class="prices" v-if="isLogin">
                  ￥{{ item.bigWholesalePrice }}
                  <span
                    style="
                      text-decoration: line-through;
                      color: #aaa;
                      font-size: 14px;
                    "
                    v-if="item.yhPrice"
                    >￥{{ item.yhPrice }}</span
                  >
                </div>
              </div>
            </div>
            <img
              src="@/assets/new/TOP1.png"
              class="topimg"
              v-if="i == 0"
              alt=""
            />
            <img
              src="@/assets/new/TOP2.png"
              class="topimg"
              v-if="i == 1"
              alt=""
            />
            <img
              src="@/assets/new/TOP3.png"
              class="topimg"
              v-if="i == 2"
              alt=""
            />
            <img
              src="@/assets/new/TOP4.png"
              class="topimg"
              v-if="i == 3"
              alt=""
            />
          </div>
        </el-carousel-item>
        <el-carousel-item style="width: 100%; height: 100%;display: flex;">
          <div
            class="wins"
            v-for="(item, i) in list"
            :key="i"
            @click="gotoDetail(item)"
            v-if="list.length > 0 && i>=4 && i<=7"
          >
            <div class="product">
              <div class="img">
                <img :src="$translateProdUrl(item.productUrl)" v-if="item.productUrl" alt="" />
              </div>
              <div class="title">
                {{ item.productName || "--" }}
              </div>
              <div class="mtzName">
                {{ item.rzType }}
                <div class="mtzName01">
                  {{ item.rzType == 1 ? "企" : "店" }}
                </div>
                <div class="mtzName02">{{ item.mtzName || "--" }}</div>
              </div>
              <div class="price" style="margin-top: 10px">
                <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
                <div class="prices" v-if="isLogin">
                  ￥{{ item.bigWholesalePrice }}
                  <span
                    style="
                      text-decoration: line-through;
                      color: #aaa;
                      font-size: 14px;
                    "
                    v-if="item.yhPrice"
                    >￥{{ item.yhPrice }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item style="width: 100%; height: 100%;display: flex;">
          <div
            class="wins"
            v-for="(item, i) in list"
            :key="i"
            @click="gotoDetail(item)"
            v-if="list.length > 0 && i>7"
          >
            <div class="product">
              <div class="img">
                <img :src="$translateProdUrl(item.productUrl)" v-if="item.productUrl" alt="" />
              </div>
              <div class="title">
                {{ item.productName || "--" }}
              </div>
              <div class="mtzName">
                {{ item.rzType }}
                <div class="mtzName01">
                  {{ item.rzType == 1 ? "企" : "店" }}
                </div>
                <div class="mtzName02">{{ item.mtzName || "--" }}</div>
              </div>
              <div class="price" style="margin-top: 10px">
                <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
                <div class="prices" v-if="isLogin">
                  ￥{{ item.bigWholesalePrice }}
                  <span
                    style="
                      text-decoration: line-through;
                      color: #aaa;
                      font-size: 14px;
                    "
                    v-if="item.yhPrice"
                    >￥{{ item.yhPrice }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
    city() {
      return this.$store.state.city;
    },
  },
  methods: {
    async getList() {
      let that = this;
      await this.$axios
        .get(
          `${this.$base_url}/webStoreOfFirstPage/queryRecommendProducts/${this.city}`
        )
        .then((res) => {
          if (res.data.status == 200) {
            that.list = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    gotoDetail(item) {
      const that = this;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.bigWholesalePrice || item.yhPrice;
      let url = `/details?bnum=${item.bizNum}&pnum=${item.productNum}&shopName=${item.mtzName}&bizAddr=${item.bizAddr}`;
      url = `${url}&yhPrice=${yhPrice}&price=${item.bigWholesalePrice}`;
      // 跳转到商品详情前，先校验是否登录
      checkLogin()
        .then((res) => {
          if (res.flag) {
            that.$router.push(url);
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", url);
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.wins {
  width: 261px;
  height: 100%;
  cursor: pointer;
  border-right: 1px solid #e8e9ec;
  position: relative;
  .topimg {
    width: 42px;
    height: 39px;
    position: absolute;
    top: 0px;
    right: 14px;
  }
}
.img {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.winnow {
  width: 100%;
  margin-top: 15px;
  display: flex;
  .winnow02 {
    width: 1041px;
    height: 324px;
    background: #fff;
    position: relative;
    display: flex;
  }
  .winnow01 {
    .left {
      width: 26px;
      height: 48px;
      position: absolute;
      z-index: 2;
      left: 0px;
      top: 50%;
      margin-top: -24px;
      cursor: pointer;
    }
    .tuijian {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
    }
    width: 209px;
    height: 325px;
    background: #fff;
    position: relative;
  }
}
</style>