<template>
  <div class="list">
    <div class="biaoshis">
      <img
        src="@/assets/new/shouye.png"
        @click="$router.push({ path: '/' })"
        class="shouye"
        alt=""
      />
      <div class="dangqian">
        当前位置：
        <div style="margin-left: 5px">分类商品</div>
      </div>
    </div>

    <div class="cate">
      <div class="catelist" style="display: flex;border-bottom: 1px solid #ececec;">
        <div class="shang">商品分类:</div>
        <div style="display: flex;align-items: center;flex-wrap: wrap;width: 90%;">
          <div
          class="catelists"
          @click="bnaneclcik(item.bvalue)"
          :class="[listQuery.bigSortCode == item.bvalue ? 'select' : '']"
          v-for="(item, i) in cateList"
          :key="i"
        >
          {{ item.bname }}
        </div>
        </div>
      </div>
      <div class="catelist" style="display: flex;">
        <div class="shang">价格排序:</div>
        <div
         @click="typeClick(1)"
          class="catelists"
          :class="[type==1 ? 'select' : '']"
        >
          从高到低
        </div>
        <div
          class="catelists"
          @click="typeClick(2)"
          :class="[type==2 ? 'select' : '']"
        >
          从低到高
        </div>
      </div>
    </div>

    <div class="search">
        <div class="input">
           <input type="text" placeholder="输入商品名称" v-model="listQuery.searchName" class="inputs" />
           <img src="@/assets/new/sousuo.png" class="sousuo" alt="" @click="search">
        </div> 
    </div>
    <!--商品列表-->

    <div class="list" v-loading="loading">
      <el-empty
        description="暂无数据"
        v-if="list.length == 0"
        style="margin: 0 auto"
      ></el-empty>
      <div class="lists" v-for="(item, i) in list" :key="i" @click="gotoDetail(item)">
        <div class="product">
          <div
            class="img"
            @click="gotoDetail(item)"
            style="width: 242px; height: 190px"
          >
            <img :src="$translateProdUrl(item.productUrl)" alt="" />
          </div>
          <div @click="gotoDetail(item)" class="title" style="text-align: left">
            {{ item.productName || "--" }}
          </div>
          <div @click="gotoDetail(item)" class="guige">
            规格: {{ item.spec || "--" }}
          </div>
          <div
            class="price"
            style="justify-content: flex-start; align-items: center"
          >

          <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
            <div class="prices" v-if="isLogin" style="margin-top:10px;">
              ￥{{ item.yhPrice || item.bigWholesalePrice}}
              <span style="text-decoration:line-through;color: #aaa;font-size: 14px;" v-if="item.bigWholesalePrice && item.yhPrice">￥{{item.bigWholesalePrice}}</span>
            </div>
          </div>
          <div
            class="mtzName"
            @click="gotoDetail(item)"
            style="justify-content: flex-start"
          >
            <div class="mtzName01s">{{ item.city }}</div>
            <div class="mtzName02">{{ item.mtzName }}</div>
          </div>
        </div>
      </div>
    </div>

        <!-- 分页 -->
        <div class="pagination-container" style="display: flex;align-items: center;justify-content: center;margin-top: 20px;margin-bottom: 20px;">
      <el-pagination
        :current-page="listQuery.limitStart"
        :page-sizes="[20, 60, 80, 100]"
        :page-size="listQuery.pageSize"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
export default {
  data() {
    return {
      cateList: [], //分类列表
      listQuery: {
        bigSortCode: "", //大类编号
        limitStart: 1,
        pageSize: 20,
        searchName: "", //搜索内容
      },
      type:"",//1从高到低  2从低到高
      list: [],
      loading: false,
      total: 0,
    };
  },
  mounted() {
    checkLogin();
    this.listQuery["bigSortCode"] = this.$route.query.bigSortCode || "";
    this.getList();
    this.getCateList();
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
    city() {
      return this.$store.state.city;
    },
  },
  methods: {
    typeClick(type){
       if(this.type==type){
        this.type = ""
       }else{
        this.type = type
        //进行排序
        if(type==1){
          this.list.sort((a, b) => b.bigWholesalePrice - a.bigWholesalePrice);
        }else{
          this.list.sort((a, b) => a.bigWholesalePrice - b.bigWholesalePrice);
        }
       }
    },
    search(){
      this.listQuery.limitStart = 1
      this.getList()
    },
       //页数
       handleCurrentChange(val) {
      this.listQuery.limitStart = val;
      this.getList();
    },
    bnaneclcik(sortCode) {
      this.listQuery.limitStart = 1
      this.listQuery.bigSortCode = sortCode;
      this.getList();
    },
    //每页条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList();
    },
    getList() {
      let that = this;
      that.loading = true;
      let a = {...that.listQuery};
      a.limitStart = (a.limitStart -1)*a.pageSize
      that.$axios
        .post(
          `${that.$base_url}/webStoreOfFirstPage/queryProductsByBigSort`,
          a
        )
        .then((res) => {
          if (res.data.status == 200) {
            that.total = res.data.data.total;
            that.list = res.data.data.list;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    gotoDetail(item) {
      const that = this;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.bigWholesalePrice;
      let url = `/details?bnum=${item.bizNum}&pnum=${item.productNum}&shopName=${item.mtzName}&bizAddr=${item.bizAddr}`;
      url = `${url}&yhPrice=${yhPrice}&price=${item.bigWholesalePrice}`;
      // 跳转到商品详情前，先校验是否登录
      checkLogin()
        .then((res) => {
          if (res.flag) {
            that.$router.push(url);
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", url);
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
            return;
          }
        })
        .catch(() => {});
    },
    //获取分类列表
    async getCateList() {
      await this.$axios
        .get(`${this.$base_url}/webStoreOfFirstPage/getSortOfPT`)
        .then((res) => {
          if (res.data.status == 200) {
            this.cateList = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search{
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  .sousuo{
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
  }
  .input{
    .inputs{
      font-size: 18px;
      margin-left: 20px;
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      border: none;
    }
    width: 310px;
    height: 42px;
    background: #fff;
    border: 1px solid #E3E4EB;
    border-radius: 21px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
.shang{
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  margin-left: 20px;
}
.catelist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  .catelists {
    width: 127px;
    height: 48px;
    background: #fff;
    border: 1px solid #e3e4eb;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    margin-left: 22px;
    margin-bottom: 20px;
  }
}
.cate {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  .catetitle {
    width: 277px;
    margin: 0 auto;
    font-size: 24px;
  }
}
/deep/ .el-divider__text {
  font-size: 24px;
}
.mtzName01s{
  padding: 5px;
background: #FFFFFF!important;
border: 1px solid #FF592D!important;
border-radius: 5px!important;
display: flex!important;
align-items: center!important;
justify-content: center!important;
color: #FF592D!important;
font-size: 14px!important;
padding-left: 5px;
padding-right: 5px;
}
.jia {
  width: 32px;
  height: 32px;
  background: #e2e4eb;
  border: 1px solid #e2e4eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: #8a8a94;
  cursor: pointer;
}
.cument {
  font-size: 20px;
  margin-left: 13px;
  margin-right: 13px;
}
.mtzName {
  padding-top: 20px;
  border-top: 1px solid #ececec;
  margin-top: 20px;
}
.guige {
  color: #8a8a94;
  font-size: 16px;
  width: calc(100% - 40px);
  text-align: left;
}
.youhuis {
  width: 225px;
  height: 132px;
  background: url("../../assets/new/youhui002.png") no-repeat;
  background-size: 100%;
  margin-left: 5px;
  position: relative;
  .manprice {
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 28px;
    bottom: 32px;
  }
  .manprices {
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 28px;
    bottom: 10px;
  }
  .lingqu {
    width: 61px;
    height: 28px;
    background: #ffffff;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fe2e2e;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    right: 18px;
    top: 28px;
  }
  .price {
    color: #fff;
    font-size: 42px;
    position: absolute;
    left: 23px;
    top: 18px;
    span {
      font-size: 24px;
    }
  }
}
.youhui {
  width: 100%;
  height: 160px;
  background: url("../../assets/new/youhui001.png") no-repeat;
  background-size: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select {
  background: #ff592d !important;
  color: #fff !important;
  border: none !important;
}
.catelist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 36px;
  .catelists {
    width: 127px;
    height: 48px;
    background: #fff;
    border: 1px solid #e3e4eb;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    margin-left: 22px;
    margin-bottom: 20px;
  }
}
.cate {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #fff;
  .catetitle {
    width: 277px;
    margin: 0 auto;
    font-size: 24px;
  }
}
/deep/ .el-divider__text {
  font-size: 24px;
}
.list {
  .dangqian {
    display: flex;
    align-items: center;
  }
  width: 1250px;
  margin: 0 auto;
  .biaoshis {
    .shouye {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
  }
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff3d09 !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff3d09 !important;
}
.list {
  width: 1250px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .lists {
    width: 240px;
    background: #fff;
    margin-right: 11px;
    margin-bottom: 11px;
    padding-bottom: 27px;
    cursor: pointer;
  }
  .lists:nth-child(5n) {
    margin-right: 0px !important;
  }
}
.goodsVue {
  width: 100%;
  margin-top: 30px;
  .tdimg {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 227px;
      height: 59px;
    }
  }
}
</style>