<template>
  <div>

    <el-table :data="tableData" stripe border style="width: 100%">
      <el-table-column width="155" min-width="155" prop="ORDERDATE" label="订单时间" :formatter="datetimeFormatter"></el-table-column>
<!--      <el-table-column style="width:10%;" prop="PAY_TYPE_NAME" label="支付类型"></el-table-column>-->
      <el-table-column style="width:10%;" prop="ORDERNUM" label="订单号"></el-table-column>
      <el-table-column style="width:10%;" prop="ALLPRICE" label="总金额"></el-table-column>
<!--      <el-table-column style="width:10%;" prop="ALLPURCHASE" label="原价"></el-table-column>-->
      <el-table-column style="width:10%;" prop="SNAME" label="商铺名称"></el-table-column>
      <el-table-column label="" style="width:10%;">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleView(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

  </div> <!-- root div -->
</template>
<script>
import axios from "axios";
import { base_url } from "@/common/constant";

export default {
  name: "OrderSearch",
  props: ['status'],
  data() {
    return {
      tableData: [],
    };
  },//data
  methods: {
    handleView(index, row){
      const that = this;
      that.$store.commit('change_order_main', row);
      const userNum = row.USERNUM;
      const orderNum = row.ORDERNUM;
      that.remote_querySendOrReceivingDetail(userNum, orderNum).then(res=>{
        if (res.flag) {
          that.$store.commit('change_drawer_order_detail', res.data);
          that.$nextTick(() => {
            that.$store.commit('change_drawer_isShow', true);
          });
        }
      });
    },
    // row, column, cellValue, index
    datetimeFormatter(row, column, cellValue){
      if (cellValue) {
        let val = cellValue.substring(0, 19);
        return val.replace('T', ' ');
      } else {
        return "";
      }
    },

    // /webStore/queryOrderByUser/{orderState}
    remote_queryOrderByUser(orderState){
      return new Promise((resolve) => {
        axios.get(`${base_url}/webStore/queryOrderByUser/${orderState}`).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          resolve({flag: false, err: res});
        });
      });
    },

    // /webStore/querySendOrReceivingDetail/{userNum}/{orderNum}
    remote_querySendOrReceivingDetail(userNum, orderNum){
      return new Promise((resolve) => {
        axios.get(`${base_url}/webStore/querySendOrReceivingDetail/${userNum}/${orderNum}`).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          resolve({flag: false, err: res});
        });
      });
    },

  },//methods
  created() {
    const that = this;
    const orderState = that.status;
    that.$nextTick(()=>{
      that.remote_queryOrderByUser(orderState).then(res=>{
        if (res.flag) {
          that.tableData = res.data;
        }
      });
    });
  }, // created
}
</script>
