const data = {};

export const setRouter = router => {
  data.router = router
}
export const setStore = store => {
  data.store = store
}
export const setApp = app => {
  data.app = app
}
export const getRouter = () => data.router
export const getStore = () => data.store
export const getApp = () => data.app


