
<template>
  <div class="home">
    <div class="content">

      <div class="cart">

        <!-- 表头 -->
        <div class="cart-table-th">
          <div class="th th-chk">
            <div class="select-all">
              <div class="cart-checkbox">
                <input class="check-all check" type="checkbox" :checked="allChecked" @click="handleAllChecked()">
              </div>
              <label>&nbsp;&nbsp;全选</label>
            </div>
          </div>
          <div class="th th-item">
            <div class="th-inner">
              商品
            </div>
          </div>
          <div class="th th-price">
            <div class="th-inner">
              单价
            </div>
          </div>
          <div class="th th-amount">
            <div class="th-inner">
              数量
            </div>
          </div>
          <div class="th th-sum">
            <div class="th-inner">
              小计
            </div>
          </div>
          <div class="th th-op">
            <div class="th-inner">
              操作
            </div>
          </div>
        </div>

        <div class="OrderList">
          <div class="order-content" id="list-cont">
            <ul class="item-content layui-clear" v-for="(item, index) of shoppingCartList" :key="index">

              <!-- checkbox -->
              <li class="th th-chk">
                <div class="select-all">
                  <div class="cart-checkbox">
                    <input class="CheckBoxShop check" type="checkbox" :checked="item.checked" @click="handleItemChecked(index)">
                  </div>
                </div>
              </li>

              <li class="th th-item">
                <div class="item-cont">
                  <a href="javascript:;"><img :src="itemUrl(item)" alt=""
                                              style="height: 98px;width: 98px;"></a>
                  <div class="text">
                    <div class="title">{{item.PNAME}}</div>
                    <p><span>规格：{{item.SPEC}}</span></p>
                    <p><span>{{item.BUYNUM}} {{item.UNITNAME}}</span></p>
                  </div>
                </div>
              </li>

              <li class="th th-price">
                <span class="th-su">{{item.PURCHASEPRICE}}</span>
              </li>

              <!-- 数量 -->
              <li class="th th-amount">
                <div class="box-btn layui-clear">
                  <div class="less layui-btn" @click="handleSubtract(index)">-</div>
                  <input class="Quantity-input" type="" name="" :value="item.BUYNUM" disabled="disabled">
                  <div class="add layui-btn" @click="handleAdd(index)">+</div>
                </div>
              </li>

              <li class="th th-sum">
                <span class="sum">{{ item_price(item) }}</span>
              </li>

              <li class="th th-op">
                <span class="dele-btn" @click="handleDelete(item)">删除</span>
              </li>

            </ul>

          </div>
        </div>

        <div class="FloatBarHolder layui-clear">
          <div class="th th-chk">
            <div class="select-all">
              <div class="cart-checkbox">
                <input class="check-all check" type="checkbox" :checked="allChecked" @click="handleAllChecked">
              </div>
              <label>&nbsp;&nbsp;已选<span class="Selected-pieces">{{ selectedNum }}</span>件</label>
            </div>
           
          </div>
          <div class="th batch-deletion">
           <span class="batch-dele-btn">{{info.mtzName}}</span>
          </div>
          <div class="th Settlement">
            <button class="layui-btn" @click="handleSettleIn">结算</button>
          </div>
          <div class="th total">
            <p>应付：<span class="pieces-total">{{ totalPrice }}</span></p>
          </div>
        </div>

      </div>
      <!-- end -->


    </div>
  </div><!-- root div-->
</template>
<script>

// import home from "@/json/home.json";

// import shoppingJson from "@/json/shopping_cart.json";

// import { isFunction } from "lodash";
// import { cloneDeep } from 'lodash'

import axios from "axios";
import {base_url} from "@/common/constant";

import { checkLogin } from "@/common/tools";

import Decimal from "decimal.js";

export default {
  name: 'ShoppingCart',
  data() {
    return {
      bnum: '',
      orderNum: '', // 订单号
      shoppingCartList: [],
      allChecked: true,
      orderInfo: '',
      btnAddDisabled: false,
      btnSubtractDisabled: false,
      btnDeleteDisabled: false,
      supplierBName:"",//商家名称
      info:{
        mtzName:""
      },
    };
  },//data end
  computed: {
    selectedNum() {
      const that = this;
      if (that.shoppingCartList && that.shoppingCartList.length > 0) {
        let result = 0;
        for (let item of that.shoppingCartList) {
          if (item.checked == true) {
            result += Number.parseInt(item.BUYNUM);
          }
        }
        return result;
      } else {
        return 0;
      }
    },
    totalPrice() {
      const that = this;
      if (that.shoppingCartList && that.shoppingCartList.length > 0) {
        let result = new Decimal(0);
        for (let item of that.shoppingCartList) {
          if (item.checked == true) {
            //  Number.parseInt(item.BUYNUM) * Number.parseFloat(item.PURCHASEPRICE);
            result = result.add(new Decimal(item.BUYNUM).mul(new Decimal(item.PURCHASEPRICE)));
          }
        }
        return result.toFixed(2);
      } else {
        return 0;
      }
    },
  },
  methods: {
    queryBiz(){
      let that = this;
      that.$axios(`${base_url}/webStore/queryBizRzInfo/${that.bnum}`).then(res=>{
        if(res.data.status==200){
            that.info = res.data.data
        }else{
          that.$message.error(res.data.message);
        }
      })
    },
    item_price(item){
      let res = new Decimal(item.PURCHASEPRICE).mul(new Decimal(item.BUYNUM));
      return res.toFixed(2);
    },

    handleSettleIn(){
      const that = this;
      if (that.shoppingCartList.length <= 0) {
        that.$message.error("没有结算的商品");
        setTimeout(()=>{
          that.$router.push(`/?v=${Math.random()}`);
        }, 1500);
      } else {
        // orderInfo , buyOrderNum
        if (that.orderInfo && that.orderInfo.buyOrderNum) {
          that.$router.push(`/payment?orderNum=${that.orderInfo.buyOrderNum}&bnum=${that.bnum}&supplierBName=${that.info.mtzName}`);
        }
      }
    },

    itemUrl(item){
      if (item && item.PRUL) {
        let url = item.PRUL;
        if (url && !url.includes('http')) {
          url = `${base_url}/file/show?fileName=${url}`;
        }
        return url;
      } else {
        return "";
      }
    },
    handleAllChecked(){
      const that = this;
      that.allChecked = true; // 不允许修改为false
      // that.allChecked = !that.allChecked;
      // if (that.shoppingCartList && that.shoppingCartList.length > 0) {
      //   for (let i = 0; i < that.shoppingCartList.length; i++) {
      //     const item = that.shoppingCartList[i];
      //     item['checked'] = that.allChecked;
      //     that.$set(that.shoppingCartList, i, item);
      //   }
      // }
    },
    handleItemChecked(index){
      const that = this;
      let item = that.shoppingCartList[index];
      item.checked = true; // 不允许修改为false
      // item.checked = !item.checked;
      // that.$set(that.shoppingCartList, index, item);
    },
    handleSubtract(index) {
      const that = this;
      if (!that.btnSubtractDisabled) {
        that.btnSubtractDisabled = true;
        let item = that.shoppingCartList[index];
        if (item.BUYNUM && Number.parseInt(item.BUYNUM) > 1) {
          item.BUYNUM = Number.parseInt(item.BUYNUM) - 1;
          that.$set(that.shoppingCartList, index, item);
          that.remote_updateCartDetail(item).then(res=>{
            if (res.flag) {
              that.btnSubtractDisabled = false;
            } else {
              item.BUYNUM = Number.parseInt(item.BUYNUM) + 1;
              that.$set(that.shoppingCartList, index, item);
              that.btnSubtractDisabled = false;
              let errMsg = "系统升级中，请稍候";
              if (res.err.message && res.err.message.length < 30) {
                errMsg= res.err.message;
                that.$message.error(errMsg);
              }
            }
          }).catch(err=>{
            console.error(err);
            item.BUYNUM = Number.parseInt(item.BUYNUM) + 1;
            that.$set(that.shoppingCartList, index, item);
            that.btnSubtractDisabled = false;
          });
        } else {
          that.btnSubtractDisabled = false;
        }
      }
    },
    handleAdd(index) {
      const that = this;
      if (!that.btnAddDisabled) {
        that.btnAddDisabled = true;
        let item = that.shoppingCartList[index];
        item.BUYNUM = Number.parseInt(item.BUYNUM) + 1;
        that.$set(that.shoppingCartList, index, item);
        that.remote_updateCartDetail(item).then(res=>{
          if (res.flag) {
            that.btnAddDisabled = false;
          } else {
            item.BUYNUM = Number.parseInt(item.BUYNUM) - 1;
            that.$set(that.shoppingCartList, index, item);
            that.btnAddDisabled = false;
            let errMsg = "系统升级中，请稍候";
            if (res.err.message && res.err.message.length < 30) {
              errMsg= res.err.message;
              that.$message.error(errMsg);
            }
          }
        }).catch(err=>{
          console.error(err);
          item.BUYNUM = Number.parseInt(item.BUYNUM) - 1;
          that.$set(that.shoppingCartList, index, item);
          that.btnAddDisabled = false;
        });
      }
    },

    handleDelete(item){
      const that = this;
      if (!that.btnDeleteDisabled) {
        that.btnDeleteDisabled = true;
        that.remote_delCartDetail(item).then(res=>{
          if (res.flag) {
            that.refresh();
          } else {
            // console.error(res.err);
            let errMsg = "系统升级中，请稍候";
            if (res.err.message && res.err.message.length < 30) {
              errMsg = res.err.message;
            }
            that.$message.error(errMsg);
          }
        }).catch(err=>{
          console.error(err);
        });
      }
    },

    // http://127.0.0.1/mall/webStore/delCartDetail/717/10001/11628231124264
    remote_delCartDetail(item){
      const that = this;
      //修改bug，之前的入参未传单位编号，time:2022-09-14
      const cid = [];
      cid.push(item.CID+":"+item.BUYUNITNUM);

      return new Promise((resolve) => {
        let url = `${base_url}/webStore/delCartDetail`;
        url += `/${cid}/${item.SUPPLIERBNUM}/${that.orderInfo.buyOrderNum}`;
        axios.delete(url).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          console.error(res);
          resolve({flag: false, err: res});
        });
      });
    },

    // http://127.0.0.1/mall/webStore/updateCartDetail/2/717/10001/CU002
    remote_updateCartDetail(item){
      return new Promise((resolve) => {
        let url = `${base_url}/webStore/updateCartDetail`;
        url += `/${item.BUYNUM}/${item.CID}/${item.SUPPLIERBNUM}/${item.BUYUNITNUM}`;
        axios.put(url).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            console.error(res);
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          console.error(res);
          resolve({flag: false, err: res});
        });
      });
    },

    // http://127.0.0.1/mall/webStore/getCartDetail/11628231124264
    remote_getCartDetail(orderNum) {
      return new Promise((resolve) => {
        axios.get(`${base_url}/webStore/getCartDetail/${orderNum}`).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          console.error(res);
          resolve({flag: false, err: res});
        });
      });
    },

    refresh() {
      // "created"中调用, "handleDelete"也调用
      const that = this;
      if (that.bnum && that.orderNum) {
        that.remote_getCartDetail(that.orderNum).then(res=>{
          if (res.flag) {
            let arr = res.data;
            if (arr && arr.length > 0) {
              that.orderInfo = arr.shift();
              that.shoppingCartList = [];
              for (let item of arr) {
                item.checked = true;
                that.shoppingCartList.push(item);
              }
            } else {
              that.shoppingCartList = [];
            }
          }
        }).catch(err=>{
          console.error(err);
        });
      }
    },

    // /webStore/getUserOrderNum
    remote_getUserOrderNum(){
      return new Promise((resolve) => {
        axios.get(`${base_url}/webStore/getUserOrderNum`).then(res=>{
          res = res.data;
          if (res.status == 200) {
            resolve({flag: true, data: res.data});
          } else {
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          resolve({flag: false, err: res});
        });
      });
    },

  },//methods end
  created() {
    const that = this;

    that.shoppingCartList = [];
    checkLogin().then(res=>{
      if (!res.flag) {
        const url = that.$route.fullPath;
        // 请先登录
        that.$store.commit('change_url_before_login', url);
        that.$message.warning("请先登录!");
        setTimeout(()=>{
          that.$router.push('/login');
        }, 1500);
      } else {
        const query = that.$route.query;
        if (query && query.bnum && query.orderNum) {
          that.bnum = query.bnum;
          that.orderNum = query.orderNum;
          that.refresh();
          that.queryBiz();
        } else {
          // 没有传递'bnum'和'orderNum'是用户直接点击"购物车"，此时需要先查询出'bnum'和'orderNum'
          that.remote_getUserOrderNum().then(res=>{
            if (res.flag) {
              const arr = res.data;
              if (arr && arr.length > 0) {
                that.orderNum = arr[0].buyOrderNum;
                that.bnum = arr[0].supplierBNum;
                that.supplierBName = arr[0].supplierBName
                that.refresh();
                that.queryBiz();
              }
            }
          });
        }

      }
    }).catch(()=>{});
  },//created end
}
</script>
<style lang="scss" scoped>
.home {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  .content {
    width: 1200px;
    display: block;
  }
}


.cart{padding-bottom: 180px;}
.cart-table-th{height: 50px; line-height: 50px; background:  #f5f5f5;}
.th{float: left; text-align: center;}
.th-chk{width: 70px; position: relative; margin-left: 43px; text-align: left;}
.th-chk .select-all{cursor: pointer;}
.th-chk .select-all label{cursor: pointer;}
.cart-checkbox{display: inline-block;}
.CheckBoxShop{width: 16px; height: 16px; border:1px solid #cccccc; position: relative; top: 4px;}
.th-item{width: 430px; text-align: left; padding-left: 90px; box-sizing: border-box;}

.th-price{width: 150px; }
.th-amount{width: 200px; }
.th-sum{width: 200px;}
.th-op{width: 100px;}
.item-content{border:1px solid #ececee; border-bottom: 0; height: 218px; padding:59px 0;
  box-sizing: border-box; position: relative;}
.order-content .th-chk{margin-top: 35px; position: relative; z-index: 2;}
.order-content .th-item{position: relative; left: -120px; z-index: 1;}
.order-content .th-amount div.layui-btn, .order-content .th-amount input{
  float: left; border:1px solid #e2e2e2; text-align: center; background: #ffffff; margin-left: -1px; color: #999999;}
.order-content .th-amount div.layui-btn{width: 28px; height: 38px; padding:0;}
.order-content .th-amount input{width: 46px; height: 36px;}
.order-content .th-amount .box-btn{padding-left: 52px;}
.order-content .th-sum span{line-height: 24px; color: #ee0000; font-weight: bold; }
.order-content .th-op span{display: block; cursor: pointer; line-height: 24px;}
.order-content .th-item .item-cont img{float: left; width: 98px; height: 98px;  border:1px solid #ececee;
  margin-right: 20px;}
.order-content .th-item .item-cont .text{text-align: left; line-height: 24px;}
.order-content .th-item .item-cont .text .title{color: #333333;}
.order-content .th-item .item-cont .text p{color: #888;}
.FloatBarHolder{line-height: 78px; border:1px solid #ececee; background: #f5f5f5; position: relative;}
.FloatBarHolder .th-chk {width: 150px;}
.FloatBarHolder .batch-deletion span{cursor: pointer;}
.FloatBarHolder .Settlement, .FloatBarHolder .total{float: right;}
.FloatBarHolder .Settlement button.layui-btn{width: 160px; height: 84px; line-height: 84px;
  font-size: 24px; color: #fff; text-align: center; background: #ff5500; border-radius: 0; position: absolute;
  right: 0px; top: -1px;}
.FloatBarHolder .total{margin-right: 230px;}
.FloatBarHolder .total span{color: #ee0000; font-size: 26px; font-weight: bold; padding-left: 5px;}
.FloatBarHolder .th-chk .select-all label{letter-spacing: 4px;}



</style>
