<template>
  <el-dialog title="" top="2vh" :visible.sync="dialogVisible" width="60%">
    <div class="content">
      <!-- <div class="title">
        <img src="@/assets/new/ruzhu.png" alt="" class="banner" />
        <div class="titles"></div>
        <div class="zhaohui">
          <div class="zhaohuis" style="width: 100%;text-align: center;font-weight: bold;">隐私政策</div>
          <img src="@/assets/new/jt.png" alt="" class="xiangxia" />
        </div>
      </div> -->
      <div class="xieyi">
        <div
          style="width: 98%; margin: 0 auto; height: 90%; overflow-y: scroll"
        >
          <div class="xieyis">
            <div
              class="xuzhi"
              style="width: 100%; text-align: center; font-weight: bold"
            >
              隐私政策
            </div>
            <div class="contents" style="text-indent: 2em; margin-top: 30px">
              本隐私政策与您所使用的本商城服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。
            </div>
            <div class="titles" style="padding-top: 60px">
              一、我们如何收集和使用您的个人信息
            </div>
            <div class="contents" style="text-indent: 2em; margin-top: 30px">
              个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
            </div>
            <div class="contents" style="text-indent: 2em">
              我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
            </div>
            <div class="titles" style="padding-top: 60px">
              （一）您须授权我们收集和使用您个人信息的情形
            </div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
            </div>
            <div class="contents" style="text-indent: 2em">
              1、实现网上购物所必须的功能<br />
              （1）账号注册、登录与验证<br />
              当您注册本商城账号时，您至少需要向我们提供您准备使用的本商城账号名、密码、手机号码、我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账号名为您的默认昵称。
              当您成功注册本商城账号后，可以使用本商城账号登录“本商城商城”，登录时您需要向我们提供您的本商城账号信息。若您不提供这类信息，您将无法登录和在“本商城商城”购买商品，此时您可使用浏览和搜索功能，我们将不会收集您的上述个人信息。
            </div>
            <div class="contents" style="text-indent: 2em">
              (2）商品/服务信息展示和搜索
            </div>
            <div class="contents" style="text-indent: 2em">
              为了向您展示商品或者服务，我们会收集您的设备信息（包括设备名称、设备型号、设备所在位置相关信息、浏览器类型来对相应的商品或者服务内容进行排序以适应您的设备界面。<br />
              为了向您提供搜索历史、购物车或订单商品或商品展示，我们会收集您的浏览信息、搜索记录、加购信息、订单信息。
            </div>
            <div class="contents" style="text-indent: 2em">
              （3）下单及订单管理<br />
              当您准备对您购物车内的商品进行结算时，本商城系统会生成您购买商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的货款金额及支付方式。
              <br />上述所有信息构成您的“订单信息”，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
              <br />（4）支付功能
              <br />在您下单后，您可以选择本商城的关联方所提供的支付服务。我们需要将您的本商城订单号与交易金额信息与这些支付机构共享以完成下单支付功能。在您使用支付功能时，支付机构会通过其页面收集或验证您的银行卡（含储蓄卡、信用卡）及其他支付必要信息，以实现其确认您的支付指令并完成支付，具体适用各支付机构相应的隐私政策。
              <br />(5）售后功能
              <br />当您需要我们提供与您订单信息相关的售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
              <br />(五）您个人信息使用的规则
              <br />1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
              &nbsp;&nbsp;<br />
              2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。
              &nbsp;&nbsp;
              <br />3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。
              <br />二、我们如何使用Cookies和同类技术 <br />(一）Cookies的使用
              <br />1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账号或数据安全。
              <br />2、我们不会将Cookies用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问本商城网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
              <br />三、我们如何对外提供您的个人信息 <br />(一）共享
              <br />1、我们不会与本商城以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
              &nbsp;&nbsp;&nbsp;<br />(1）事先获得您明确的同意或授权；
              &nbsp;&nbsp;&nbsp;<br />（2）根据法律法规、行政及司法部门强制性要求进行提供；
              &nbsp;&nbsp;&nbsp;<br />（3）在法律法规允许的范围内，为维护本商城、本商城的关联方或合作伙伴、您或其他本商城用户或社会公众利益、财产或安全免遭损害而有必要提供；
              &nbsp;&nbsp;&nbsp;<br />（4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
              &nbsp;&nbsp;&nbsp;<br />（5）应您需求为您处理您与他人的纠纷或争议；
              &nbsp;&nbsp;&nbsp;<br />（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
              <br />(二）转移
              <br />在因合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式，要求接收您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
              <br />(三）公开披露
              <br />我们仅会在以下情况，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
              &nbsp;&nbsp;&nbsp;<br />(1）根据您单独同意的授权范围披露相应个人信息；
              &nbsp;&nbsp;&nbsp;<br />（2）如果您严重违反法律法规或者相关协议、规则，我们可能会披露您的相关违规行为及我们对您采取的措施。
              <br />四、我们如何保护和保存您的个人信息
              <br />(一）我们保护您个人信息的技术与措施
              <br />我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
              <br />1、数据安全技术措施
              <br />我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。例如：本商城的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全；本商城采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离；在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性；本商城采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用；本商城采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。
              <br />2、本商城为保护个人信息采取的其他安全措施
              <br />本商城通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；
              我们仅允许有必要知晓这些信息的本商城及本商城关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。
              互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过本商城与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
              <br />3、安全事件处置
              <br />为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的相应处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
              一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
              如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本隐私政策“八、如何联系我们”中明确的联系方式联络我们，以便我们采取相应措施。
              <br />(二）您个人信息的保存
              <br />1、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
              <br />2、请您注意，当您成功注销本商城账号后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
              <br />3、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
              <br />五、您如何管理您的个人信息
              <br />本商城非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
              <br />1、访问和更正您的个人信息
              <br />除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：
              <br />(1）您的账号信息：PC端您可以在个人信息中查阅。
              <br />(2）您的收货信息：PC端您可以在个人信息中新增、修改或删除。
              <br />（3）您的订单信息：PC端您可以通过访问“我的订单”页面查看您的所有已经完成、待付款或待售后的订单。
              <br />2、删除您的个人信息
              <br />您在我们的产品与/或服务页面中可以直接清除或删除的信息。
              <br />在以下情形中，您可以向我们提出删除个人信息的请求：
              <br />(1）如果我们处理个人信息的行为违反法律法规；
              <br />(2）如果我们收集、使用您的个人信息，却未征得您的同意；
              <br />(3）如果我们处理个人信息的行为严重违反了与您的约定；
              <br />(4）如果您不再使用我们的产品或服务，或您注销了本商城账号；
              <br />(5）如果我们终止服务及运营，或者保存期限已届满。
              <br />&nbsp;&nbsp;请您注意，当您成功注销本商城账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。
              <br />六、未成年人的个人信息保护
              <br />1、本商城非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。本商城根据国家相关法律法规的规定保护未成年人的个人信息。
              <br />2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
              <br />3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
              <br />七、通知和修订
              <br />1、为给您提供更好的服务以及随着本商城业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在本商城网站发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问本商城以便及时了解最新的隐私政策。
              <br />2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
              <br />八、如何联系我们
              <br />1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。
              个人信息保护负责人邮箱1523139822@qq.com
              <br />2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
            </div>
          </div>
        </div>

        <div class="button" style="margin-top: 50px">
          <div class="button02" @click="dialogVisible = false">我已知晓</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    routers() {
      window.close();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
/deep/ .el-dialog__header {
  // display: none;
}
.button {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  .button01 {
    width: 180px;
    height: 60px;
    background: #f3f4f7;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
  }
  .button02 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 254px;
    height: 60px;
    background: #ff3d09;
    border-radius: 30px;
    font-size: 24px;
    color: #fff;
    margin-left: 20px;
  }
}
.xieyi {
  .titles {
    color: #515151;
    font-size: 24px;
  }
  .contents {
    color: #515151;
    font-size: 24px;
    line-height: 38px;
  }
  width: 100%;
  margin: 0 auto;
  height: 70vh;
  background: #fff;
  border-radius: 20px;
  // margin-top: -113px;
  // position: relative;
  // z-index: 999;
  padding-top: 50px;
  padding-bottom: 50px;
  .xieyis {
    width: 100%;
    margin: 0 auto;
    .xuzhi {
      font-size: 24px;
      line-height: 42px;
      padding-bottom: 40px;
      border-bottom: 1px solid #ebedf3;
    }
  }
}
.success {
  width: 360px;
  height: 60px;
  background: #ff3d09;
  box-shadow: 0px 3px 24px 0px rgba(255, 61, 9, 0.42);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  margin-top: 35px;
  cursor: pointer;
  margin-left: 125px;
}
.code {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 25px;
  .codes {
    width: 1px;
    height: 17px;
    background: #d5d6d8;
  }
  .btnText {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ff3d09;
    margin-left: 14px;
  }
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    .inouts {
      font-size: 16px;
      width: 50%;
      margin-left: 44px;
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
    .zhaohui {
      width: 18px;
      height: 24px;
      margin-left: 25px;
    }
    width: 360px;
    height: 56px;
    background: #fff;
    border: 1px solid #d6d8de;
    box-shadow: 0px 3px 5px 0px #edeef0;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .form_title {
    width: 120px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    text-align: right;
  }
}
.neirong {
  width: 1100px;
  height: 595px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.biaoshi {
  width: 1100px;
  height: 103px;
  background: #fff;
  left: 50%;
  margin-left: -550px;
  position: absolute;
  bottom: 0px;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .dangqian {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 10px;
  }
  .biaoshis {
    width: 1161px;
    margin: 0 auto;
    height: 89px;
    border-bottom: 1px solid #edeef6;
    font-size: 24px;
    color: #515151;
  }
}
.zhaohui {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .zhaohuis {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    top: 106px;
  }
  .xiangxia {
    width: 47px;
    height: 41px;
    position: absolute;
    top: 195px;
  }
}
.content {
  width: 100%;
  padding-bottom: 30px;
  .title {
    width: 100%;
    height: 418px;
    background: #fff;
    position: relative;
    .titles {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.32;
      position: absolute;
      z-index: 2;
    }
    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
}
</style>