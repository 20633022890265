<template>
  <div class="home">

    <div class="regist" style="padding-bottom: 20px;margin-bottom: 40px;">
      <div class="regist_center">
        <div class="regist_top">
          <div class="left fl">会员注册</div>
          <div class="right fr"><router-link to="/login">登录</router-link></div>
          <div class="clear"></div>
          <div class="xian center"></div>
        </div>
        <div class="regist_main center">
          <div class="username">用&nbsp;&nbsp;户&nbsp;&nbsp;名:&nbsp;&nbsp;
            <input class="shurukuang" type="text" @keyup="usernameInputLimit" v-model="userinfo.username"
                   placeholder="请输入你的用户名"/>
          </div>
          <div class="username">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码:&nbsp;&nbsp;
            <input class="shurukuang" @blur="pwd2blur" @keyup="passwordInputLimit" type="password"
                   v-model="userinfo.password" placeholder="请输入你的密码"/>
            <span style="color: #ff6700;" v-if="pwdTip != ''">{{ pwdTip }}</span>
          </div>
          <div class="username">确认密码:&nbsp;&nbsp;
            <input class="shurukuang" @blur="pwd2blur" type="password" v-model="password2" placeholder="请确认你的密码"/>
            <span style="color: #ff6700;" v-if="pwdErrMsg != ''">{{ pwdErrMsg }}</span>
          </div>
          <div class="username">手&nbsp;&nbsp;机&nbsp;&nbsp;号:&nbsp;&nbsp;
            <input class="shurukuang" type="text" v-model="userinfo.phone" placeholder="请填写正确的手机号"/>
            <el-button type="info" class="btn-send" :disabled="disabled"
                       @click="sendVerifyCode"> {{btnText}} </el-button>
          </div>
          <div class="username">
            <div class="left fl">验&nbsp;&nbsp;证&nbsp;&nbsp;码:&nbsp;&nbsp;
              <input class="yanzhengma" type="text" v-model="userinfo.verifyCode" placeholder="请输入验证码"/></div>
<!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
        </div>
  <div style="margin-left: 45px;padding-top: 20px;display: flex;align-items: center;">
      <el-checkbox v-model="checked">
        阅读并同意
      </el-checkbox>
  	<span :style="{color:checked==true?'#409EFF':''}" style="font-weight: bold;cursor: pointer;" @click.stop="$refs.fuwuHi.dialogVisible=true">《用户服务协议》</span>
  	<span :style="{color:checked==true?'#409EFF':''}">和</span>
  	<span :style="{color:checked==true?'#409EFF':''}" style="font-weight: bold;cursor: pointer;" @click.stop="$refs.yinsiHi.dialogVisible=true">《隐私政策》</span>
    </div>
        <div class="regist_submit">
          <input class="submit" type="button" value="立即注册" @click="handleRegister">
        </div>

      </div>
    </div>
    <fuwuXieyi ref="fuwuHi" />
    <yinsiXieyi ref="yinsiHi" />
  </div><!-- root div-->
</template>
<script>

// import home from "@/json/home.json";

import axios from "axios";
import { base_url } from "@/common/constant";
import fuwuXieyi from "@/components/components/xieyi/fuwu.vue"
import yinsiXieyi from "@/components/components/xieyi/yinsi.vue"
export default {
  components:{
    fuwuXieyi,yinsiXieyi
  },
  name: 'Register',
  data() {
    return {
      userinfo: {
        username: '',
        password: '',
        phone: '',
        verifyCode: '', // 验证码
      },
      password2: '',
      pwdTip: '',
      pwdErrMsg: '', // 两次密码不一致时显示
      btnText: '发送验证码',
      disabled: false, // 发送验证码按钮的启用和禁用
      checked:false
    };
  },//data end
  methods: {

    usernameInputLimit(){
      const reg = /[^\w_.@]/g;
      if (reg.test(this.userinfo.username)) {
        this.userinfo.username = this.userinfo.username.replace(reg, '');
        this.$message.info("只能输入大小写字母、数字、下划线.@");
      }
    },

    passwordInputLimit(){
      const reg = /[^\w_.@]/g;
      if (reg.test(this.userinfo.password)) {
        this.userinfo.password = this.userinfo.password.replace(reg, '');
        this.$message.info("只能输入大小写字母、数字、下划线.@");
      }
    },

    checkBeforeSubmit() {
      // 校验，提交前的校验
      const that = this;
      const arr = [
        {field: 'username', msg: '请输入用户名'}, {field: 'password', msg: '请输入密码'},
        {field: 'phone', msg: '请输入手机号'}
      ];
      for (let item of arr) {
        if (that.userinfo[item.field] == '') {
          that.$message.error(item.msg);
          return false;
        }
      }
      if (that.userinfo.password.length < 6) {
        that.$message.error("请输入6位以上的字符");
        return false;
      }
      if (that.password2 == '') {
        that.$message.error("请确认密码");
        return false;
      }
      if (that.userinfo.password != that.password2) {
        that.$message.error("两次密码输入不一致，请确认！");
        return false;
      }
      if (that.userinfo.verifyCode == '') {
        that.$message.error("请输入验证码");
        return false;
      }
      return true; // 千万别忘记了
    },

    handleRegister(){
      // 注册
      const that = this;
      if(!that.checked){
          this.$message.error("请阅读并同意用户服务协议和隐私政策");
          return;
        }
      if (that.checkBeforeSubmit()) {
        //
        axios.post(`${base_url}/webStoreUser/register`, that.userinfo).then(res=>{
          res = res.data;
          if (res.status == 200) {
            that.$message.success("注册成功");
            setTimeout(()=>{
              that.$router.push('/login');
            }, 1500);
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.message && res.message.length < 30) {
              errMsg = res.message;
            }
            that.$message.error(errMsg);
            console.error(res);
          }
        }).catch(res=>{
          console.error(res);
        });
      }
    },

    pwd2blur(){
      // input失去焦点时触发
      const that = this;
      if (that.userinfo.password) {
        if (that.userinfo.password.length < 6) {
          that.pwdTip = "请输入6位以上的字符";
          return;
        } else {
          that.pwdTip = "";
        }
      }
      if (that.userinfo.password && that.password2) {
        if (that.userinfo.password != that.password2) {
          that.pwdErrMsg = "两次密码输入不一致，请确认！";
          return;
        } else {
          that.pwdErrMsg = "";
        }
      }
    },

    countDown(time) {
      // 倒计时
      let that = this;
      const interval = setInterval(function() {
        if (time > 0) {
          that.disabled = true; //禁用点击
          --time;
          that.btnText = time + 's后重试';
        } else {
          that.disabled = false; //开启可点击
          that.btnText = '发送验证码';
          clearInterval(interval);
        }
      }, 1000);
    },
    rou(path){
         // 使用$router.resolve方法获取到目标URL对应的RouteLocationNormalizedLoaded对象
         const route = this.$router.resolve(path);
      
      // 创建一个a元素并将其添加到body中
      const aElement = document.createElement('a');
      aElement.href = route.href;
      aElement.setAttribute('target', '_blank');
      document.body.appendChild(aElement);
      
      // 模拟点击事件，自动打开新窗口
      aElement.click();
      
      // 移除已经添加的a元素
      document.body.removeChild(aElement);
    },
    sendVerifyCode() {
      // 获取验证码
      const that = this;
      if (!that.userinfo.phone) {
        that.$message.error("请输入手机号");
      } else {
        if (!that.disabled) {
          that.disabled = true;
          axios.get(`${base_url}/CustomerVerifyCode/send/${that.userinfo.phone}`).then(res=>{
            res = res.data;
            if (res.status == 200) {
              that.$message.success("发送成功");
              that.countDown(60);
            } else {
              let errMsg = '系统升级中，请稍候';
              if (res.message && res.message.length < 30) {
                errMsg = res.message;
              }
              that.$message.error(errMsg);
              console.error(res);
              that.disabled = false;
            }
          }).catch(res=>{
            console.error(res);
            that.disabled = false;
          });
        }
      }
    },

  },//methods end
  created() {
  },//created end
}
</script>
<style lang="scss" scoped>
.home {
  display: block;
  margin: 0;
  padding: 20px 0 0 0;
  width: 100%;
  background-color: #f8f8f8;
}

.fl{float: left;}
.fr{float: right;}
.clear{clear:both;}
.center{margin:0 auto;}
.border{border:1px solid red;}

/*用户注册*/
.regist{width: 1200px;height: 550px;margin:0 auto;background:#fff;color:#000;border-radius: 10px;}
.regist_center{width: 760px;margin: 0 auto;}
.regist .regist_top{margin: 0;}
.regist .regist_top .left{height: 40px;line-height: 40px;font-weight: bold;font-size: 20px;}
.regist .regist_top .right{height: 40px;line-height: 40px;font-size: 13px;}
.regist .regist_top .right a{color:#ff6700;font-weight: bold;}
.regist .regist_top .right a:hover{color:orange;}
.xian{height: 2px;background: #ff6700;margin: 8px auto;}

.regist_main{padding:10px 0;padding-left:45px;}
.regist_main .username{font:16px Times New Roman;height: 40px;line-height: 40px;margin:20px 0;}
.regist_main .username .shurukuang{width: 220px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .yanzhengma{width: 100px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .shurukuang:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .yanzhengma:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .right{margin-left: 20px;}

.regist_main .username span{display:inline-block;margin-left:20px;font-size: 12px;color: #ccc;font-weight: bold;}
.regist_main .username span:hover{color: #ff6700;}

.regist .regist_center .regist_submit{margin: 20px auto;}
.regist .regist_center .submit{border:none;width: 440px;height: 45px;margin-left:45px;
  background: #ff6700;color: #fff; font-size: 22px;font-weight: bold;letter-spacing: 8px;cursor:pointer;}
.regist .regist_center .submit:hover{
  border:1px solid lighten(#ff6700, 10%);
  background-color: lighten(#ff6700, 10%);
}

.btn-send {
  display: inline-block;
  margin-left: 10px;
  width: 140px;
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}


</style>
