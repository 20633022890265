<template>
    <div class="coupon">
      <el-empty description="暂无数据" v-if="list.length==0" style="margin: 0 auto;"></el-empty>
       <div class="coupons" v-for="(item,i) in list" :key="i">
          <img src="@/assets/new/youhui01.png" class="shiyong" alt="" v-if="item.isUse=='N'">
          <img src="@/assets/new/youhui02.png" class="shiyong" alt="" v-if="item.isUse=='E'">
          <div class="neirong">
             <div class="price" :style="{color:item.isUse=='E'?'#515151':''}">
                <span>￥</span>{{item.subPrice}}
             </div>
             <div class="man" :style="{color:item.isUse=='E'?'#515151':''}">
                满{{item.xfAmount}}可用
             </div>
             <div class="youxiaoqi">
                有效期至：{{item.validTime}}
             </div>
             <div class="liji" @click="shiyong(item)">
                {{
                  item.isUse=='N'?"立即使用":item.isUse=='Y'?"已使用":item.isUse=="E"?"已过期":""
                }}
             </div>
             <div class="shangjia" :class="[item.isUse=='E'?'shangjias':'']">
              <span>{{item.mtzName}}</span>
             </div>
             <img src="@/assets/new/yishiyong.png" class="yishiyong" v-if="item.isUse=='Y'" alt="">
          </div>
       </div>
    </div>
</template>

<script>
	import { checkLogin } from "@/common/tools";
    export default {
        data(){
            return{
               list:[],
               loading:false,
            }
        },
        props:{
          yhState:{
            type:String,
            default:"1"
          },
        },
        watch:{
          yhState(){
             this.getList();
          },
        },
        mounted(){
		checkLogin();
          this.getList();
        },
        methods:{
          shiyong(item){
            
            if(item.isUse=='N'){
              this.$router.push({
                path:"/PruductList",
                query:{
                  bizNum:item.bizNum,
                  mtzName:item.mtzName,
                  rzType:""
                },
              })
            }
          },
          getList(){
            let that = this;
            that.loading = true
            let yhState = "all";
            if(that.yhState==2){
              yhState = "Y"
            }
            if(that.yhState==3){
              yhState = "N"
            }
            if(that.yhState==4){
              yhState = "E"
            }
            that.$axios.get(`${that.$base_url}/webStoreUser/queryYhqOfMe/${yhState}`).then(res=>{
              if(res.data.status==200){
                 that.list = res.data.data
              }else{
                that.$message.error(res.data.message);
              }
            }).finally(()=>{
              that.loading = false
            })
          },
        },
    }
</script>

<style lang="scss" scoped>
.shangjias{
  background: url("../../assets/new/juxing02.png") no-repeat!important;
  color: #515151!important;
}
.shangjia{
  width: 300px;
height: 29px;
background: url("../../assets/new/juxing01.png") no-repeat;
background-size: 100%;
border-radius: 15px;
display: flex;
align-items: center;
font-size: 16px;
color: #F6555D;
position: absolute;
z-index: 2;
left: 28px;
bottom: 61px;
span{
  margin-left: 14px;
}
}
.coupon{
    .liji{
        color: #fff;
        font-size: 20px;
        width: 20px;
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 16px;
    }
  padding-top: 30px;
  padding-bottom: 30px;
  width: 856px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  .yishiyong{
    width: 99px;
    height: 99px;
    position: absolute;
    bottom: 20px;
    right: 64px;
    z-index: 1.5;
  }
  .youxiaoqi{
    font-size: 16px;
    position: absolute;
    left: 30px;
    bottom: 20px;
    color: #5D6169;
  }
  .man{
   font-size: 20px;
   position: absolute;
   color: #F6555C;
   right: 73px;
   top: 41px; 
  }
  .price{
    display: flex;
    align-items: center;
    color: #F54851;
    font-size: 48px;
    position: absolute;
    left: 30px;
    top: 20px;
    span{
      font-size: 24px;  
    }
  }
  .neirong{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }
  .coupons{
    width: 391px;
    height: 172px;
    position: relative;
    margin-bottom: 34px;
    cursor: pointer;
    .shiyong{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
  }
  .coupons:nth-child(2n){
    margin-left: auto;
  }
}
</style>