<template>
  <el-dialog
    title=""
    top="2vh"
    :center="true"
    :visible.sync="dialogVisible"
    width="60%"
  >
    <div class="content">
      <!-- <div class="title">
        <img src="@/assets/new/ruzhu.png" alt="" class="banner" />
        <div class="titles"></div>
        <div class="zhaohui">
          <div class="zhaohuis">用户服务协议</div>
          <img src="@/assets/new/jt.png" alt="" class="xiangxia" />
        </div>
      </div> -->
      <div class="xieyi">
        <div
          style="width: 98%; margin: 0 auto; height: 90%; overflow-y: scroll"
        >
          <div class="xieyis">
            <div
              class="xuzhi"
              style="width: 100%; text-align: center; font-weight: bold"
            >
              用户服务协议
            </div>
            <div class="titles" style="padding-top: 60px">
              一、注册协议条款的确认和接受
            </div>
            <div class="contents" style="text-indent: 2em; margin-top: 30px">
              欢迎注册成为正绿航商城用户！您在正绿航商城(以下称“本网站”或“商城”或“平台”)注册过程中，您需要完成我们的注册流程并在注册过程中点击同意按钮完成注册，请您务必仔细阅读、充分理解协议中的内容条款后再点击同意。<br />
              &nbsp;&nbsp;&nbsp;&nbsp;如果您不同意以下协议内容，请您停止注册。您停止注册后仅可以浏览我们的商品信息但无法享受我们的产品或服务。如您按照注册流程完成注册后，即表示您已充分阅读、理解并接受协议的全部内容，并表明您同意我们可以依据协议内容来处理您的个人信息。<br />
              &nbsp;&nbsp;&nbsp;&nbsp;同意接受本协议的全部条款的,
              申请人应当按照页面上的提示完成全部的注册程序,
              并在注册程序过程中点击“同意”按钮,
              否则视为不接受本《协议》全部条款, 申请人应当终止并退出申请。
            </div>
            <div class="titles" style="padding-top: 60px">二、用户账户</div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、经本网站注册系统完成注册程序的用户即为正式用户，您可获得平台账户并成为平台用户。
            </div>
            <div class="contents" style="text-indent: 2em">
              2、您有权使用您设置或确认的平台账户名、手机号码（以下简称“账户名称”）登陆商城。
            </div>
            <div class="contents" style="text-indent: 2em">
              3、由于您的账户关联您的个人信息及平台商业信息，您的账户仅限您本人使用。未经平台同意，您直接或间接授权第三方使用您的账户或获取您账户项下信息的行为无效。如平台判断您的账户的使用可能危及您的账户安全或平台信息安全的，平台将拒绝提供相应服务或终止本协议。
            </div>
            <div class="contents" style="text-indent: 2em">
              4、由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经平台同意，并符合平台规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让，否则平台有权追究您的违约责任，且由此产生的一切责任均由您承担.
            </div>
            <div class="contents" style="text-indent: 2em">
              5、您的账户为您自行设置并由您保管，平台不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录。如您在使用商城服务时忘记密码，您可在登陆页面使用找回密码功能重置密码。
            </div>
            <div class="contents" style="text-indent: 2em">
              <strong>
                6、账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，平台并不承担责任，您应通过司法、行政等途径向侵权行为人追偿。
              </strong>
            </div>
            <div class="titles" style="padding-top: 60px">三、服务内容</div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、您有权在平台上享受店铺管理、商品或服务的销售与推广、商品或服务的购买、交易争议处理等服务。
            </div>
            <div class="contents" style="text-indent: 2em">
              2、通过在平台创建店铺，您可发布商品及服务信息并与其他用户达成交易。
            </div>
            <div class="contents" style="text-indent: 2em">
              3、如您通过下架商品短暂关停您的店铺，您应当对您店铺关停前已达成的交易继续承担发货、退换货及售后处理等交易保障责任。
            </div>
            <div class="contents" style="text-indent: 2em">
              4、当您在平台购买商品或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。
            </div>
            <div class="titles" style="padding-top: 60px">四、使用规则</div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、您应当确保您对您在平台上发布的商品及服务享有相应的权利，您不得在平台上销售违规商品或提供违规服务。
            </div>
            <div class="contents" style="text-indent: 2em">
              2、您应当遵守诚实信用原则，确保您所发布的商品及服务信息真实、与您实际所销售的商品及提供的服务相符，并在交易过程中切实履行您的交易承诺。
            </div>
            <div class="contents" style="text-indent: 2em">
              3、您应当维护平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰平台上进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰平台的正常运作。
            </div>
            <div class="contents" style="text-indent: 2em">
              4、您的购买行为应当基于真实的消费需求，不得存在对商品或服务实施恶意购买、恶意维权等扰乱平台正常交易秩序的行为。基于维护平台交易秩序及交易安全的需要，平台发现上述情形时可主动执行关闭相关交易订单等操作。
            </div>
            <div class="titles" style="padding-top: 60px">五、版权声明</div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              本网站提供的商品服务中包含的任何文本、图片、图形均受版权、商标和/或其它财产所有权法律的保护,
              未经相关权利人同意, 上述资料均不得在任何媒体直接或间接发布,
              或者被用于其他任何商业目的。所有以上资料或资料的任何部分仅可作为私人和非商业用途保存。
            </div>
            <div class="titles" style="padding-top: 60px">六、隐私保护</div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、本网站保证不对外公开或向第三方提供用户的注册资料,
              但下列情况除外:
              <br />
              （1）事先获得用户的书面明确授权;<br />
              （2）根据有关的法律法规要求;<br />
              （3）按照相关政府主管部门的要求;<br />
              （4）为维护社会公众的利益;<br />
              （5）为维护本网站的合法权益;
            </div>
            <div class="titles" style="padding-top: 60px">
              七、协议的用途、更新和效力
            </div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、本协议之服务条款用以规范用户使用平台提供的服务。
            </div>
            <div class="contents" style="text-indent: 2em">
              2、平台可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修订本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将在您登陆本网站时以弹窗方式显示出来并提示您。
            </div>
            <div class="contents" style="text-indent: 2em">
              3、如您不同意变更事项，您应当于变更事项确定的生效之日起停止使用平台服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用平台服务，则视为您同意已生效的变更事项。
            </div>
            <div class="titles" style="padding-top: 60px">
              八、法律适用、管辖与其他
            </div>
            <div class="contents" style="margin-top: 30px; text-indent: 2em">
              1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及行业惯例。
            </div>
            <div class="contents" style="text-indent: 2em">
              2、您因使用平台服务所产生及与平台服务有关的争议，由平台与您协商解决。协商不成时，任何一方均可向被告所在地人民法院提起诉讼。
            </div>
            <div class="contents" style="text-indent: 2em">
              3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
            </div>
          </div>
        </div>

        <div class="button" style="height: 35%">
          <div class="button02" @click="dialogVisible = false">我已知晓</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      path: "Login",
      dialogVisible: false,
    };
  },
  mounted() {

  },
  methods: {
    routers() {
      window.close();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
/deep/ .el-dialog__header {
  // display: none;
}
.button {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  .button01 {
    width: 180px;
    height: 60px;
    background: #f3f4f7;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
  }
  .button02 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 254px;
    height: 60px;
    background: #ff3d09;
    border-radius: 30px;
    font-size: 24px;
    color: #fff;
    margin-left: 20px;
  }
}
.xieyi {
  .titles {
    color: #515151;
    font-size: 24px;
  }
  .contents {
    color: #515151;
    font-size: 24px;
    line-height: 38px;
  }
  width: 100%;
  margin: 0 auto;
  height: 70vh;
  background: #fff;
  border-radius: 20px;
  // margin-top: -113px;
  // position: relative;
  // z-index: 999;
  padding-top: 50px;
  padding-bottom: 50px;
  .xieyis {
    width: 100%;
    margin: 0 auto;
    .xuzhi {
      font-size: 24px;
      line-height: 42px;
      padding-bottom: 40px;
      border-bottom: 1px solid #ebedf3;
    }
  }
}
.success {
  width: 360px;
  height: 60px;
  background: #ff3d09;
  box-shadow: 0px 3px 24px 0px rgba(255, 61, 9, 0.42);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  margin-top: 35px;
  cursor: pointer;
  margin-left: 125px;
}
.code {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 25px;
  .codes {
    width: 1px;
    height: 17px;
    background: #d5d6d8;
  }
  .btnText {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ff3d09;
    margin-left: 14px;
  }
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    .inouts {
      font-size: 16px;
      width: 50%;
      margin-left: 44px;
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
    .zhaohui {
      width: 18px;
      height: 24px;
      margin-left: 25px;
    }
    width: 360px;
    height: 56px;
    background: #fff;
    border: 1px solid #d6d8de;
    box-shadow: 0px 3px 5px 0px #edeef0;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .form_title {
    width: 120px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    text-align: right;
  }
}
.neirong {
  width: 1100px;
  height: 595px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.biaoshi {
  width: 1100px;
  height: 103px;
  background: #fff;
  left: 50%;
  margin-left: -550px;
  position: absolute;
  bottom: 0px;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .dangqian {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 10px;
  }
  .biaoshis {
    width: 1161px;
    margin: 0 auto;
    height: 89px;
    border-bottom: 1px solid #edeef6;
    font-size: 24px;
    color: #515151;
  }
}
.zhaohui {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .zhaohuis {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    top: 106px;
  }
  .xiangxia {
    width: 47px;
    height: 41px;
    position: absolute;
    top: 195px;
  }
}
.content {
  width: 100%;
  padding-bottom: 30px;
  .title {
    width: 100%;
    height: 418px;
    background: #fff;
    position: relative;
    .titles {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.32;
      position: absolute;
      z-index: 2;
    }
    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
}
</style>