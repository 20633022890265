<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="待发货" name="first">
        <OrderSearch status="C" v-if="activeName == 'first'"></OrderSearch>
      </el-tab-pane>
      <el-tab-pane label="待收货" name="second">
        <OrderSearch status="Y" v-if="activeName == 'second'"></OrderSearch>
      </el-tab-pane>
      <el-tab-pane label="待自提" name="third">
        <OrderSearch status="ZT" v-if="activeName == 'third'"></OrderSearch>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="fourth">
        <OrderSearch status="R" v-if="activeName == 'fourth'"></OrderSearch>
      </el-tab-pane>
    </el-tabs>
  </div><!-- root div -->
</template>
<script>

import OrderSearch from "@/components/user/OrderSearch";
import {checkLogin} from "@/common/tools";

export default {
  name: "OrderSearchTab",
  components: {
    OrderSearch
  },
  data(){
    return {
      activeName: 'first',
    };
  },//data
  methods:{
    handleClick(tab) {
      this.activeName = tab.name
    }
  },//methods
  created() {
    const that = this;

    const url = that.$route.fullPath;
    checkLogin().then(res=>{
      if (!res.flag) {
        // 请先登录
        that.$store.commit('change_url_before_login', url);
        that.$message.warning("请先登录!");
        setTimeout(()=>{
          that.$router.push('/login');
        }, 1500);
      }
    }).catch(()=>{});
  },//created end
}
</script>
