<template>
  <div class="header">
     <div class="headers">
       <div class="header01">
         <img src="../assets/new/dingwei.png" alt="" class="dingwei" />
         <!-- {{city || "城市选择"}} -->
         <el-select v-model="citys" @change="cityChange" collapse-tags placeholder="城市选择" style="width: 300px;">
          <div style="width: 100%;height: 40px;background: #fff;position: absolute;background: #fff;z-index: 9999;top: 0px;">
            <div class="el-input" style="width:90%;margin-left:5%;position: absolute;background: #fff;z-index: 9999;top: 10px;">
            <input type="text" placeholder="搜索城市名称" class="el-input__inner" v-model="citys_new">
          </div>
          </div>
            <div style="width: 100%;height: 50px;"></div>
            <el-option v-if="cityArr.length === 0" :label="''" :value="''"></el-option>
            <el-option
              v-for="item in cityArr"
              :key="item.city_name"
              :label="item.city_name"
              :value="item.city_name">
            </el-option>
          </el-select>
       </div>
       <!-- <div style="color: #515151;width: 812px;">
        推荐使用chorom、360、qq浏览器
       </div> -->
       <div class="header02">
           <div v-if="isLogin" class="huanying" @click="userinfo_url">欢迎您,{{user_name}}</div>
           <router-link class="a-link" to="/login"  v-if="!isLogin">
            <div class="ml2">登录</div>
           </router-link>
           <router-link class="a-link" to="/register"  v-if="!isLogin"> 
            <div class="ml2">注册</div>
           </router-link>
            <router-link class="a-link" :to="shoppingCart_url()" v-if="isLogin">
              <div class="ml2 gouwu">
              <img src="../assets/new/gouwuche.png" alt="" />
             购物车
              </div>
            </router-link>
            <!-- <router-link class="a-link" to="/Enter"> -->
              <div class="ml2" style="color: #515151;cursor: pointer;"  @click="ruzhu">商家入驻</div>
            <!-- </router-link> -->
            <div class="ml2 co" style="color: #515151;"  v-if="isLogin" @click="shangjia">
              <!-- <a href="http://139.9.56.204:8089/#/login?redirect=%2F/" target="_black"> -->
                商家中心
              <!-- </a> -->
            </div>
            <!-- <div class="ml2 co" style="color: #515151;" v-if="isLogin">
              <a href="http://139.9.56.204:8089/#/login?redirect=%2F/" target="_black">
                商家中心
              </a>
            </div> -->
           <div class="ml2 huanying" v-if="isLogin" @click="logout">退出登录</div>
       </div>
     </div>
  </div>
</template>

<script>
  
  // import axios from "axios";
  // import {base_url} from "@/common/constant";
  import { checkLogin } from "@/common/tools";
  // import { getApp } from "@/common/global";
  import { common_logout } from "@/common/tools";
  import cityJson from "@/utils/city.json"
  export default {
    name: 'Header',
    data() {
      return {
        cityAll:cityJson,
        cityArr:cityJson,
        citys:"深圳市",
        citys_new:"",
      };
    },//data end
    mounted(){
       
    },
    computed: {
      is_header_show(){
        return this.$store.state.is_header_show;
      },
      isLogin(){
        return this.$store.state.is_login;
      },
      user_name(){
        const login_user = this.$store.state.login_user;
        if (login_user && login_user.username) {
          return login_user.username;
        } else {
          return "";
        }
      },
      city(){
        this.citys = this.$store.state.city
        return this.$store.state.city
      },
    },
    watch:{
      citys_new(e){
        if(e==''){
          this.cityArr = cityJson
        }else{
          this.cityArr = cityJson.filter(item=>{
            return item.city_name.includes(e)
        });
        }
      },
    },
    methods: {
      dropDownSearch(){
        let _this = this;
        _this.cityArr = _this.cityAll.filter(item=>{
          _this.filterSearch(item)
        });
      },
      filterSearch(item){
        return item.city_name.includes(this.citys_new);
      },
      cityChange(e){
        this.$store.commit('change_city', e);
      },
      //商家后台
      shangjia(){
        let that = this;
        checkLogin()
        .then((res) => {
          if (res.flag) {
            window.open("https://byjwx.com/#/login?redirect=%2F/", '_blank');
          } else {
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
      },
      ruzhu(){
        let that = this;
        checkLogin()
        .then((res) => {
          if (res.flag) {
           that.$router.push({
            path:"/Enter"
           })
          } else {
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
      },
      shoppingCart_url() {
        return `/shoppingCart?v=${Math.random()}`;
      },
  
      home_url(){
        return `/?v=${Math.random()}`;
      },
  
      userinfo_url(){
        this.$router.push({path:`/user_center/userinfo?v=${Math.random()}`})
      },
  
      logout(){
        common_logout();
      },
  
    },//methods end
    created() {
    }, //created end
  }
  </script>

<style lang="scss" scoped>
.el-scrollbar {
  display: block !important;
}
/deep/ .el-input__inner{
  background: #F3F4F7!important;
  border: none!important;
  color: #515151!important;
  font-size: 16px!important;
  padding: 0px!important;
}
/deep/ .el-input__suffix{
  display: none!important;
}


.huanying{
  cursor: pointer;
}
.huanying:hover{
  color: #FF3D09;
}
.co{
  color: #FF3D09;
  cursor: pointer;
}
.gouwu{
  display: flex;
  align-items: center;
  img{
    width: 16rpx;
    height: 16rpx;
    margin-right: 5px;
  }
}
.ml2{
  margin-left: 20px;
}
.header02{
  margin-left: auto;
  display: flex;
  align-items: center;

}
.header{
  .headers{
    width: 1250px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    .header01{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #515151;
      cursor: pointer;
      .dingwei{
        width: 16px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
  background: #F3F4F7;
}
</style>