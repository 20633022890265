<template>
  <div class="root">
    <!--    <img src="@/assets/banner.jpg" alt="" class="banner">-->
    <div
      style="
        width: 1200px;
        height: 350px;
        display: block;
        margin: 0 auto;
        position: relative;
      "
    >
      <el-image
        src="banner.jpg"
        fit="fill"
        style="display: block; width: 100%; height: 100%"
      ></el-image>
      <div class="zlh_banner_text">智慧批发 + 智慧新零售</div>
    </div>

    <div class="regist">
      <div class="regist_center">
        <div class="regist_top">
          <div class="left fl">商家入驻</div>
          <!--          <div class="right fr"><router-link to="/">智慧采购通</router-link></div>-->
          <div class="clear"></div>
          <div class="xian center"></div>
        </div>
        <div class="regist_main center">
          <div class="username">
            联&nbsp;&nbsp;系&nbsp;&nbsp;人:&nbsp;&nbsp;
            <input
              class="shurukuang"
              type="text"
              v-model="entity.contactName"
              placeholder="请输入你的姓名"
            />
          </div>
          <div class="username">
            手&nbsp;&nbsp;机&nbsp;&nbsp;号:&nbsp;&nbsp;
            <input
              class="shurukuang"
              type="text"
              v-model="entity.contactMobile"
              placeholder="请输入你的手机号"
            />
            <el-button
              type="info"
              class="btn-send"
              :disabled="disabled"
              @click="sendVerifyCode"
            >
              {{ btnText }}
            </el-button>
          </div>
          <div class="username">
            <div class="left fl">
              验&nbsp;&nbsp;证&nbsp;&nbsp;码:&nbsp;&nbsp;
              <input
                class="yanzhengma"
                type="text"
                v-model="vCode"
                placeholder="请输入验证码"
              />
            </div>
            <!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
          <div class="username">
            <div class="left fl">
              店铺名称:&nbsp;&nbsp;
              <input
                class="shurukuang"
                type="text"
                v-model="entity.shopName"
                placeholder="请输入店铺名称"
              />
            </div>
            <!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
          <div class="username">
            <div class="left fl">
              所在地区:&nbsp;&nbsp;
              <el-cascader
                v-model="birth_place"
                style="width: 243px;font-size: 16px;color: #000;"
                :options="optionsnative_place"
              />
            </div>
            <!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
          <div class="username">
            <div class="left fl">
              经营地址:&nbsp;&nbsp;
              <input
                class="shurukuang"
                type="text"
                v-model="entity.addr"
                placeholder="请输入经营地址"
              />
            </div>
            <!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
          <div class="username" v-loading="loadings">
            <div class="left fl" style="display: flex;">
              营业执照:&nbsp;&nbsp;
              <el-upload
                class="upload-demo"
                drag
                action="#"
                :show-file-list="false"
                :multiple="false"
                :http-request="uploadServerLog"
              >
                <i class="el-icon-upload" v-if="!entity.bizLicenseUrl"></i>
                <div class="el-upload__text" v-if="!entity.bizLicenseUrl">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <img :src="$translateProdUrl(entity.bizLicenseUrl)" alt="" style="width:100%;height: 100%;" v-if="entity.bizLicenseUrl">
              </el-upload>
            </div>
            <!--            <div class="right fl"><img src="@/assets/yanzhengma.jpg"></div>-->
            <div class="clear"></div>
          </div>
        </div>
        <div class="regist_submit"  v-loading="loading" style="margin-bottom: 30px">
          <input
            class="submit"
            type="button"
            value="申请入驻"
            @click="handleApply"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import home from "@/json/home.json";

import axios from "axios";
import { base_url } from "@/common/constant";
import { checkLogin } from "@/common/tools";
import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  codeToText,
} from "element-china-area-data";
// 1、provinceAndCityData省市二级联动数据,汉字+code
// 2、regionData省市区三级联动数据
// 3、pcTextArr省市联动数据，纯汉字
// 4、pcaTextArr省市区联动数据，纯汉字
// 5、codeToText是个大对象，属性是区域码，属性值是汉字 用法例如：codeToText[‘110000’]输出北京市
export default {
  name: "MerchantSettleIn",
  data() {
    return {
      optionsnative_place: pcaTextArr,
      btnText: "发送验证码",
      disabled: false, // 发送验证码按钮的启用和禁用
      entity: {
        contactName: "", // 联系人
        contactMobile: "", // 联系电话
        shopName: "", //店铺名称
        province: "", //省
        city: "", //市
        area: "", //区
        addr: "", //经营地址
        bizLicenseUrl: "", //营业执照URL
      }, // 意向客户
      vCode: "", // 验证码
      btnApplyDisabled: false,
      birth_place: "",
      loading:false,
      loadings:false,
    };
  }, //data end
  methods: {
    uploadServerLog(param){
       let that = this;
       const file = param.file;
       const form = new FormData();
      // 文件对象
      form.append('file', file);
      form.append("saveType","biz_img");
      that.loadings = true
      that.$axios.post(`${that.$base_url}/file/upload`,form).then(res=>{
          if (res.data.status == 200) {
               that.entity.bizLicenseUrl = res.data.data
            } else {
              that.$message.error(res.data.message);
            }
      }).finally(()=>{
        that.loadings = false
      })
    },
    before_submit_apply() {
      const that = this;
      if (that.entity.contactName == "") {
        that.$message.error("请输入联系人");
        return false;
      }
      if (that.entity.contactMobile == "") {
        that.$message.error("请输入联系电话");
        return false;
      }
      if (that.vCode == "") {
        that.$message.error("请输入验证码");
        return false;
      }
      if (that.entity.shopName == "") {
        that.$message.error("请输入店铺名称");
        return false;
      }
      if (that.birth_place == "") {
        that.$message.error("请选择所在地区");
        return false;
      }
      if (that.entity.addr == "") {
        that.$message.error("请输入经营地址");
        return false;
      }
      if (that.entity.bizLicenseUrl=='') {
        that.$message.error("请上传营业执照");
        return false;
      }
      return true;
    },

    handleApply() {
      const that = this;
      if (that.before_submit_apply()) {
        if (!that.btnApplyDisabled) {
          that.btnApplyDisabled = true;
          that.remote_applyRz().then((res) => {
            if (res.flag) {
              that.$message.success("已提交申请");
              that.btnApplyDisabled = false;
              that.$router.go(0)
            } else {
              let errMsg = "系统升级中，请稍候";
              if (res.err.message && res.err.message.length < 30) {
                errMsg = res.err.message;
              }
              that.$message.error(errMsg);
              that.btnApplyDisabled = false;
            }
          });
        }
      }
    },

    countDown(time) {
      // 倒计时
      let that = this;
      const interval = setInterval(function () {
        if (time > 0) {
          that.disabled = true; //禁用点击
          --time;
          that.btnText = time + "s后重试";
        } else {
          that.disabled = false; //开启可点击
          that.btnText = "发送验证码";
          clearInterval(interval);
        }
      }, 1000);
    },

    sendVerifyCode() {
      // 获取验证码
      const that = this;
      if (!that.entity.contactMobile) {
        that.$message.error("请输入手机号");
      } else {
        if (!that.disabled) {
          that.disabled = true;
          axios
            .get(
              `${base_url}/CustomerVerifyCode/send/${that.entity.contactMobile}`
            )
            .then((res) => {
              res = res.data;
              if (res.status == 200) {
                that.$message.success("发送成功");
                that.countDown(60);
              } else {
                let errMsg = "系统升级中，请稍候";
                if (res.message && res.message.length < 30) {
                  errMsg = res.message;
                }
                that.$message.error(errMsg);
                console.error(res);
                that.disabled = false;
              }
            })
            .catch((res) => {
              console.error(res);
              that.disabled = false;
            });
        }
      }
    },

    // /webStoreUser/applyRz/{vCode}
    remote_applyRz() {
      // 申请入驻
      const that = this;
      return new Promise((resolve) => {
        let a = { ...that.entity };
        a["province"] = that.birth_place[0]; //所在省份
        a["city"] = that.birth_place[1]; //所在城市
        a["area"] = that.birth_place[2]; //所在区
        axios
          .post(`${base_url}/webStoreUser/applyRz/${that.vCode}/`, a)
          .then((res) => {
            res = res.data;
            if (res.status == 200) {
              resolve({ flag: true, data: res.data });
            } else {
              resolve({ flag: false, err: res });
            }
          })
          .catch((res) => {
            console.error(res);
            resolve({ flag: false, err: res });
          });
      });
    },
  }, //methods end
  created() {
    const that = this;
    const url = that.$route.fullPath;
    checkLogin()
      .then((res) => {
        if (!res.flag) {
          // 请先登录
          that.$store.commit("change_url_before_login", url);
          that.$message.warning("请先登录!");
          setTimeout(() => {
            that.$router.push("/login");
          }, 1500);
        }
      })
      .catch(() => {});
  }, //created end
};
</script>
<style lang="scss" scoped>
// /deep/ .el-upload-dragger{
//   height: 252px;
// }
/deep/ .el-cascader .el-input .el-input__inner{
  color: #000!important;
  font-size: 16px!important;
}
.root {
  display: block;
  //margin: 30px 0 0 0;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  width: 100%;
}

.zlh_banner_text {
  position: absolute;
  left: 100px;
  bottom: 60px;
  font-size: 35px;
  font-family: 楷体, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  /*text-stroke: .1px white;*/
  /*-webkit-text-stroke: .1px white;*/
}

.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  clear: both;
}
.center {
  margin: 0 auto;
}
.border {
  border: 1px solid red;
}

/*用户注册*/
.regist {
  width: 1200px;
  margin: 5px auto;
  background: #fff;
  color: #000;
  border-radius: 6px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.regist_center {
  width: 760px;
  margin: 10px auto;
}
.regist .regist_top {
  margin: 10px 0;
}
.regist .regist_top .left {
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 20px;
}
.regist .regist_top .right {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}
.regist .regist_top .right a {
  color: #ff6700;
  font-weight: bold;
}
.regist .regist_top .right a:hover {
  color: orange;
}
.xian {
  height: 2px;
  background: #ff6700;
  margin: 8px auto;
}

.regist_main {
  padding: 10px 0;
  padding-left: 45px;
}
.regist_main .username {
  font: 16px Times New Roman;
  height: 40px;
  line-height: 40px;
  margin: 20px 0;
}
.regist_main .username .shurukuang {
  width: 220px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.regist_main .username .yanzhengma {
  width: 100px;
  height: 30px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}
.regist_main .username .shurukuang:focus {
  border: 1px solid blue;
  background: #f0ffff;
}
.regist_main .username .yanzhengma:focus {
  border: 1px solid blue;
  background: #f0ffff;
}
.regist_main .username .right {
  margin-left: 20px;
}

.regist_main .username span {
  display: inline-block;
  margin-left: 20px;
  font-size: 12px;
  color: #ccc;
  font-weight: bold;
}
.regist_main .username span:hover {
  color: #ff6700;
}

.regist .regist_center .regist_submit {
  margin: 20px auto;
}
.regist .regist_center .submit {
  border: none;
  width: 440px;
  height: 45px;
  margin-left: 45px;
  background: #ff6700;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 8px;
  cursor: pointer;
}
.regist .regist_center .submit:hover {
  border: 1px solid #ccc;
}

.btn-send {
  display: inline-block;
  margin-left: 10px;
  width: 140px;
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}
</style>
