<template>
  <div class="swiper">
    <div class="swiper01">
      <div class="swiper01s" v-for="(item, i) in cateList" :key="i">
        <router-link :to="'/CateList?bigSortCode=' + item.bvalue" target="_blank">
          <img :src="getImageUrl(item.bvalue)" alt="" class="cateicon" v-if="getImageUrl(item.bvalue)" />
        {{ item.bname }}
        <img src="@/assets/nav/right.png" alt="" class="right" />
        </router-link>
      </div>
    </div>
    <div class="swiper02">
      <el-carousel
        :interval="3000"
        arrow="always"
        style="width: 100%; height: 100%"
      >
        <el-carousel-item style="height: 100%">
          <img src="@/assets/nav/swiper.png" @click="routers('/ShopList')" alt="" class="swiperimg" />
        </el-carousel-item>
        <el-carousel-item style="height: 100%">
          <img src="@/assets/nav/swiper02.jpg" @click="routers('/ShopList')" alt="" class="swiperimg" />
        </el-carousel-item>
        <el-carousel-item style="height: 100%">
          <img src="@/assets/nav/swiper03.jpg" @click="routers('/ShopList')" alt="" class="swiperimg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="swiper03">
      <div class="swiper03s">
        <div class="noLogin">
          <img src="@/assets/new/nologin.png" alt="" />
        </div>
        <div class="huanying">HI，欢迎来到{{title}}</div>
        <div class="huanying" v-if="isLogin">{{ user_name }}</div>
        <div class="login" v-if="!isLogin">
          <router-link class="a-link" to="/login"
            ><div class="logins">登录</div></router-link
          >

          <router-link class="a-link" to="/register">
            <div class="logins zhuce">注册</div>
          </router-link>
        </div>
      </div>
      <div class="swiper03s">
        <div class="zheng mlnone bomnone">
            <img src="@/assets/new/cate01.png" alt="">
            正品货源
        </div>
        <div class="zheng bomnone brnone">
            <img src="@/assets/new/cate02.png" alt="">
            品类齐全
        </div>
        <div class="zheng mlnone brnone">
            <img src="@/assets/new/cate03.png" alt="">
            阳光采购
        </div>
        <div class="zheng brnone">
            <img src="@/assets/new/cate04.png" alt="">
            售后无忧
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cateList: [], //分类列表
	  title:process.env.VUE_APP_TITLE
    };
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getCateList();
  },
  methods: {
    cateClick(item){
      this.$router.push({
        path:"/CateList",
        query:{
          bigSortCode:item.bvalue
        }
      })
    },
    //获取分类列表
    async getCateList() {
      let that = this;
      await this.$axios
        .get(`${that.$base_url}/webStoreOfFirstPage/getSortOfPT`)
        .then((res) => {
          if (res.data.status == 200) {
            that.cateList = res.data.data;
          } else {
            that.$message.error(res.data.message);
          }
        });
    },
    getImageUrl(filename){
        return require(`@/assets/nav/${filename}.png`);
    },
    routers(path){
     this.$router.push({
      path:path
     })
    },
  },
};
</script>

<style lang="scss" scoped>
.brnone{
    border-right: none!important;
}
.bomnone{
    border-bottom: none!important;
}
.mlnone{
    border-left: none!important;
}
.zheng{
    width: calc(50% - 1px);
    height: 50%;
    border: 1px solid #E9F2F9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #515151;
    font-size: 16px;
    img{
        width: 29px;
        height: 31px;
        margin-bottom: 9px;
    }
}
.login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .zhuce {
    background: #fff !important;
    border: 1px solid #ff4817 !important;
    color: #ff4817 !important;
    margin-left: 10px !important;
  }
  .logins {
    width: 105px;
    height: 38px;
    background: #ff4817;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.huanying {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #000000;
  padding-top: 16px;
  padding-bottom: 16px;
}
/deep/ .el-carousel__container {
  height: 100% !important;
}
/deep/ .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  // background: #000000!important;
  // opacity: 0.5!important;
  border-radius: 5px !important;
}
/deep/ .is-active .el-carousel__button {
  width: 34px !important;
  height: 10px !important;
  background: #fff !important;
  border-radius: 5px !important;
}
.swiperimg {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.swiper {
  .swiper03 {
    width: 250px;
    height: 100%;
    background: #fff;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    .swiper03s {
      width: 100%;
      height: 50%;
      .noLogin {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: #ececec;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    .swiper03s:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .swiper03s:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .swiper01 {
    width: 190px;
    height: 100%;
    background: #fff;
    overflow-y: scroll;
    .swiper01s:hover {
      font-weight: bold;
    }
    .swiper01s {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 13px;
      padding-bottom: 13px;
      color: #515151;
      cursor: pointer;
      .cateicon {
        width: 25px;
        height: 25px;
        margin-left: 20px;
        margin-right: 10px;
      }
      .right {
        margin-left: auto;
        margin-right: 22px;
        width: 6px;
        height: 11px;
      }
    }
  }
  .swiper02 {
    width: 780px;
    height: 100%;
    margin-left: auto;
    background: #fff;
  }
  width: 100%;
  height: 390px;
  display: flex;
}
</style>