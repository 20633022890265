
<template>
  <div>

    <el-dialog title="选择地址" :visible.sync="visible_for_payment" :append-to-body="true"
               :close-on-click-modal="false" width="50%" >

      <div style="margin-top: -30px;">
        <div style="display: flex;justify-content: flex-end;">
          <span class="link-a-style" style="margin-bottom: 5px;font-weight: bold;" @click="signAddNewAddress = !signAddNewAddress">新增地址</span>
        </div>
        <div v-for="(item, index) of addressList" :key="index" style="border-bottom: 1px solid lightgrey;margin: 10px 0;padding-bottom: 5px;">
          <!--<el-radio v-model="selectedAddressId" :label="item.id">
            <span style="display: inline-block;max-width: 70%;font-size: 16px;word-wrap: break-word;">{{addressDetail(item)}}</span>
          </el-radio>-->
          <div style="display: inline-block;max-width: 80%;font-size: 16px;">
            <span class="address-text" @click="clickSelectDefault(index)">{{addressDetail(item)}}</span>
          </div>
          <span v-if="item.isDefault && item.isDefault == 'Y'" class="span-default-address">默认地址</span>
          <span v-else class="link-a-style" style="float: right;font-size: 12px;color: blue;" @click="clickSelectDefault(index)">设为默认地址</span>
        </div>
      </div>

      <div class="address-line" v-if="signAddNewAddress">
        <div class="line">
          <span class="line-title">收货人:</span>
          <el-input class="line-receiver" v-model="addressInfo.receiver" placeholder="请输入姓名"></el-input>
        </div>
        <div class="line">
          <span class="line-title">所在地区:</span>
          <el-cascader v-model="selectedAddress" :options="addressArrayData" :clearable="true"
                       :props="{value: 'name', label: 'name', checkStrictly: true}"
                       style="width: 380px;"
                       @change="handleAddressChange"></el-cascader>
        </div>
        <div class="line">
          <span class="line-title">地址:</span>
          <el-input v-model="addressInfo.address"
                    placeholder="请输入详细地址" style="width: 420px;"></el-input>
        </div>
        <div class="line">
          <span class="line-title">手机:</span>
          <el-input v-model="addressInfo.phone"
                    placeholder="请输入手机号"  style="width: 200px;"></el-input>
        </div>
        <div class="line" style="display: flex;justify-content: flex-end;align-items: center;">
          <span v-if="errMsgTip != ''" style="color: red;margin-right: 15px;">{{errMsgTip}}</span>
          <el-button type="default" @click="clickAddAddress">确 定</el-button>
        </div>
      </div>
      <!--<span slot="footer" class="dialog-footer">
        <el-button @click="visible_for_payment = false">取 消</el-button>
        <el-button type="primary" class="btn-confirm" @click="handleSelect">确 定</el-button>
      </span>-->
    </el-dialog>

  </div><!-- root div-->
</template>
<script>

import full_address from "@/json/full_address.json";

import { remote_set_default_address,
  remote_listAddressByUid,
  remote_add_address } from "@/components/dialog/js/scriptForAddress";

export default {
  name: 'SelectAddressDialog',
  props: ['seed'],
  data() {
    return {
      signAddNewAddress: false, // 新增地址标志
      selectedAddressId: '', //选中的地址信息id
      selectedAddressInfo: '', // 选中的地址信息
      errMsgTip: '', // 错误提示
      addressList: '', //地址列表
      addressInfo: {
        uid: '', // 用户id
        receiver: '', // 收货人
        province: '',
        city: '',
        area: '',
        address: '',
        phone: '',
      },
      addressArrayData: [], // 省市区集合
      selectedAddress: [], // 选中的省市区
      selDisabled: false, // 禁用选择，默认false
      defaultIdx: '', // 默认的地址在'addressList'中的index
    };
  },//data end
  computed: {
    visible_for_payment: {
      get(){
        return this.$store.state.visible_for_payment;
      },
      set(val){
        this.$store.commit('change_visible_for_payment', val);
      }
    },
  },//computed end
  watch: {
    visible_for_payment(val) {
      const that = this;
      if (val) {
        that.reusing_set_addressList();
      }
    },
    seed(){
      const that = this;
      //
      const login_user = that.$store.state.login_user;
      that.addressInfo.uid = login_user.id;
    },
  },//watch end
  methods: {

    clickSelectDefault(index){
      const that = this;
      if (index == that.defaultIdx) {
        return;
      }
      if (!that.selDisabled) {
        that.selDisabled = true;
        const item = that.addressList[index];
        const id = item.id;
        that.localChangeDefault(index);
        const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        remote_set_default_address(id).then(res=>{
          if (res.flag) {
            that.defaultIdx = index;
            that.selDisabled = false;
            loading.close();
            that.$emit('custom_evt', item);
            that.$store.commit('change_visible_for_payment', false);
          } else {
            that.localChangeDefault(that.defaultIdx);
            that.selDisabled = false;
            loading.close();
          }
        }).catch(err=>{
          console.error(err);
          that.localChangeDefault(that.defaultIdx);
          that.selDisabled = false;
          loading.close();
        });
      }
    },

    localChangeDefault(index) {
      const that = this;
      let idx = 0;
      for (let item of that.addressList){
        item.isDefault = 'N';
        that.$set(that.addressList, idx, item);
        idx++;
      }
      const item = that.addressList[index];
      item.isDefault = 'Y';
      that.$set(that.addressList, index, item);
    },

    handleSelect(){
 
    },

    reusing_set_addressList() {
      const that = this;
      remote_listAddressByUid().then(res=>{
        if (res.flag) {
          that.addressList = res.data;
          if (that.addressList && that.addressList.length > 0) {
            that.defaultIdx = that.addressList.findIndex(item => item.isDefault == 'Y');
          }
        } else {
          let errMsg = "系统升级中，请稍候";
          if (res.err.message && res.err.message.length < 30) {
            errMsg = res.err.message;
          }
          that.$message.error(errMsg);
        }
      }).catch(err=>{console.error(err)});
    },

    addressDetail(item){
      if (item) {
        let addressDetail = "";
        let prefix = "";
        if (item.province == item.city) {
          prefix = item.province;
        } else {
          prefix = `${item.province} ${item.city}`;
        }
        addressDetail += `${item.receiver}  ${prefix} ${item.area} ${item.address} ${item.phone}`;
        return addressDetail;
      } else {
        return "";
      }
    },

    handleAddressChange(selectedVal){
      if (selectedVal.length == 1) {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[0];
        this.addressInfo.area = "";
      } else if (selectedVal.length == 2) {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[0];
        this.addressInfo.area = selectedVal[1];
      } else {
        this.addressInfo.province = selectedVal[0];
        this.addressInfo.city = selectedVal[1];
        this.addressInfo.area = selectedVal[2];
      }
    },

    clickAddAddress(){
      const that = this;
      // that.visible_for_payment = false;

      if (that.check_before_submit()) {
        remote_add_address(that.addressInfo).then(res=>{
          if (res.flag) {
            // 添加成功，要刷新dialog
            that.reusing_set_addressList();
            that.signAddNewAddress = false;
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.err.message && res.err.message.length < 30) {
              errMsg = res.err.message;
            }
            that.errMsgTip = errMsg;
          }
        }).catch(err=>{console.error(err)});
      }
    },

    check_before_submit() {
      const that = this;
      const arr = [
        {field: 'receiver', msg: '请输入收货人姓名'}, {field: 'province', msg: '请选择所在地区'},
        {field: 'city', msg: '请选择所在地区'}, {field: 'area', msg: '请选择所在地区'},
        {field: 'address', msg: '请输入详细地址'}, {field: 'phone', msg: '请输入手机号码'},
      ];
      for (let item of arr) {
        if (that.addressInfo[item.field] == '') {
          that.errMsgTip = item.msg;
          return false;
        }
      }
      that.errMsgTip = "";
      return true;
    },


  },//methods end
  created() {
    const that = this;
    that.addressArrayData = full_address.data;
  },//created end
}
</script>
<style lang="scss" scoped>
.address-line {
  border: 2px solid #bbb8b8;
  margin: 10px 0;
  padding: 10px;
  .username {
    margin-right: 5px;
  }
  .province {
  }
  .line {
    margin: 5px 0;
  }
  .line-title {
    display: inline-block;
    width: 80px;
    text-align: right;
    color: #242222;
    font-weight: bold;
    margin-right: 10px;
  }
  .line-input {
    display: inline-block;
  }
  .line-receiver {
    display: inline-block;
    max-width: 150px;
  }
}

.btn-confirm {
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}

.btn-confirm2 {
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}

.link-a-style, .address-text{
  cursor: pointer;
  &:hover {
    color: orangered;
  }
}

.span-default-address {
  font-size: 10px;
  float: right;
  padding: 5px 10px;
  background-color: orangered;
  color: white;
  //border-radius: 10px;
}

</style>
