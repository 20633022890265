<template>
  <div class="list" v-infinite-scroll="getList" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="10">
    <div class="biaoshis">
      <img
        src="@/assets/new/shouye.png"
        @click="$router.push({ path: '/' })"
        class="shouye"
        alt=""
      />
      <div class="dangqian">当前位置：批发部</div>
    </div>

    <div
      class="lists"
      v-for="(item, i) in list"
      :key="i"
      style="cursor: pointer"
      @click="routers(item)"
    >
      <div class="listss" style="display: flex; align-items: center">
        <div>
          <div style="display: flex; align-items: center">
            <div class="list01">
              <div class="img">
                <img :src="$translateProdUrl(item.mtzUrl)" alt="" />
              </div>
            </div>

            <div class="title">
              {{ item.mtzName || "--" }}
              <div style="display: flex; align-items: center">
                <div class="titles">
                  <img src="@/assets/new/shouding.png" alt="" />
                  满{{ item.pfFullPrice }}元起送
                </div>
                <div class="buhuo">
                  补货金额:<span>￥{{ item.replenish }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="address">
            <img src="@/assets/new/dingwei.png" alt="" />
            {{ item.bizAddr }}
          </div>
        </div>

        <!--右侧商品-->
        <div class="shop">
          <div class="shop01">
            <div class="shop01s">
              <div class="shopimg">
                <img :src="$translateProdUrl(item.firstProductUrl)" alt="" />
              </div>
              <div class="shoptitle">
                {{ item.firstProductUrl_name }}
              </div>
              <div class="shopprice">￥{{ item.firstProductUrl_price }}</div>
            </div>
          </div>
          <div class="shop01">
            <div class="shop01s">
              <div class="shopimg">
                <img :src="$translateProdUrl(item.secondProductUrl)" alt="" />
              </div>
              <div class="shoptitle">
                {{ item.secondProductUrl_name }}
              </div>
              <div class="shopprice">￥{{ item.secondProductUrl_price }}</div>
            </div>
          </div>
          <div class="shop01">
            <div class="shop01s">
              <div class="shopimg">
                <img :src="$translateProdUrl(item.thirdProductUrl)" alt="" />
              </div>
              <div class="shoptitle">
                {{ item.thirdProductUrl_name }}
              </div>
              <div class="shopprice">￥{{ item.thirdProductUrl_price }}</div>
            </div>
          </div>
          <div class="shop02">详情</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;display: flex;color: #999;align-items: center;justify-content: center;padding: 30px;">
      {{dataNomore?'已经到底部了~':"加载中..."}}
    </div>
    <!-- 分页 -->
    <!-- <div class="pagination-container">
      <el-pagination
        :current-page="limitStart"
        :page-sizes="[10, 60, 80, 100]"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
import InfiniteScroll from 'vue-infinite-scroll';
export default {
  directives: {
    infiniteScroll: InfiniteScroll
  },
  data() {
    return {
      list: [],
      total: 0,
      limitStart: 0,
      pageSize: 20,
      dataLoadStop: false,  //列表数据加载停止状态
      dataLoadNomore: false,    //列表数据加载是否完成
      dataNomore:false,
    };
  },
  mounted() {
    checkLogin()
      .then(() => {})
      .catch(() => {});
    this.getList();
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
    city() {
      return this.$store.state.city;
    },
  },
  watch: {
    city(e) {
      this.limitStart = 0;
      this.getList();
    },
  },
  methods: {
    async getList() {
      let that = this;
      this.dataLoadStop = true;
      let qarms = `city=${this.city}&limitStart=${
        this.limitStart* this.pageSize
      }&pageSize=${this.pageSize}`;
      await that.$axios
        .get(`${that.$base_url}/webStoreBusiness/getBusiness?${qarms}`)
        .then((res) => {
          if (res.data.status == 200) {
            that.total = res.data.data.total;
            that.list = that.list.concat(res.data.data.resultBizList)
            if(res.data.data.resultBizList.length>=that.pageSize){
              that.limitStart += 1
              that.dataLoadStop = false
            }else{
              that.dataNomore = true
            }
          } else {
            that.dataLoadStop = false
            this.$message.error(res.data.message);
          }
        });
    },
    routers(item) {
      let that = this;
      checkLogin()
        .then((res) => {
          if (res.flag) {
            this.$router.push({
              path: "/PruductList",
              query: {
                bizNum: item.bizNum,
                mtzName: item.mtzName,
                rzType: item.rzType,
              },
            });
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", "/PruductList");
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
    },
    //页数
    handleCurrentChange(val) {
      this.limitStart = val;
      this.getList();
    },
    //每页条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.shop {
  margin-left: auto;
  display: flex;
  align-items: center;
  .shopprice {
    color: #ff3d09;
    font-size: 16px;
    margin-top: 12px;
  }
  .shoptitle {
    width: 120px;
    font-size: 18px;
    white-space: nowrap; /* 防止换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
    margin-top: 14px;
  }
  .shopimg {
    width: 120px;
    height: 100px;
    background: #fff;
    border: 1px solid #faece9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 73px;
      height: 73px;
    }
  }
  .shop01 {
    display: flex;
    align-content: center;
    .shop01s {
      margin-left: 15px;
    }
  }
  .shop02 {
    width: 82px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff3d09;
    font-size: 18px;
    margin-left: 20px;
    border: 1px solid #ff3d09;
    border-radius: 19px;
  }
}
.address {
  display: flex;
  align-items: center;
  color: #8a8a94;
  font-size: 18px;
  margin-top: 20px;
  img {
    width: 14px;
    height: 18px;
    margin-right: 5px;
  }
}
.buhuo {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 18px;
  span {
    color: #ff3d09;
  }
}
.lists {
  .titles {
    color: #ff3d09;
    font-size: 20px;
    width: 185px;
    img {
      width: 57px;
      height: 100%;
    }
    height: 34px;
    background: #fff;
    border: 1px solid #ffbead;
    border-radius: 5px;
    margin-top: 17px;
    display: flex;
    align-items: center;
  }
  .title {
    font-size: 28px;
    margin-left: 20px;
  }
  .listss {
    width: calc(100% - 76px);
    margin: 0 auto;
    .list01 {
      width: 100px;
      height: 100px;
      background: #fff;
      border: 1px solid #e0e0e4;
      box-shadow: 0px 4px 3px 0px rgba(199, 200, 201, 0.32);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 82px;
        height: 82px;
      }
    }
  }
  width: 100%;
  height: 210px;
  background: url("../../assets/new/pifa.png") no-repeat;
  background-size: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.list {
  width: 1250px;
  margin: 0 auto;
  .biaoshis {
    .shouye {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #edeef6;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff3d09 !important;
}
</style>