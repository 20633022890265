
import { getApp } from "@/common/global";


import axios from "axios";
import {base_url} from "@/common/constant";


// /webStoreUser/address/default/{uid}/{id}
export function remote_set_default_address(id){
  return new Promise((resolve)=>{
    const login_user = getApp().$store.state.login_user;
    if (login_user) {
      const uid = login_user.id;
      axios.get(`${base_url}/webStoreUser/address/default/${uid}/${id}`).then(res=>{
        res = res.data;
        if (res.status == 200) {
          resolve({flag:true, data: res.data});
        } else {
          resolve({flag:false, err: res});
        }
      }).catch(res=>{
        console.error(res);
        resolve({flag:false, err: res});
      });
    } else {
      resolve({flag:false, err: {message: '请重新登录'}});
    }
  });
}

// http://127.0.0.1/mall/webStoreUser/address/list/1
export function remote_listAddressByUid(){
  return new Promise((resolve) => {
    const login_user = getApp().$store.state.login_user;
    if (login_user) {
      axios.get(`${base_url}/webStoreUser/address/list/${login_user.id}`).then(res=>{
        res = res.data;
        if (res.status == 200) {
          resolve({flag:true, data: res.data});
        } else {
          resolve({flag:false, err: res});
        }
      }).catch(res=>{
        console.error(res);
        resolve({flag:false, err: res});
      });
    } else {
      resolve({flag:false, err: {message: '请重新登录'}});
    }
  });
}

//  /webStoreUser/address/add
export function remote_add_address(addressInfo){
  return new Promise((resolve) => {
    const login_user = getApp().$store.state.login_user;
    if (login_user) {
      addressInfo.uid = login_user.id;
      axios.post(`${base_url}/webStoreUser/address/add`, addressInfo).then(res=>{
        res = res.data;
        if (res.status == 200) {
          resolve({flag:true, data: res.data});
        } else {
          resolve({flag:false, err: res});
        }
      }).catch(res=>{
        console.error(res);
        resolve({flag:false, err: res});
      });
    } else {
      resolve({flag:false, err: {message: '请重新登录'}});
    }

  });
}


