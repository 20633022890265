
<template>
  <div>
    <div class="youqing">
      <div class="youqings">
         <div class="youqing01">友情链接：</div>
         <div class="youqing02">
             <a href="/">{{title}}</a>
             <a style="margin-left: 20px;" href="https://activity.huaweicloud.com/" target="_blank">华为云</a>
         </div>
      </div>
    </div>

    <div class="footer">
    <div class="content">

      <!--div class="ng-promise-box">
        <div class="ng-promise">
          <p class="text">
            <a class="icon1" href="javascript:;">7天无理由退换货</a>
            <a class="icon2" href="javascript:;">满99元全场免邮</a>
            <a class="icon3" style="margin-right: 0" href="javascript:;">100%品质保证</a>
          </p>
        </div>
      </div-->

      <!-- mod_help -->
      <div style="box-sizing: border-box;text-align: center;font-size: 16px;line-height: 34px;margin-top: 20px;">
        <!--p>
          <a href="javascript:;">关于我们</a>
          <span>|</span>
          <a href="javascript:;">帮助中心</a>
          <span>|</span>
          <a href="javascript:;">售后服务</a>
          <span>|</span>
          <a href="javascript:;">母婴资讯</a>
          <span>|</span>
          <a href="javascript:;">关于货源</a>
        </p-->
        <!--p>母婴商城版权所有 &copy; 2012-2020 More Templates <a href="http://sskoo.taobao.com/" target="_blank" title="sskoo">sskoo</a> - Collect from <a href="http://sskoo.taobao.com/" title="sskoo" target="_blank">sskoo</a></p-->
<!--        <p style="color: white;">地址：深圳市宝安区沙井巨基U谷智造创新园D栋5楼</p>-->
        <p style="color: white;">
          <img src="../assets/new/jingcha.png" alt="" style="width: 20px;height: 20px;">
          ©{{year}} 深圳市正绿航信息技术版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备17027204号</a> | 增值电信业务经营许可证：粤B2-20240448
        </p>
<!--          <img src="../assets/new/shiming.png" alt="" class="shiming">-->
      </div>

    </div>
  </div><!-- root div -->
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      year:"",
	  title:process.env.VUE_APP_TITLE
    };
  },//data end
  mounted(){
    let that = this;
    that.$axios
        .get(`${that.$base_url}/webStoreOfFirstPage/getYear/`)
        .then(res=>{
          if (res.data.status == 200) {
             that.year = res.data.data.val
          }else {
            this.$message.error(res.data.message);
          }
        })
  },
  methods: {},//methods end
}
</script>

<style lang="scss" scoped>
.youqing{
  width: 100%;
  height: 70px;
  background: #2e323f;
  .youqings{
    width: 1250px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    .youqing02{
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 16px;
      span{
        margin-right: 20px;
      }
    }
    .youqing01{
      color: #fff;
      font-size: 18px;
    }
  }
}
a {
  color: white;
  &:hover {
    color: white;
    text-decoration: underline white;
  }
}
.shiming{
  width: 265px;
  height: 33px;
  margin-top: 10px;
}
.footer {
  margin: 0;
  padding: 0 0 45px 0;
  background: #353947;
  width: 100%;
  //background-color: #f5f5f5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .content {
    width: 1200px;
    display: block;
  }

  .ng-promise-box {
    border-bottom: 1px solid #3d3d3d;
    line-height: 145px;
    padding: 50px 0;
  }

  .ng-promise-box .ng-promise {
    //padding-left: 100px;
    box-sizing: border-box;
    p {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-content: center;
    }
  }

  .ng-promise-box .ng-promise p a {
    color: #fff;
    font-size: 20px;
    //margin-right: 210px;
    padding-left: 44px;
    height: 40px;
    display: inline-block;
    line-height: 40px;
    background: url(../assets/us-icon.png) no-repeat left 0;
  }

  .ng-promise-box .ng-promise p a.icon2 {
    padding-left: 56px;
    background-position: 0 -44px;
  }

  .ng-promise-box .ng-promise p a.icon3 {
    background-position: 0 -80px;
  }

  .mod_help {
    text-align: center;
    color: #888888;
  }

  .mod_help p {
    margin: 38px 0 16px 0;
  }

  .mod_help p a {
    color: #888888;
  }

  .mod_help p span {
    padding: 0 22px;
  }

}
</style>
