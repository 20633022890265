import Vue from "vue";
import Vuex from "vuex";
import { setStore } from "@/common/global";
import { checkLogin } from "@/common/tools.js"
Vue.use(Vuex);

const store = new Vuex.Store({
  state() {
    return {
      drawer_order_detail: '', // 将要显示在抽屉里的订单详情
      order_main: '', // 主订单
      drawer_isShow: false, // 抽屉的显示与隐藏
      is_header_show: true, // 是否显示header
      searchDisabled: false, // 禁用搜索按钮
      url_before_reload: '', // reload前保存的url
      firstPageList: undefined, // 首页商品列表
      countryList: undefined, // 国家列表
      login_user: undefined, // 当前登录用户
      // 是否登录, `src/common/tools.js` "checkLogin"; `UserInfo.vue` "logout"; `Login.vue` "handleLogin";
      is_login: false,
      url_before_login: `/?v=${Math.random()}`, // 记住登录前的url地址，默认为: 个人中心, 默认为首页
      visible_for_userinfo: false, // 用户中心dialog的显示隐藏标记
      visible_for_payment: false,// 结算(支付)dialog的显示隐藏标记

      city:"深圳市",//深圳市
      searchName:"",//检索的名称
      time:"",
    };
  },
  mutations: {
    change_drawer_order_detail(state, orderDetail){
      state.drawer_order_detail = orderDetail;
    },
    change_order_main(state, order_main){
      state.order_main = order_main;
    },
    change_drawer_isShow(state, flag) {
      state.drawer_isShow = flag;
    },
    change_is_header_show(state, flag) {
      state.is_header_show = flag;
    },
    change_searchDisabled(state, disabled) {
      state.searchDisabled = disabled;
    },
    change_url_before_reload(state, url) {
      state.url_before_reload = url;
    },
    change_firstPageList(state, list){
      state.firstPageList = list;
    },
    change_countryList(state, list){
      state.countryList = list;
    },
    change_login_user(state, userInfo){
      state.login_user = userInfo;
    },
    change_is_login(state, isLogin) {
      state.is_login = isLogin;
      //这里走一下登录接口
      // checkLogin();
    },
    change_url_before_login(state, url) {
      state.url_before_login = url;
    },
    change_visible_for_userinfo(state, visible){
      state.visible_for_userinfo = visible;
    },
    change_visible_for_payment(state, visible) {
      state.visible_for_payment = visible;
    },
    change_city(state, visible){
      state.city = visible
    },
    change_searchName(state, visible){
      state.searchName = visible
      state.time = new Date().getTime() + Math.random() + new Date().getTime()
    }
  },
  modules: {},
});

setStore(store);

export default store;
