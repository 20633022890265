<template>
  <div class="root-parent">
    <!--当前定位-->
    <div class="biaoshis">
      <img
        src="@/assets/new/shouye.png"
        @click="$router.push({ path: '/' })"
        class="shouye"
        alt=""
      />
      <div class="dangqian">当前位置：个人中心</div>
    </div>
    <!--当前定位-->
    <el-container class="root">
      <el-aside width="230px" class="aside">
        <AsideMenuNew @navName="navName" :asideActiveIndexs="asideActiveIndex"></AsideMenuNew>
      </el-aside>
      <el-main>
        <div class="user">
           <div class="users">
            <img src="@/assets/nav/juxing.png" class="juxing" alt="">
            {{name}}
            <el-button type="text" v-if="asideActiveIndex==1" style="margin-left: auto;" @click="logout">
             <span style="color: #FF5B2F;">退出登录</span>
            </el-button>
            <!--优惠券的tab切换-->
            <div v-if="asideActiveIndex==3" class="tab">
               <div class="tabs" @click="tabType='1'" :class="[tabType==1?'tabselect':'']">全部</div>
               <div class="tabs" @click="tabType='2'" :class="[tabType==2?'tabselect tabss':'']">已使用</div>
               <div class="tabs" @click="tabType='3'" :class="[tabType==3?'tabselect tabss':'']">未使用</div>
               <div class="tabs" @click="tabType='4'" :class="[tabType==4?'tabselect tabss':'']">已失效</div>
            </div>
            <!--优惠券的tab切换-->
           </div>
        </div>
        <userInfoVue v-if="asideActiveIndex==1" />
        <orderVue v-if="asideActiveIndex==2" style="margin-top: 20px;" />
        <couponVue v-if="asideActiveIndex==3" :yhState="tabType" />
        <ApplyVue v-if="asideActiveIndex==4" />
        <!-- <router-view></router-view> -->
      </el-main>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
// import { base_url } from "@/common/constant";
// import { checkLogin } from "@/common/tools";

import AsideMenuNew from "@/components/user/AsideMenuNew";
import userInfoVue from "./UserInfo.vue"
import { common_logout } from "@/common/tools";
import orderVue from "@/components/user/OrderSearchTab"
import ApplyVue from "@/views/shop/enter_apply"
import couponVue from "@/views/shop/coupon"
import { checkLogin } from "@/common/tools";
export default {
  name: "UserCenter",
  data() {
    return {
      name:"我的资料",
      asideActiveIndex:"1",

      tabType:"1",//1全部 2已使用  3未使用 4已失效
    };
  }, //data end
  computed: {},
  components: {
    AsideMenuNew,userInfoVue,orderVue,ApplyVue,couponVue
  },
  methods: {
    logout(){
      common_logout();
    },
    navName(e,asideActiveIndex){
      this.name = e
      this.asideActiveIndex = asideActiveIndex
    },
  }, //methods end
  mounted(){
	  if(this.$route.query.n){
		  this.asideActiveIndex = this.$route.query.n
	  }
    checkLogin();
  },
  created() {}, //created end
};
</script>

<style lang="scss" scoped>
.tab{
  margin-left: auto;
  display: flex;
  align-items: center;
  .tabselect{
    color: #FF3D09!important;
    font-weight: bold;
  }
  .tabselect::after{
    content:"";
    position: absolute;
    width: 39px;
    height: 4px;
    background: #FF3D09;
    bottom: -25px;
    left: 0px;
  }
  .tabss::after{
    left: 12px!important;
  }
  .tabs{
    font-size: 20px;
    color: #515151;
    cursor: pointer;
    margin-left: 50px;
    position: relative;
  }
}
.user{
  width: 958px;
  margin: 0 auto;
  .users{
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    color: #515151;
    font-size: 20px;
    border-bottom: 1px solid #E8EAF0;
    .juxing{
      width: 8px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.biaoshis {
  .shouye {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  width: 1248px;
  margin: 0 auto;
  padding-top: 22px;
  padding-bottom: 22px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.root-parent {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

.root {
  width: 1248px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 30px);
  background-color: white;
  border-radius: 15px;
}

.aside {
  border-right: 1px solid lightgrey;
}
</style>
