<template>
  <div id="app">
    <Header></Header>
    <BodyTop></BodyTop>
    <router-view></router-view>

    <!-- 抽屉 -->
    <OrderDetailDrawer></OrderDetailDrawer>
    <Footer></Footer>

    <div v-if="isIE" style="width: 1200px;height: 100px;background: #fff;border: 1px solid #EBEEF5;position: fixed;left: 50%;margin-left: -600px;z-index: 9999;box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);top: 20px;">

    <div  style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 30px;font-weight: bold;">
      ie浏览器已停止维护，推荐使用
      <span style="color: #ff6700;">
        edge、谷歌、360、qq
      </span>
      浏览器
    </div>
    </div>
  </div>
  <!-- root div -->
</template>

<script>
import Header from "@/components/Header";
import OrderDetailDrawer from "@/components/drawer/OrderDetailDrawer";

import BodyTop from "@/components/BodyTop";
import Footer from "@/components/Footer";
export default {
  name: "App",
  components: {
    Header, // 页头
    OrderDetailDrawer, // 抽屉
    BodyTop,
    Footer,
  },
  data(){
    return{
      isIE:false
    }
  },
  created(){
    const isIE = !!window.ActiveXObject || "ActiveXObject" in window;
    this.isIE = isIE
  },
};
</script>

<style lang="scss">
/deep/ .el-notification.right{
  width: 600px!important;
  right: 50%!important;
  margin-right: -300px!important;
}
/deep/ .el-notification__content{
  font-size: 30px!important;
  color: #000!important;
  font-weight: bold!important;
  margin-top:20px!important;
}
/deep/ .el-notification__title{
  font-size: 25px!important;
}
body {
  margin: 0;
  padding: 0;
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  //box-sizing: border-box;
}
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  //margin-top: 60px;
  margin: 0;
  padding: 0;
  background: #f3f4f7;
}
.pagination-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FF3D09!important;
}
/**商品列表公共样式 */
.product {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .img{
    img{
      width: 100%;
      height: 100%;
    }
  }
  .price {
      width: calc(100% - 40px);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      .prices{
        color: #ff3d09;
        font-size: 16px;
      }
      .pricelogin {
        width: 126px;
        height: 32px;
        border: 1px solid #ff3d09;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff3d09;
        font-size: 16px;
        margin-top: 16px;
      }
    }
  .mtzName {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .mtzName02 {
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #8a8a94;
      margin-left: 5px;
    }
    .mtzName01 {
      width: 18px;
      height: 18px;
      background: #ff3d09;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
    }
  }
  .title {
    color: #515151;
    font-size: 18px;
    width: calc(100% - 40px);
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/**商品列表公共样式 */
</style>
