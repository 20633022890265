<template>
  <div style="width: 1250px;margin: 0 auto;margin-top: 20px;">
    <div style="width: 100%;" v-if="list.length==0">
      <el-empty description="" style="margin: 0 auto;"></el-empty>
    </div>
    <div class="list"  v-infinite-scroll="getList" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="10">
      
      <div class="lists" v-for="(item, i) in list" :key="i" @click="gotoDetail(item)">
        <div class="product">
          <div class="img" style="width: 242px; height: 190px">
            <img :src="$translateProdUrl(item.productUrl)" alt="" v-if="item.productUrl" />
          </div>
          <div class="title" style="text-align: left">
            {{ item.productName || "--" }}
          </div>
          <div class="mtzName" style="justify-content: flex-start">
            <div class="mtzName01">{{item.rzType==1?'企':'店'}}</div>
            <div class="mtzName02">{{ item.mtzName || "--" }}</div>
          </div>
          <div class="price" style="justify-content: flex-start">
            <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
            <div class="prices" v-if="isLogin" style="margin-top: 10px;">
              ￥{{ item.yhPrice || item.bigWholesalePrice}}
              <span style="text-decoration:line-through;color: #aaa;font-size: 14px;" v-if="item.bigWholesalePrice && item.yhPrice">￥{{item.bigWholesalePrice}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="list.length>0" style="width: 100%;display: flex;color: #999;align-items: center;justify-content: center;padding: 30px;">
      {{dataNomore?'已经到底部了~':"加载中..."}}
    </div>
    <!-- 分页 -->
    <!-- <div class="pagination-container">
      <el-pagination
        :current-page="limitStart"
        :page-sizes="[20, 60, 80, 100]"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
import InfiniteScroll from 'vue-infinite-scroll';
  export default {
    directives: {
    infiniteScroll: InfiniteScroll
  },
    data(){
      return{
        list:[],
        total:0,
        limitStart:0,
        pageSize:20,
        dataLoadStop: false,  //列表数据加载停止状态
        dataLoadNomore: false,    //列表数据加载是否完成
        dataNomore:false,
      }
    },
    computed:{
      is_header_show(){
        return this.$store.state.is_header_show;
      },
      isLogin(){
        return this.$store.state.is_login;
      },
      user_name(){
        const login_user = this.$store.state.login_user;
        if (login_user && login_user.username) {
          return login_user.username;
        } else {
          return "";
        }
      },
      city(){
        return this.$store.state.city
      },
      searchName(){
        return this.$store.state.searchName
      },
      time(){
        return this.$store.state.time
      },
    },
    watch:{
      time(){
        this.limitStart = 0
        this.getList();
      },
    },
    mounted(){
      this.getList();
    },
    methods:{
       getList(){
        let that = this;
      this.dataLoadStop = true;
        if(!this.searchName){
          let qarms = `city=${this.city}&limitStart=${that.limitStart*that.pageSize}&pageSize=${that.pageSize}`;
          that.$axios
            .get(`${that.$base_url}/webStoreOfFirstPage/queryProducts?${qarms}`)
            .then((res) => {
              if (res.data.status == 200) {
                if(that.limitStart==0){
                  that.list = []
                }
            if(res.data.data.list.length>=that.pageSize){
              that.limitStart += 1
              that.dataLoadStop = false
              that.total = res.data.data.total;
              that.list = that.list.concat(res.data.data.list)
            }else{
              that.dataNomore = true
            }
          } else {
            that.dataLoadStop = false
            this.$message.error(res.data.message);
          }
            });
            return;
        }else{
          let qarms = `${this.searchName}/${that.limitStart*that.pageSize}/${this.pageSize}`;
      that.$axios
        .get(`${that.$base_url}/webStoreOfFirstPage/searchProducts/${qarms}`)
        .then((res) => {
          if (res.data.status == 200) {
            if(that.limitStart==0){
                that.list = []
              }
            if(res.data.data.list.length>=that.pageSize){
              that.limitStart += 1
              that.dataLoadStop = false
              that.total = res.data.data.total;
              that.list = that.list.concat(res.data.data.list)
            }else{
              that.list = that.list.concat(res.data.data.list)
              that.dataNomore = true
            }
          } else {
            that.dataLoadStop = false
            this.$message.error(res.data.message);
          }
        });
        }
       },
           //页数
    handleCurrentChange(val) {
      this.limitStart = val;
      this.getList();
    },
    //每页条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    gotoDetail(item) {
      const that = this;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.bigWholesalePrice || item.yhPrice;
      let url = `/details?bnum=${item.bizNum}&pnum=${item.productNum}&shopName=${item.mtzName}`;
      url = `${url}&yhPrice=${yhPrice}&price=${item.bigWholesalePrice}&bizAddr=${item.bizAddr}`;
      // 跳转到商品详情前，先校验是否登录
      checkLogin()
        .then((res) => {
          if (res.flag) {
            that.$router.push(url);
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", url);
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
    },
    },
  }
</script>

<style lang="scss" scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FF3D09!important;
}
.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .lists {
    width: 240px;
    background: #fff;
    margin-right: 11px;
    margin-bottom: 11px;
    padding-bottom: 27px;
    cursor: pointer;
  }
  .lists:nth-child(5n) {
    margin-right: 0px !important;
  }
}
.goodsVue {
  width: 100%;
  margin-top: 30px;
  .tdimg {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 227px;
      height: 59px;
    }
  }
}
</style>