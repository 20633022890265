<template>
  <div class="goodsVue"  >
    <div class="tdimg">
      <img src="@/assets/new/jingxuan.png" v-if="types==0" alt="" />
      <img src="@/assets/new/haohuo.png" v-if="types==1" alt="" />
    </div>
    <div class="list" v-infinite-scroll="getList" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="10">
      <div class="lists" v-for="(item, i) in list" :key="i" @click="gotoDetail(item)">
        <div class="product">
          <div class="img" style="width: 242px; height: 190px">
            <img :src="$translateProdUrl(item.productUrl)" alt="" v-if="item.productUrl" />
          </div>
          <div class="title" style="text-align: left">
            {{ item.productName || "--" }}
          </div>
          <div class="mtzName" style="justify-content: flex-start">
            <div class="mtzName01">{{item.rzType==1?'企':'店'}}</div>
            <div class="mtzName02">{{ item.mtzName || "--" }}</div>
          </div>
          <div class="price" style="justify-content: flex-start">
            <div class="pricelogin" v-if="!isLogin">登录可享批发价</div>
            <div class="prices" v-if="isLogin" style="margin-top:10px;">
              ￥{{ item.yhPrice || item.bigWholesalePrice}}
              <span style="text-decoration:line-through;color: #aaa;font-size: 14px;" v-if="item.bigWholesalePrice && item.yhPrice">￥{{item.bigWholesalePrice}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;display: flex;color: #999;align-items: center;justify-content: center;padding: 30px;">
      {{dataNomore?'已经到底部了~':"加载中..."}}
    </div>
    <!-- 分页 -->
    <!-- <div class="pagination-container">
      <el-pagination
        :current-page="limitStart"
        :page-sizes="[10, 60, 80, 100]"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->
  </div>
</template>

<script>
import { checkLogin } from "@/common/tools";
import InfiniteScroll from 'vue-infinite-scroll';
export default {
  directives: {
    infiniteScroll: InfiniteScroll
  },
  data() {
    return {
      list: [],
      limitStart: 0,
      pageSize: 20,
      total: 0,
      dataLoadStop: false,  //列表数据加载停止状态
      dataLoadNomore: false,    //列表数据加载是否完成
      dataNomore:false,
    };
  },
  props:{
    types:{
       type:Number,
       default:0
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    is_header_show() {
      return this.$store.state.is_header_show;
    },
    isLogin() {
      return this.$store.state.is_login;
    },
    user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user && login_user.username) {
        return login_user.username;
      } else {
        return "";
      }
    },
    city() {
      return this.$store.state.city;
    },
  },
  watch:{
    city(e){
       this.limitStart = 0
       this.getList();
    },
  },
  methods: {
    getList(){
      this.dataLoadStop = true;
      let that = this;
      let qarms = `city=${this.city}&limitStart=${that.limitStart*that.pageSize}&pageSize=${that.pageSize}`;
      that.$axios
        .get(`${that.$base_url}/webStoreOfFirstPage/queryProducts?${qarms}`)
        .then((res) => {
          if (res.data.status == 200) {
            if(res.data.data.list.length>=that.pageSize){
              that.limitStart += 1
              that.dataLoadStop = false
              that.total = res.data.data.total;
              that.list = that.list.concat(res.data.data.list)
            }else{
              that.dataNomore = true
            }
          } else {
            that.dataLoadStop = false
            this.$message.error(res.data.message);
          }
        });
    },
    // async getList() {
    //   let that = this;
    //   let qarms = `city=${this.city}&limitStart=${(this.limitStart-1)*this.pageSize}&pageSize=${this.pageSize}`;
    //   await that.$axios
    //     .get(`${that.$base_url}/webStoreOfFirstPage/queryProducts?${qarms}`)
    //     .then((res) => {

    //       if (res.data.status == 200) {
    //         that.total = res.data.data.total;
    //         if(!res.data.data.list.length){
    //           that.hasNextPage = false;
    //         }else{
    //           that.list = [...this.list, ...res.data.data.list];
    //         }
    //       } else {
    //         this.$message.error(res.data.message);
    //       }
    //     });
    // },
    // loadMore(){
    //   if (this.loading || !this.hasNextPage) {
    //     return;
    //   }
    //   this.limitStart++;
    //   this.loading = true;
    //   this.getList();
    // },
    gotoDetail(item) {
      const that = this;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.bigWholesalePrice || item.yhPrice;
      let url = `/details?bnum=${item.bizNum}&pnum=${item.productNum}&shopName=${item.mtzName}`;
      url = `${url}&yhPrice=${yhPrice}&price=${item.bigWholesalePrice}&bizAddr=${item.bizAddr}`;
      // 跳转到商品详情前，先校验是否登录
      checkLogin()
        .then((res) => {
          if (res.flag) {
            that.$router.push(url);
          } else {
            // 请先登录
            that.$store.commit("change_url_before_login", url);
            that.$message.warning("请先登录!");
            setTimeout(() => {
              that.$router.push("/login");
            }, 1500);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FF3D09!important;
}
.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .lists {
    width: 240px;
    background: #fff;
    margin-right: 11px;
    margin-bottom: 11px;
    padding-bottom: 27px;
    cursor: pointer;
  }
  .lists:nth-child(5n) {
    margin-right: 0px !important;
  }
}
.goodsVue {
  width: 100%;
  margin-top: 30px;
  .tdimg {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 227px;
      // height: 59px;
    }
  }
}
</style>