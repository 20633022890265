
<template>
  <div class="detail-root" style="margin-top: 20px;">
    <div class="datails-content">

      <div class="data-cont-wrap">
         <div style="padding-bottom: 20px;font-size: 16px;display: flex;color: #515151;display: flex;align-items: center;width: 1250px;">
          您选择的是：{{shopName}}
          <span style="margin-left: 20px;font-weight: normal;color: #515151;">{{bizAddr}}</span>

          <el-button type="text" style="margin-left: auto;" @click="fanhui">返回</el-button>
         </div>
         <!-- 商品详情 -->
        <div class="product-intro layui-clear">
          <div class="preview-wrap">
            <a href="javascript:;"><img :src="productImg" style="height: 400px;width: 400px;"></a>
          </div>

          <div class="itemInfo-wrap">
            <div class="itemInfo">
              <div class="title">
                <h4>{{detail.productName}}</h4>
<!--                <span><i class="layui-icon layui-icon-rate-solid"></i>收藏</span>-->
              </div>
              <div class="summary">
                <p class="reference"><span>参考价</span> <del>￥{{detail.guidePrice}}</del></p>
                <p class="activity">
                  <span>采购价</span><strong class="price"><i>￥</i><span>{{detail.wholeSalePrice}}</span></strong>
                </p>
                <!--p class="address-box"><span>送&nbsp;&nbsp;&nbsp;&nbsp;至</span><strong class="address">江西&nbsp;&nbsp;南昌&nbsp;&nbsp;东湖区</strong></p-->
              </div>
              <div class="choose-attrs">
<!--                <div class="color layui-clear"><span class="title">颜&nbsp;&nbsp;&nbsp;&nbsp;色</span> <div class="color-cont"><span class="btn">白色</span> <span class="btn active">粉丝</span></div></div>-->
                <div class="number layui-clear"><span class="title">采购数量</span>
                  <div class="number-cont">
                    <span class="cut btn" @click="handleSubtract">-</span>
                    <input maxlength="4" type="text" disabled v-model="count">
                    <span class="add btn" @click="handleAdd">+</span>
                    <span style="margin-left: 20px;">{{detail.bigUnitName}}</span>
                  </div>
                </div>
              </div>
              <div class="choose-attrs" style="margin-top: 20px;">
                <div class="number layui-clear">
                  <span class="title">采购金额</span> <span>￥{{totalPrice}}</span>
                </div>
              </div>
              <div class="choose-btns" style="margin-top: 30px;">
                <!--button class="layui-btn layui-btn-primary purchase-btn">立刻购买</button-->
                <button class="layui-btn layui-btn-danger car-btn" @click="gotoShoppingCart">
                  <i class="layui-icon layui-icon-cart-simple"></i>去购物车
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="layui-clear">

          <!-- 热销推荐 -->
          <div class="aside">
            <h4>热销推荐</h4>
            <div class="item-list">
              <div class="item" v-if="hotProduct && hotProduct.PNAME">
                <img :src="getHotProdImg()" class="hot-prod-img" style="height: 280px;width: 280px;" @click="clickHotProduct">
                <p><span>{{hotProduct.PNAME}}</span><span class="pric">￥{{hotProduct.PRICE}}</span></p>
              </div>
            </div>
          </div>

          <!-- 详情 -->
          <div class="detail">
            <h4>详情</h4>
<!--            <div class="item"></div>-->
            <div class="item-detail">
              <div class="line">
                <span class="line-title">商品名:</span><span class="line-text">{{detail.productName}}</span>
              </div>
              <div class="line">
                <span class="line-title">商品条码:</span><span class="line-text">{{detail.barCard}}</span>
              </div>
              <div class="line">
                <span class="line-title">商品规格:</span><span class="line-text">{{detail.spec}}</span>
              </div>
              <div class="line">
                <span class="line-title">产地:</span><span class="line-text">{{ getCountryNameByCode(detail.productionPlace) }}</span>
              </div>
              <div class="line">
                <span class="line-title">生产日期:</span><span class="line-text">{{ detail.scDate }}</span>
              </div>
              <div class="line">
                <span class="line-title">保质期:</span><span class="line-text">{{ detail.shelfLife }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div><!-- root div -->
</template>

<script>

import axios from "axios";
import {base_url} from "@/common/constant";

import { getCountry, checkLogin, getFirstPageProducts } from "@/common/tools";

import Decimal from "decimal.js";

export default {
  name: 'ProductDetails',
  data() {
    return {
      detail: {}, // 商品详情
      count: 0, // 数量（购物数量）
      bnum: '', // 商家编号
      shopName: '', // 商铺名称
      btnAddDisabled: false,
      btnSubtractDisabled: false,
      yhPrice: '', // 批发价
      price: '', // 参考价
      productList: '', // 产品列表
      hotProduct: '', // 热销商品（一个）
      orderNum: '', // 订单号
      bizAddr:"",//地址
      bigUnitName:""
    };
  },//data end
  computed: {
    totalPrice() {
      const that = this;
      if (that.detail.wholeSalePrice) {
        //  Number.parseFloat(that.detail.price) * that.count;
        return new Decimal(that.detail.wholeSalePrice).mul(new Decimal(that.count)).toFixed(2);
      } else {
        return 0;
      }
    },
    productImg(){
      if (this.detail.uploadUrl) {
        let uploadUrl = this.detail.uploadUrl;
        if (!uploadUrl.includes('http')) {
          uploadUrl = `${base_url}/file/show?fileName=${uploadUrl}`;
        }
        return uploadUrl;
      } else {
        return "";
      }
    },
  },//computed end
  methods: {
    fanhui(){
      this.$router.go(-1)
      // this.$router.push({
      //   path:"/PruductList",
      //   query:{
      //     bizNum:this.bnum,
      //     mtzName:this.shopName,
      //     rzType:this.rzType
      //   }
      // })
    },

    // http://127.0.0.1/mall/webStore/getBuyNumByUser/10001/103

    remote_getBuyNumByUser() {
      const that = this;
      if (that.bnum && that.detail.productNum) {
        axios.get(`${base_url}/webStore/getBuyNumByUser/${that.bnum}/${that.detail.productNum}`).then(res=>{
          res = res.data
          if (res.status == 200) {
            if (res.data && res.data.length > 0) {
              const prodOrder = res.data[0];
              if (prodOrder) {
                that.count = prodOrder.buyBigNum;
                that.orderNum = prodOrder.buyOrderNum;
                that.bigUnitName = prodOrder.bigUnitName
              }
            }
          } else {
            console.error(res);
          }
        }).catch(err=>{
          console.error(err);
        })
      }
    },

    clickHotProduct(){
      const that = this;
      // hotProduct
      const item = that.hotProduct;
      // 首页查询、根据条件查询，返回的价格字段不一样
      const yhPrice = item.BIGPRICE || item.YHPRICE;
      let url = `/details?bnum=${item.BNUM}&pnum=${item.PNUM}&shopName=${item.SHOPNAME}`;
      url = `${url}&yhPrice=${that.detail.yhPrice}&price=${item.PRICE}`;
      // reload
      that.$store.commit('change_url_before_reload', url);
      that.$router.push(`/reload?v=${Math.random()}`);
    },

    getHotProdImg(){
      const that = this;
      if (that.hotProduct && that.hotProduct.PHOTOURL) {
        let img_url = that.hotProduct.PHOTOURL;
        if (!img_url.includes('http')) {
          img_url = `${base_url}/file/show?fileName=${img_url}`;
        }
        return img_url;
      } else {
        return "";
      }
    },
    getCountryNameByCode(code){
      const that = this;
      const countryList = that.$store.state.countryList;
      if (countryList) {
        const item = countryList.find(item => item.countryCode == code);
        if (item) {
          return item['countryName'];
        } else {
          return code;
        }
      } else {
        return code;
      }
    },

    gotoShoppingCart(){
      const that = this;
      if (that.count == 0) {
        that.$message.error("请选择数量");
        return;
      } else {
        if (that.orderNum != '') {
          that.$router.push(`/shoppingCart?orderNum=${that.orderNum}&bnum=${that.bnum}`);
        } else {
          that.$message.error("没有orderNum");
        }
      }
    },

    handleAdd(){
      const that = this;
      const submitData = {};//
      if (!that.btnAddDisabled) {
        that.count ++;
        that.btnAddDisabled = true;

        //dto入参，time:2022-09-14
        submitData.count = that.count;
        submitData.supplierBizNum = that.bnum;
        submitData.supplierName = that.shopName;
        submitData.productNum = that.detail.productNum;
        submitData.dwNum = that.detail.commonUnitNum;
        submitData.smallSortCode = that.detail.sortCode;

        // /webStore/addBuyCart/{count}/{sBNum}/{supplierName}/{productNum}/{dwNum}
        /*let url = `${base_url}/webStore/addBuyCart/${that.count}/${that.bnum}`;
        url = `${url}/${that.shopName}/${that.detail.productNum}/${that.detail.commonUnitNum}`;*/
        let url = `${base_url}/webStore/addBuyCart`;
        axios.post(url,submitData).then(res=>{
          res = res.data;
          if (res.status == 200) {
            if (res.data && res.data.length > 0) {
              let data = res.data[0];
              that.orderNum = data.orderNum;
            }
            that.btnAddDisabled = false;
          } else {
            let errMsg = "系统升级中，请稍候";
            if (res.message && res.message.length < 30) {
              errMsg = res.message;
            }
            that.$message.error(errMsg);
            // console.error(res);
            that.btnAddDisabled = false;
            that.count --;
          }
        }).catch(res=>{
          console.error(res);
          that.btnAddDisabled = false;
          that.count --;
        });
      }
      // axios.put()
    },
    handleSubtract(){
      const that = this;
      if (that.count === 0) {
        return;
      } else {
        if (!that.btnSubtractDisabled) {
          that.count --;
          that.btnSubtractDisabled = true;

          // /webStore/subBuyCart/{count}/{sBNum}/{productNum}/{dwNum}
          let url = `${base_url}/webStore/subBuyCart/${that.count}/${that.bnum}`;
          url = `${url}/${that.detail.productNum}/${that.detail.commonUnitNum}`;
          axios.put(url).then(res=>{
            res = res.data;
            if (res.status == 200) {
              //
              that.btnSubtractDisabled = false;
            } else {
              let errMsg = "系统升级中，请稍候";
              if (res.message && res.message.length < 30) {
                errMsg = res.message;
              }
              that.$message.error(errMsg);
              // console.error(res);
              that.btnSubtractDisabled = false;
              that.count ++;
            }
          }).catch(res=>{
            console.error(res);
            that.btnSubtractDisabled = false;
            that.count ++;
          });
        }
      }
    },

    remote_getDetail(bnum, pnum){
      const that = this;
      return new Promise((resolve) => {
        axios.get(`${base_url}/webStore/getDetail/${bnum}/${pnum}`).then(res=>{
          res = res.data;
          if (res.status == 200) {
            if (res.data && res.data.length > 0) {
              that.detail = res.data[0];
              resolve({flag: true, err: res.data[0]});
            } else {
              console.error(res);
              resolve({flag: false, err: res});
            }
          } else {
            console.error(res);
            resolve({flag: false, err: res});
          }
        }).catch(res=>{
          console.error(res);
          resolve({flag: false, err: res});
        });
      });
    },

    getHotProduct(){
      const that = this;
      if (that.productList && that.detail.productNum) {
        for(let item of that.productList) {
          if (item.PNUM != that.detail.productNum) {
            that.hotProduct = item;
            return;
          }
        }
      }
    },

    getFirstPageList() {
      const that = this;
      return new Promise((resolve) => {
        const firstPageList = that.$store.state.firstPageList;
        if (!firstPageList) {
          getFirstPageProducts(0, 10).then(res => {
            if (res.flag) {
              const data = res.data;
              if (data.list) {
                // hotProduct
                that.productList = data.list;
                that.$store.commit('change_firstPageList', data.list);
              }
              resolve({flag: true, data: data.list});
            }
          }).catch((err)=>{
            resolve({flag: false, err: err});
          });
        } else {
          that.productList = firstPageList;
          resolve({flag: true, data: firstPageList});
        }
      });
    },

  },//methods end
  created() {
    const that = this;
    // 先校验是否登录
    const url = that.$route.fullPath;
    checkLogin().then(res=>{
      if (res.flag) {
        const query = that.$route.query;
        if (query && query.bnum && query.pnum) {
          that.bnum = query.bnum;
          that.shopName = query.shopName;
          that.yhPrice = query.yhPrice;
          // that.price = query.price;
          that.bizAddr = query.bizAddr
          that.rzType = query.rzType
          // that.remote_getDetail(query.bnum, query.pnum);
          Promise.all([that.remote_getDetail(query.bnum, query.pnum),
            that.getFirstPageList()]).then(()=>{
            //
            that.getHotProduct();
            // 查询用户购买的商品数量（未结算）
            that.remote_getBuyNumByUser();
          }).catch(err=>{
            console.error(err);
          });
        }
      } else {
        // 请先登录
        that.$store.commit('change_url_before_login', url);
        that.$message.warning("请先登录!");
        setTimeout(()=>{
          that.$router.push('/login');
        }, 1500);
      }
    }).catch(()=>{});

    const countryList = that.$store.state.countryList;
    if (!countryList) {
      getCountry().then(res=>{
        if (res.flag) {
          that.$store.commit('change_countryList', res.data);
        }
      }).catch(()=>{});
    }




  },//created end
}
</script>

<style lang="scss" scoped>

.detail-root {
  margin: 0;
  padding: 0;
  width: 100%;
  //background-color: #f8f8f8;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}


/*details*/
.datails-content {
  width: 1200px;
  display: block;
  padding-bottom: 80px;
}

.datails-content .crumb {
  line-height: 76px;
}

.datails-content .crumb span {
  font-family: simsun;
  color: #666;
  font-weight: bold;
  margin: 0 5px;
}

.datails-content .crumb a {
  color: #666;
}

.datails-content .crumb a:hover {
  color: #ff5500;
}

.datails-content .product-intro {
  margin-bottom: 85px;
}

.datails-content .product-intro .preview-wrap {
  width: 430px;
  float: left;
}

.datails-content .product-intro .preview-wrap img {
  border: 1px solid #eaeaea;
}

.datails-content .itemInfo-wrap {
  float: left;
  width: 770px;
}

.datails-content .itemInfo .title {
  position: relative;
  margin-bottom: 30px;
}

.datails-content .itemInfo .title h4 {
  font-size: 18px;
}

.datails-content .itemInfo .title span {
  position: absolute;
  right: 0;
  top: 2px;
  cursor: pointer;
}

.datails-content .itemInfo .title span i {
  margin-right: 4px;
  color: #ff5500;
}

.datails-content .itemInfo .summary {
  width: 100%;
  height: 135px;
  background: #f5f5f5;
  padding-top: 25px;
  margin-bottom: 26px;
}

.datails-content .itemInfo .summary p {
  line-height: 40px;
  padding-left: 34px;
  color: #888888;
}

.datails-content .itemInfo .summary p span {
  padding-right: 40px;
}

.datails-content .itemInfo .summary .activity .price {
  font-size: 30px;
  color: #ee0000;
}

.datails-content .itemInfo .summary .activity .price i {
  font-size: 14px;
}

.datails-content .itemInfo .choose-attrs {
  color: #888888;
  line-height: 32px;
  padding-left: 34px;
}

.datails-content .itemInfo .choose-attrs .title {
  margin-right: 34px;
  float: left;
  margin-bottom: 0;
}

.datails-content .itemInfo .choose-attrs .color {
  margin-bottom: 18px;
}

.datails-content .itemInfo .choose-attrs .color .color-cont {
  display: inline-block;
  float: left;
}

.datails-content .itemInfo .choose-attrs .color .color-cont .btn {
  display: inline-block;
  padding: 0 26px;
  margin-right: 26px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  color: #000;
}

.datails-content .itemInfo .choose-attrs .color .color-cont .btn.active {
  border-color: #ff5500;
}

.datails-content .itemInfo .choose-attrs .number {
  line-height: 30px;
}

.datails-content .itemInfo .choose-attrs .number .title {
  float: left;
}

.datails-content .itemInfo .choose-attrs .number .number-cont {
  display: inline-block;
  float: left;
  line-height: 30px;
}

.datails-content .itemInfo .choose-attrs .number .number-cont .btn {
  display: inline-block;
  padding: 0 6px;
  border: 1px solid #dddddd;
  background: #f8f8f8;
  width: 20px;
  text-align: center;
  font-size: 26px;
  cursor: pointer;
  float: left;
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}

.datails-content .itemInfo .choose-attrs .number .number-cont .cut {
}

.datails-content .itemInfo .choose-attrs .number .number-cont input {
  width: 35px;
  height: 28px;
  text-align: center;
  margin: 0 5px;
  float: left;
}

.datails-content .itemInfo .choose-btns {
  padding-left: 34px;
  margin-top: 22px;
}

.datails-content .itemInfo .choose-btns .purchase-btn {
  border: 1px solid #ff5500;
  color: #ff5500;
  width: 116px;
  height: 40px;
  line-height: 40px;
}

.datails-content .itemInfo .choose-btns .car-btn {
  width: 136px;
  height: 40px;
  line-height: 40px;
}

.datails-content .aside {
  display: block;
  float: left;
  width: 360px;
  margin-right: 40px;
}

.datails-content .aside h4 {
  font-size: 18px;
  line-height: 42px;
  padding-left: 18px;
  color: #888888;
}

.datails-content .aside .item-list {
  border: 1px solid #ededed;
  padding: 45px;
  padding-bottom: 0;
}

.datails-content .aside .item-list .item {
  margin-bottom: 34px;
}

.datails-content .aside .item-list .item p {
  line-height: 64px;
  position: relative;
}

.datails-content .aside .item-list .item .pric {
  color: #ee0000;
  position: absolute;
  right: 0;
}

.datails-content .detail {
  //float: right;
  float: left;
}

.datails-content .detail h4 {
  line-height: 42px;
  width: 100px;
  text-align: center;
  border-bottom: 2px solid #888888;
  font-size: 18px;
  cursor: pointer;
  color: #888888;
}

.datails-content .detail .item {
  display: block;
  border: 1px solid #ececec;
  width: 795px;
}

/*end-details*/

.item-detail {
  width: 770px;
  border: 2px solid #bbb8b8;
  margin: 10px 0;
  padding: 10px;
  .username {
    margin-right: 5px;
  }
  .province {
  }
  .line {
    margin: 5px 0;
  }
  .line-title {
    display: inline-block;
    width: 80px;
    text-align: right;
    color: #b1acac;
    margin-right: 10px;
  }
  .line-text {}
}

.hot-prod-img {
  cursor: pointer;
}
</style>
