<template>
  <div class="root">
    <img :src="qrUrl" alt="微信支付二维码">
    <div style="display: block;font-size: 22px;letter-spacing: .2em;text-indent: .2em;color: red;">￥{{price}}</div>
    <div style="display: block;font-size: 30px;letter-spacing: .2em;text-indent: .2em;">微信扫码支付</div>
  </div><!-- root div -->
</template>
<script>

// import home from "@/json/home.json";

import axios from "axios";
import { base_url } from "@/common/constant";
//
// import { checkLogin } from "@/common/tools";


export default {
  name: 'WxPay',
  components: {
  },// components end
  data() {
    return {
      qrUrl: '', // 微信支付二维码
      price: '',
    };
  },//data end
  computed: {
  },//computed end
  methods: {
  },//methods end
  created() {
    const that = this;
    const query = that.$route.query;
    const postData = {};

    if (query.code_url) {
      that.price = query.price;
      postData.url = query.code_url;
      axios.post(`${base_url}/qrCode/generate`, postData, {
        responseType: 'arraybuffer'
      }).then(res=>{
        if (res.status == 200) {
          that.qrUrl = "data:image/png;base64," + btoa(
            new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );
        }
      }).catch(err=>{
        console.error(err);
      });
      // that.qrUrl = `${base_url}/qrCode/generate`;
    }
  },//created end
}
</script>
<style lang="scss" scoped>
.root {
  display: flex;
  box-sizing: border-box;
  //margin-top: -30px;
  background-color: #f8f8f8;
  opacity: 1;
  height: calc(100vh - 30px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px;
  img {
    display: block;
    width: 400px;
    height: 400px;
  }
}
</style>
