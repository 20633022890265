<template>
  <div class="content">
    <div class="title">
      <img src="@/assets/new/wangji.jpg" alt="" class="banner" />
      <div class="titles"></div>
      <div class="zhaohui">
        <div class="zhaohuis">找回密码</div>
        <img src="@/assets/new/jt.png" alt="" class="xiangxia">
      </div>
      <div class="biaoshi">
        <div class="biaoshis">
          <img
            src="@/assets/new/shouye.png"
            @click="$router.push({ path: '/' })"
            class="shouye"
            alt=""
          />
          <div class="dangqian">当前位置：找回密码</div>
        </div>
      </div>
    </div>

    <div class="neirong">
      <div class="form">
        <div class="form_title">手机号：</div>
        <div class="input">
          <img src="@/assets/new/zhaohui01.png" class="zhaohui" alt="" />
          <el-input
            placeholder="输入手机号"
            v-model="form.phone"
            class="inouts"
          ></el-input>
          <div class="code">
            <div class="codes"></div>
            <el-button type="text" :disabled="disabled" @click="sendVerifyCode">
              <span class="btnText">
                {{ btnText }}
              </span>
            </el-button>
          </div>
        </div>
      </div>

      <div class="form" style="margin-top: 33px;">
        <div class="form_title">验证码：</div>
        <div class="input">
          <img src="@/assets/new/zhaohui04.png" class="zhaohui" alt="" />
          <el-input
            placeholder="确认验证码"
            v-model="form.verifyCode"
            class="inouts"
          ></el-input>
        </div>
      </div>

      <div class="form" style="margin-top: 33px;">
        <div class="form_title">密码：</div>
        <div class="input">
          <img src="@/assets/new/zhaohui02.png" class="zhaohui" alt="" />
          <el-input
            placeholder="请输入密码"
            v-model="form.password"
            class="inouts"
            type="password"
          ></el-input>
        </div>
      </div>

      <div class="form" style="margin-top: 33px;">
        <div class="form_title">确认密码：</div>
        <div class="input">
          <img src="@/assets/new/zhaohui02.png" class="zhaohui" alt="" />
          <el-input
            placeholder="请确认密码"
            v-model="form.newPassword"
            class="inouts"
            type="password"
          ></el-input>
        </div>
      </div>

      <div class="success" @click="successclick" v-loading="loading">确定</div>
      <el-button type="text" style="margin-left: 150px;" @click="routers">
        <span style="color: #ff6700;">返回登录</span>
      </el-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: "",
        verifyCode: "",
        password: "",
        newPassword: "",
      },
      btnText: "发送验证码",
      disabled: false,
      loading:false,
    };
  },
  mounted() {},
  methods: {
    routers(){
      this.$router.push({
        path:"/login"
      })
    },
    successclick(){
        let that = this;
        if(!this.form.phone){
            this.$message.error('请输入手机号');
            return;
        }
        if(!this.form.verifyCode){
            this.$message.error('请输入验证码');
            return;
        }
        if(!this.form.password){
            this.$message.error('请输入密码');
            return;
        }
        if(this.form.password!=this.form.newPassword){
            this.$message.error('两次密码不一致');
            return;
        }
        this.loading = true
        let form = {
          code:window.btoa(that.form.verifyCode),
          confirmPwd:window.btoa(that.form.newPassword),
          mobileNum:window.btoa(that.form.phone),
          pwd:window.btoa(that.form.password)
        };
        this.$axios.put(`${that.$base_url}/webStoreUser/forgotPwd`,form).then(res=>{
          if (res.data.status == 200) {
                that.$message.success("重置成功");
                that.$router.push({path:"/login"})
              } else {
                that.$message.error(res.data.message);

              }
        }).finally(()=>{
          this.loading = false
        })
    },
    sendVerifyCode() {
      // 获取验证码
      const that = this;
      if (!that.form.phone) {
        that.$message.error("请输入手机号");
      } else {
        if (!that.disabled) {
          that.disabled = true;
          that.$axios
            .get(`${that.$base_url}/CustomerVerifyCode/send/${that.form.phone}`)
            .then((res) => {
              res = res.data;
              if (res.status == 200) {
                that.$message.success("发送成功");
                that.countDown(60);
              } else {
                let errMsg = "系统升级中，请稍候";
                if (res.message && res.message.length < 30) {
                  errMsg = res.message;
                }
                that.$message.error(errMsg);
                console.error(res);
                that.disabled = false;
              }
            })
            .catch((res) => {
              console.error(res);
              that.disabled = false;
            });
        }
      }
    },
    countDown(time) {
      // 倒计时
      let that = this;
      const interval = setInterval(function() {
        if (time > 0) {
          that.disabled = true; //禁用点击
          --time;
          that.btnText = time + 's后重试';
        } else {
          that.disabled = false; //开启可点击
          that.btnText = '发送验证码';
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.success{
    width: 360px;
height: 60px;
background: #FF3D09;
box-shadow: 0px 3px 24px 0px rgba(255,61,9,0.42);
border-radius: 30px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-size: 24px;
margin-top: 35px;
cursor: pointer;
margin-left: 125px;
}
.code {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 25px;
  .codes {
    width: 1px;
    height: 17px;
    background: #d5d6d8;
  }
  .btnText {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #ff3d09;
    margin-left: 14px;
  }
}
.form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    .inouts {
      font-size: 16px;
      width: 50%;
      margin-left: 44px;
      /deep/ .el-input__inner {
        border: none !important;
      }
    }
    .zhaohui {
      width: 18px;
      height: 24px;
      margin-left: 25px;
    }
    width: 360px;
    height: 56px;
    background: #fff;
    border: 1px solid #d6d8de;
    box-shadow: 0px 3px 5px 0px #edeef0;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .form_title {
    width: 120px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    text-align: right;
  }
}
.neirong {
  width: 1100px;
  height: 595px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.biaoshi {
  width: 1100px;
  height: 103px;
  background: #fff;
  left: 50%;
  margin-left: -550px;
  position: absolute;
  bottom: 0px;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .dangqian {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 10px;
  }
  .biaoshis {
    .shouye {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    width: 1062px;
    margin: 0 auto;
    height: 89px;
    border-bottom: 1px solid #edeef6;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}
.zhaohui {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .zhaohuis {
    color: #fff;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    top: 106px;
  }
  .xiangxia {
    width: 47px;
    height: 41px;
    position: absolute;
    top: 195px;
  }
}
.content {
  width: 100%;
  padding-bottom: 30px;
  .title {
    width: 100%;
    height: 418px;
    background: #fff;
    position: relative;
    .titles {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.32;
      position: absolute;
      z-index: 2;
    }
    .banner {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }
}
</style>