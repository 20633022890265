<template>
  <div class="home">
    <div class="regist">
      <div class="regist_center">
        <!-- <div class="regist_top">
          <div class="left fl">个人中心</div>
          <div class="right fr"><a href="javascript:;" @click="logout">退出登录</a></div>
          <div class="clear"></div>
          <div class="xian center"></div>
        </div> -->
        <div class="regist_main center">
          <div class="username">用&nbsp;&nbsp;户&nbsp;&nbsp;名:&nbsp;&nbsp;<span>{{login_user_name}}</span></div>
          <div class="username">手&nbsp;&nbsp;机&nbsp;&nbsp;号:&nbsp;&nbsp;<span>{{login_user_phone}}</span></div>
        </div>

        <div>
          <el-button type="info" class="btn-add" @click="handleAddAddress">新增收货地址</el-button>
        </div>

      </div> 
      <!-- regist_center -->

      <div class="regist_center">
        <div class="address-line" v-for="(item, index) of addressList" :key="index">
          <div><span class="username">{{item.receiver}}</span><span class="province">{{item.province}}</span></div>
          <div class="line">
            <span class="line-title">收货人:</span><span class="line-text">{{item.receiver}}</span>
          </div>
          <div class="line">
            <span class="line-title">所在地区:</span><span class="line-text">{{connectAddressArea(item)}}</span>
          </div>
          <div class="line">
            <span class="line-title">地址:</span><span class="line-text">{{item.address}}</span>
          </div>
          <div class="line">
            <span class="line-title">手机:</span><span class="line-text">{{item.phone}}</span>
          </div>
        </div>
        <div class="address-line" v-if="!addressList || addressList.length == 0">
          没有收货地址信息
        </div>
      </div><!-- regist_center -->
    </div>

    <!-- dialog -->
    <UserInfoDialog :title="dialogTitle">
      <div>您好!</div>
    </UserInfoDialog>

  </div><!-- root div-->
</template>

<script>

import UserInfoDialog from "@/components/dialog/UserInfoDialog";

// import axios from "axios";
// import { base_url } from "@/common/constant";
import { checkLogin } from "@/common/tools";

import { common_logout } from "@/common/tools";

import { remote_listAddressByUid } from "@/components/dialog/js/scriptForAddress";

export default {
  name: 'UserInfo',
  components: {
    UserInfoDialog,
  },
  data() {
    return {
      dialogTitle: '',
      addressList: [],
    };
  },//data end
  computed: {
    is_login() {
      return this.$store.state.is_login;
    },
    login_user_name() {
      const login_user = this.$store.state.login_user;
      if (login_user) {
        return login_user.username;
      } else {
        return "";
      }
    },
    login_user_phone() {
      const login_user = this.$store.state.login_user;
      if (login_user) {
        return login_user.phone;
      } else {
        return "";
      }
    },
    visible_for_userinfo() {
      return this.$store.state.visible_for_userinfo;
    },
  },
  watch: {
    visible_for_userinfo(val){
      if (!val) {
        this.refresh();
      }
    },
  },//watch
  methods: {

    logout(){
      common_logout();
    },

    handleAddAddress(){
      //
      const that = this;
      that.dialogTitle = "新增收货地址";
      that.$store.commit('change_visible_for_userinfo', true);
    },
    connectAddressArea(item){
      let result = "";
      if (item.province) {
        result += item.province;
      }
      if (item.city) {
        result += item.city;
      }
      if (item.area) {
        result += item.area;
      }
      return result;
    },

    refresh() {
      const that = this;
      remote_listAddressByUid().then(res=>{
        if (res.flag) {
          that.addressList = res.data;
        } else {
          console.error(res.err);
        }
      }).catch(err=>{
        console.error(err);
      });
    },

  },//methods end
  created() {
    const that = this;

    const url = that.$route.fullPath;
    checkLogin().then(res=>{
      if (res.flag) {
        that.refresh();
      } else {
        // 请先登录
        that.$store.commit('change_url_before_login', url);
        that.$message.warning("请先登录!");
        setTimeout(()=>{
          that.$router.push('/login');
        }, 1500);
      }
    }).catch(()=>{});
  },//created end
}
</script>

<style lang="scss" scoped>

.fl{float: left;}
.fr{float: right;}
.clear{clear:both;}
.center{margin:0 auto;}
.border{border:1px solid red;}

/*用户中心  height: 550px;  */
.regist{
  //width: 1200px;
  min-height: 550px;margin:0 auto;background:#fff;color:#000;
  //border-radius: 10px;
}
.regist_center{width: 760px;margin: 0 auto;}
.regist .regist_top{margin: 0;}
.regist .regist_top .left{height: 40px;line-height: 40px;font-weight: bold;font-size: 20px;}
.regist .regist_top .right{height: 40px;line-height: 40px;font-size: 13px;}
.regist .regist_top .right a{color:#ff6700;font-weight: bold;}
.regist .regist_top .right a:hover{color:orange;}
.xian{height: 2px;background: #ff6700;margin: 8px auto;}

.regist_main{padding:10px 0;padding-left:45px;}
.regist_main .username{font:16px Times New Roman;height: 40px;line-height: 40px;margin:20px 0;}
.regist_main .username .shurukuang{width: 220px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .yanzhengma{width: 100px;height: 30px;border:1px solid #ccc;padding:5px 10px; }
.regist_main .username .shurukuang:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .yanzhengma:focus{border: 1px solid blue;background: #F0FFFF;}
.regist_main .username .right{margin-left: 20px;}

.regist_main .username span{display:inline-block;margin-left:20px;font-size: 12px;color: #ccc;font-weight: bold;}
.regist_main .username span:hover{color: #ff6700;}

.regist .regist_center .regist_submit{margin: 20px auto;}
.regist .regist_center .submit{border:none;width: 440px;height: 45px;margin-left:45px;
  background: #ff6700;color: #fff; font-size: 22px;font-weight: bold;letter-spacing: 8px;cursor:pointer;}
.regist .regist_center .submit:hover{border:1px solid #ccc;}


.btn-add {
  background-color: #ff6700;
  border-color: #ff6700;
  &:hover {
    background-color: lighten(#f67d2f, 10%);
    border-color: lighten(#f67d2f, 10%);
  }
  &:active {
    background-color: #ff6700;
  }
}

.address-line {
  border: 2px solid #bbb8b8;
  margin: 10px 0;
  padding: 10px;
  .username {
    margin-right: 5px;
  }
  .province {
  }
  .line {
    margin: 5px 0;
  }
  .line-title {
    display: inline-block;
    width: 80px;
    text-align: right;
    color: #b1acac;
    margin-right: 10px;
  }
  .line-text {}
}
</style>
